import React from 'react'
import {
  TabbedForm,
  FormTab,
  TextInput,
  SelectInput,
  ReferenceInput,
  DateInput,
  BooleanInput,
  required,
} from 'react-admin'
import CountrySelectorInput from '../../components/CountrySelectorInput'

// const choicesUserStatus = [{ _key: 'ACTIVE', label: 'ACTIVE' }, { _key: 'SUSPENDED', label: 'SUSPENDED' }]

const choicesUserTitle = [{ _key: 'M', _label: 'M' }, { _key: 'MME', _label: 'MME' }, { _key: 'MLLE', _label: 'MLLE' }]

// const choicesUserIncomeRanges = [
//   { _key: 'less_twenty', _label: '0-18' },
//   { _key: 'less_twenty_five', _label: '19-23' },
//   { _key: 'less_thirty', _label: '24-27' },
//   { _key: 'less_thirty_five', _label: '28-35' },
//   { _key: 'less_fifty_five', _label: '36-56' },
//   { _key: 'over_fifty_five', _label: '57-*' },
// ]

// const choicesUserPersonalAssets = [
//   { _key: 'less_two', _label: '0-2' },
//   { _key: 'less_twenty_two', _label: '3-22' },
//   { _key: 'less_hundred_thirthy', _label: '23-128' },
//   { _key: 'less_three_hundred_twenty', _label: '129-319' },
//   { _key: 'less_four_hundred_sixty', _label: '320-464' },
//   { _key: 'over_four_hundred_sixty', _label: '465-' },
//]

const fieldRequired = [required()]

export default ({ type = 'edit', defaultPartner, permissions, ...props }) => (
  <TabbedForm {...props}>
    <FormTab label="Shareholder Info">
      <ReferenceInput
        label="Organisation"
        source="paymentOrganisationId"
        reference="paymentOrganisations"
        perPage={700}
        validate={fieldRequired}
      >
        <SelectInput source="paymentOrganisationId" optionText="name" optionValue="id" allowEmpty={false} />
      </ReferenceInput>
      <SelectInput
        source="title"
        choices={choicesUserTitle}
        optionText="_label"
        optionValue="_key"
        allowEmpty={false}
        validate={fieldRequired}
      />
      <TextInput source="firstName" validate={fieldRequired} />
      <TextInput source="lastName" validate={fieldRequired} />
    </FormTab>
    <FormTab label="Contact Info">
      <TextInput source="email" validate={fieldRequired} />
      <TextInput source="phoneNumber" validate={fieldRequired} />
    </FormTab>
    <FormTab label="Address">
      <TextInput source="address.street" validate={fieldRequired} />
      <TextInput source="address.city" validate={fieldRequired} />
      <TextInput source="address.postalCode" validate={fieldRequired} />
      <CountrySelectorInput source="address.countryCode" defaultValue="BE" />
    </FormTab>
    <FormTab label="KYC Info">
      <CountrySelectorInput source="nationality" defaultValue="BE" />
      <DateInput source="birthday" validate={fieldRequired} />
      <TextInput source="placeOfBirth" validate={fieldRequired} />
      <BooleanInput source="isUsPerson" label="Is a US citizen" />
      <TextInput source="occupation" validate={fieldRequired} />
      <TextInput source="taxResidence.taxPayerId" label="National Registry Number" validate={fieldRequired} />
    </FormTab>
  </TabbedForm>
)
