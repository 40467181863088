import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import compose from 'recompose/compose'
import { withTranslate, userLogin } from 'react-admin'
import { Button, CardActions, CircularProgress, TextField, withStyles, createStyles } from '@material-ui/core'
import { TERMS_AND_CONDITIONS_URL, PRIVACY_POLICY_URL } from '../config/env/base'
import { Checkbox, FormControlLabel, Typography, FormControl, FormHelperText } from '@material-ui/core'

const styles = ({ spacing }) =>
  createStyles({
    form: {
      padding: '0 1em 1em 1em',
    },
    input: {
      marginTop: '1em',
    },
    button: {
      width: '100%',
      marginBottom: spacing.unit,
    },
    icon: {
      marginRight: spacing.unit,
    },
    link: {
      color: 'inherit',
      textDecoration: 'underline',
    },
  })

// see http://redux-form.com/6.4.3/examples/material-ui/
const renderInput = ({
  meta: { touched, autofilled, error } = { touched: false, autofilled: false, error: '' }, // eslint-disable-line react/prop-types
  input: { ...inputProps }, // eslint-disable-line react/prop-types
  ...props
}) => (
  <TextField
    error={!!(touched && error) && !autofilled}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
)

const renderCheckbox = ({
  meta: { touched, error, ...metaProps } = { touched: false, error: '' }, // eslint-disable-line react/prop-types
  input: { value, ...inputProps }, // eslint-disable-line react/prop-types
  label,
}) => {
  const hasError = !!touched && !!error
  return (
    <FormControl required error={hasError}>
      <FormControlLabel control={<Checkbox checked={value ? true : false} required {...inputProps} />} label={label} />
      {hasError && <FormHelperText>Required</FormHelperText>}
    </FormControl>
  )
}

const login = (auth, dispatch, { redirectTo }) => dispatch(userLogin(auth, redirectTo))

const LoginForm = ({ classes, isLoading, handleSubmit, translate }) => (
  <div>
    <form onSubmit={handleSubmit(login)}>
      <div className={classes.form}>
        <div className={classes.input}>
          <Field
            autoFocus
            id="email"
            name="email"
            component={renderInput}
            label="Email"
            type="email"
            disabled={isLoading}
            required
          />
        </div>
        <div className={classes.input}>
          <Field
            id="password"
            name="password"
            component={renderInput}
            label={translate('ra.auth.password')}
            type="password"
            disabled={isLoading}
            required
          />
        </div>
        <div className={classes.input}>
          <Field
            id="agreement"
            name="agreement"
            component={renderCheckbox}
            label={
              <Typography variant="body1" color="textSecondary">
                I agree to the SkiprForBusiness{' '}
                <a className={classes.link} href={TERMS_AND_CONDITIONS_URL} target="_blank" rel="noopener noreferrer">
                  Terms and
                  <br />
                  Conditions
                </a>{' '}
                and{' '}
                <a className={classes.link} href={PRIVACY_POLICY_URL} target="_blank" rel="noopener noreferrer">
                  Privacy Policy
                </a>
              </Typography>
            }
            disabled={isLoading}
            required
          />
        </div>
      </div>
      <CardActions>
        <Button variant="raised" type="submit" color="primary" disabled={isLoading} className={classes.button}>
          {isLoading && <CircularProgress className={classes.icon} size={18} thickness={2} />}
          {translate('ra.auth.sign_in')}
        </Button>
      </CardActions>
    </form>
    <p style={{ textAlign: 'center' }}>
      <Link to={'?forgot'}>Forgot password?</Link>
    </p>
  </div>
)

const mapStateToProps = state => ({
  isLoading: state.admin.loading > 0,
})

const enhance = compose(
  withStyles(styles),
  withTranslate,
  connect(mapStateToProps),
  reduxForm({
    form: 'signIn',
    validate: (values, props) => {
      const errors = { email: '', password: '' }
      const { translate } = props
      if (!values.email) {
        errors.email = translate('ra.validation.required')
      }
      if (!values.password) {
        errors.password = translate('ra.validation.required')
      }
      if (!values.agreement) {
        errors.agreement = translate('ra.validation.required')
      }
      return errors
    },
  }),
)

const UMLoginForm = enhance(LoginForm)

UMLoginForm.propTypes = {
  redirectTo: PropTypes.string,
}

export default UMLoginForm
