import { CURRENCY_SYMBOL_DEFAULT, LOCALE_DEFAULT } from '../config/locale'
export const randomInt = (from, to) => from + Math.floor(Math.random() * (to - from + 1))

export const formatPrice = amount => `${CURRENCY_SYMBOL_DEFAULT} ${(amount / 100).toLocaleString(LOCALE_DEFAULT)}`

export const formatFileSize = size => {
  const kb = Math.ceil(size / 1000)
  if (kb >= 1000) {
    return (kb / 1000).toFixed(2).toLocaleString(LOCALE_DEFAULT) + ' MB'
  }
  return kb.toLocaleString(LOCALE_DEFAULT) + ' KB'
}
