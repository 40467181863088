import React from 'react'
import { Create } from 'react-admin'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import get from 'lodash.get'
import { parse } from 'query-string'
import partners from '../../api/resources/partners'
import UMBreadcrumbs from '../../components/UMBreadcrumbs'
import employees from '../../api/resources/employees'
import EmployeeForm from './form'
import { withStyles, Typography } from '@material-ui/core'
import commonStyles from '../common/styles'

const Aside = () => (
  <div style={{ width: '50%', margin: '1em' }}>
    <Typography variant="title">Create employee</Typography>
    <p>This form handles the following taks</p>
    <ul>
      <li>Create a new user in Skipr for Business</li>
      <li>Link user to company with membership Employee</li>
    </ul>
  </div>
)

const EmployeeCreate = withStyles(commonStyles.create)(
  ({ parentRecord, organisationId, defaultPartner, dispatch, permissions, classes, ...props }) => {
    if (!parentRecord) {
      if (!organisationId) {
        dispatch(push('/'))
        return null
      }
      parentRecord = { id: organisationId }
    }

    const formProps = {
      mode: 'create',
      defaultPartner,
      permissions,
      organisationId,
    }

    return (
      <Create
        title={<UMBreadcrumbs resourceName={employees.getName()} currentLabel="Create employee" />}
        {...props}
        aside={<Aside />}
      >
        <EmployeeForm {...formProps} />
      </Create>
    )
  },
)

export default connect((state, ownProps) => {
  const query = get(ownProps, 'location.search')
  const params = parse(query)
  const { organisationId } = params
  const defaultPartner = partners.getMainRecord(state)
  return {
    organisationId,
    defaultPartner,
  }
})(EmployeeCreate)
