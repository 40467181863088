import React from 'react'
import { List, TextField, CardActions, RefreshButton, EditButton } from 'react-admin'
import UMDatagrid from '../../components/UMDatagrid'

const CardsActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
}) => (
  <CardActions>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <RefreshButton />
    {/* Add your custom actions */}
  </CardActions>
)

export default props => (
  <List actions={<CardsActions />} bulkActions={false} {...props}>
    <UMDatagrid isFullList rowClick="show">
      <TextField source="merchantName" label="Merchant name" />
      <TextField source="merchantReference" />
      <TextField source="name" label="Merchant name in S4B" />
      <TextField source="categoryCode" label="Category in S4B" />
      <EditButton />
    </UMDatagrid>
  </List>
)
