import { DateTime, Settings } from 'luxon'
import { LOCALE_DEFAULT, TIMEZONE } from '../config/locale'

Settings.defaultZoneName = TIMEZONE

export const parseDateAsDateTime = date => {
  let dt
  if (DateTime.isDateTime(date)) {
    dt = date
  } else if (date instanceof Date) {
    dt = DateTime.fromJSDate(date)
  } else if (date && typeof date.indexOf === 'function' && date.indexOf('T') > -1) {
    dt = DateTime.fromISO(date)
  } else {
    dt = DateTime.fromJSDate(new Date(date))
  }
  return dt
}

export const getTodayDate = () =>
  DateTime.local()
    //.set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
    .toJSDate()

export const isDateValid = date => parseDateAsDateTime(date).isValid

export const formatDate = value => {
  const dt = parseDateAsDateTime(value)
  return dt.isValid ? dt.toLocaleString(DateTime.DATE_SHORT) : value
}

export const parseDate = (value, options = {}) => {
  let dt = parseDateAsDateTime(value)
  if (!dt.isValid) {
    return value
  }
  if (options.offset) {
    dt = dt.plus(options.offset)
  }
  return dt.toISO()
}

export const getMonthsListBetweenDates = (startDateValue, endDateValue, locales = LOCALE_DEFAULT) => {
  const startDate = startDateValue instanceof Date ? startDateValue : new Date(startDateValue)
  const startYear = startDate.getFullYear()
  let endDate
  const maxEndDate = new Date(startYear, 11, 31)
  if (endDateValue) {
    endDate = endDateValue instanceof Date ? endDateValue : new Date(endDateValue)
    if (endDate > maxEndDate) {
      endDate = maxEndDate
    }
  } else {
    endDate = maxEndDate
  }
  const startDateMonth = startDate.getMonth() + 1
  const endDateMonth = endDate.getMonth()
  if (startDateMonth > endDateMonth) {
    return []
  }
  const formatter = new Intl.DateTimeFormat(locales, { month: 'long', year: 'numeric' })
  const getMonthName = month => formatter.format(new Date(startYear, month, 1))
  return Array.from({ length: endDateMonth - startDateMonth + 1 }, (v, k) => {
    const month = k + startDateMonth
    return { value: month + 1, name: getMonthName(month) }
  })
}
