import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

import { Responsive } from 'react-admin'
import UMMenuItemLink from './UMMenuItemLink'
import organisations from '../api/resources/organisations'
import users from '../api/resources/users'
import mccRestrictionGroups from '../api/resources/mccRestrictionGroups'
import merchantIdRestrictionGroups from '../api/resources/merchantIdRestrictionGroups'
import virtualCards from '../api/resources/virtualCards'
import paymentOrganisations from '../api/resources/paymentOrganisations'
import shareholders from '../api/resources/shareholders'
import merchants from '../api/resources/merchants'
import cardTransactions from '../api/resources/cardTransactions'

const styles = {
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
}

const UMSideMenu = ({
  classes,
  className,
  dense,
  hasDashboard,
  onMenuClick,
  open,
  pathname,
  items,
  logout,
  dispatch,
  ...rest
}) => {
  return (
    <div className={classnames(classes.main, className)} {...rest}>
      {items.map(item => (
        <UMMenuItemLink
          key={item.key || item.to}
          to={item.to}
          primaryText={item.label}
          leftIcon={item.icon}
          badge={item.badge}
          onClick={onMenuClick}
          dense={dense}
          open={open}
        />
      ))}
      <Responsive xsmall={logout} medium={null} />
    </div>
  )
}

UMSideMenu.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  dense: PropTypes.bool,
  hasDashboard: PropTypes.bool,
  logout: PropTypes.element,
  onMenuClick: PropTypes.func,
  open: PropTypes.bool,
  pathname: PropTypes.string,
  items: PropTypes.array.isRequired,
}

UMSideMenu.defaultProps = {
  onMenuClick: () => null,
}

const mapStateToProps = state => {
  const items = []

  items.push({
    key: organisations.getName(),
    to: '/organisations',
    label: organisations.getLabel(),
    //icon: <AttachMoneyIcon />,
  })
  items.push({
    key: users.getName(),
    to: '/users',
    label: users.getLabel(),
  })
  items.push({
    key: paymentOrganisations.getName(),
    to: '/paymentOrganisations',
    label: 'KYC - Organisations',
  })
  items.push({
    key: shareholders.getName(),
    to: '/shareholders',
    label: shareholders.getLabel(),
  })
  items.push({
    key: virtualCards.getName(),
    to: '/virtualCards',
    label: virtualCards.getLabel(),
  })
  items.push({
    key: cardTransactions.getName(),
    to: '/cardTransactions',
    label: cardTransactions.getLabel(),
  })
  items.push({
    key: mccRestrictionGroups.getName(),
    to: '/mccRestrictionGroups',
    label: mccRestrictionGroups.getLabel(),
  })
  items.push({
    key: merchants.getName(),
    to: '/merchants',
    label: merchants.getLabel(),
  })
  items.push({
    key: merchantIdRestrictionGroups.getName(),
    to: '/merchantIdRestrictionGroups',
    label: merchantIdRestrictionGroups.getLabel(),
  })
  return {
    open: state.admin.ui.sidebarOpen,
    pathname: state.router.location.pathname, // used to force redraw on navigation
    items,
  }
}

const enhance = compose(
  connect(mapStateToProps),
  withStyles(styles),
)

export default enhance(UMSideMenu)
