import React from 'react'
import { SimpleShowLayout, TextField } from 'react-admin'
import UMPriceField from '../../components/UMPriceField'
import UMDateField from '../../components/UMDateField'
import { Card } from '@material-ui/core'
import UMChartField from '../../components/UMChartField'

const BudgetInfoShowLayout = props => (
  <SimpleShowLayout {...props}>
    <TextField source="titleHead" variant="title" gutterBottom addLabel={false} />
    {/* <ReferenceField source="membership.id" reference="memberships" label="Employee" linkType="show">
      <UMFullNameField firstNameKey="user.firstName" lastNameKey="user.lastName" />
    </ReferenceField> */}
    <UMChartField label="Budget status" />
    <TextField source="type.code" label="Type" />
    <UMPriceField source="total" format={v => (v ? v / 100 : 0)} />
    <UMPriceField source="used" format={v => (v ? v / 100 : 0)} />
    <UMPriceField source="remaining" format={v => (v ? v / 100 : 0)} />
    <UMDateField source="startOn" label="Start date" />
    <UMDateField source="endOn" label="End date" />
    <UMPriceField source="professionalUsed" label="Business expenses" format={v => (v ? v / 100 : 0)} />
    <TextField source="email" />
    <TextField source="phoneNumber" />
    {/* <TextField source="identityDocument.status" label="KYC status" /> */}
    <UMDateField source="updatedOn" label="Last update" />
  </SimpleShowLayout>
)

export default ({ basePath, forceRecord, employeeRecord, resource, version, classes }) => {
  return (
    <Card className={classes.aside}>
      <BudgetInfoShowLayout
        record={{
          titleHead: `${employeeRecord.firstName} ${employeeRecord.lastName}`,
          ...employeeRecord,
          ...forceRecord,
        }}
      />
    </Card>
  )
}
