import get from 'lodash/get'
import { formatDate } from '../../utils/dates'

const fieldsPartial = `
  id
  name
  internal
`

const filterTypes = {}

const getParentResource = () => null

const getName = () => 'partners'

const getLabel = () => 'Partners'

const getRecordById = (state, id) => get(state, `admin.resources.partners.data.${id}`, null)

const getRecord = (state, finder) => Object.values(get(state, `admin.resources.partners.data`, {})).find(finder)

const getMainRecord = state => {
  return getRecord(state, record => record.internal)
}

const getLabelForRecord = record => {
  if (!record) {
    return ''
  }
  return `${formatDate(record.name)}`
}

export default {
  fieldsPartial,
  getParentResource,
  getName,
  getLabel,
  getRecordById,
  getMainRecord,
  getRecord,
  getLabelForRecord,
  filterTypes,
}
