import React from 'react'
import { TabbedShowLayout, Tab, TextField, DateField, Show } from 'react-admin'
import UMDatagrid from '../../components/UMDatagrid'

const Merchants = ({ record }) => {
  let data = {}
  // record.merchantsList.map((elem, index) => {
  //   data = { ...data, [elem]: { id: elem, name: elem } }
  //   return data
  // })
  let lastMerchants = record.merchantsList.slice(-100)
  console.log(lastMerchants)
  for (var i = lastMerchants.length; i > lastMerchants.length - 100; i--) {
    let elem = lastMerchants[i]
    data = { ...data, [elem]: { id: elem, name: elem } }
  }
  console.log(data)
  return (
    <UMDatagrid
      ids={lastMerchants}
      data={data}
      total={lastMerchants.length} //{record.merchantsList.length}
      loadedOnce={true}
      isFullList
      currentSort={{}}
    >
      <TextField source="id" />
    </UMDatagrid>
  )
}
export default props => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="startDate" />
        <TextField source="status" />
        <DateField label="Created on" source="createdOn" />
        <DateField label="Updated on" source="updatedOn" />
      </Tab>
      <Tab label="MID's">
        <Merchants {...props} />
      </Tab>
    </TabbedShowLayout>
  </Show>
)
