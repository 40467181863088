import React from 'react'
import { CardActions, Typography } from '@material-ui/core'

const UMActions = ({ title, classes, children }) => {
  return (
    <CardActions className={classes.actions}>
      {title && (
        <Typography className={classes.actionsTitle} variant="subheading">
          {title}
        </Typography>
      )}
      {children}
    </CardActions>
  )
}

export default UMActions
