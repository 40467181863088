import React from 'react'
import {
  TabbedForm,
  FormTab,
  TextInput,
  SelectInput,
  DateInput,
  BooleanInput,
  Toolbar,
  SaveButton,
  DeleteButton,
  NumberInput,
  required,
} from 'react-admin'
import CountrySelectorInput from '../../components/CountrySelectorInput'

const legalNetIncomeRanges = [
  { value: 'less_five', label: '0-4' },
  { value: 'less_ten', label: '5-9' },
  { value: 'less_fifty', label: '10-49' },
  { value: 'less_hundred_fifty', label: '50-149' },
  { value: 'less_five_hundred_fifty', label: '150-499' },
  { value: 'over_five_hundred_fifty', label: '500-*' },
]

const legalNumberOfEmployeeRanges = [
  { value: 'less_ten', label: '1-9' },
  { value: 'less_hundred', label: '10-99' },
  { value: 'less_two_hundred_fifty', label: '100-249' },
  { value: 'over_two_hundred_fifty', label: '250-*' },
]

const legalAnnualTurnOverRanges = [
  { value: 'less_forty', label: '0-39' },
  { value: 'less_hundred', label: '40-99' },
  { value: 'less_two_hundred_fifty', label: '100-249' },
  { value: 'less_one_k', label: '250-999' },
  { value: 'less_three_k', label: '1000-2999' },
  { value: 'less_ten_k', label: '3000-9999' },
  { value: 'less_hundred_k', label: '10000-99999' },
  { value: 'over_hundred_k', label: '100000-*' },
]

const kmAllowanceCoefTypes = [{ value: 'LEGAL', label: 'legal' }, { value: 'CUSTOM', label: 'custom' }]

const PostEditToolbar = ({ permissions, ...props }) => {
  if (permissions === 'FLEET_MGR' || permissions === 'EMPLOYEE')
    return (
      <Toolbar {...props}>
        <SaveButton />
      </Toolbar>
    )
  else
    return (
      <Toolbar {...props}>
        <SaveButton />
        <DeleteButton />
      </Toolbar>
    )
}

const validateForm = values => {
  const errors = {}
  if (values.isTreezor) {
    //if associate with treezor then income fields are mandatory
    if (!values.legalRegistrationDate) {
      errors.legalSector = ['This field is required for Treezor']
    }
    if (!values.legalRegistrationDate) {
      errors.legalSector = ['This field is required for Treezor']
    }
    if (!values.legalNumberOfEmployeeRange) {
      errors.legalNumberOfEmployeeRange = ['This field is required for Treezor']
    }
    if (!values.legalNetIncomeRange) {
      errors.legalNetIncomeRange = ['This field is required for Treezor']
    }
    if (!values.legalAnnualTurnOver) {
      errors.legalAnnualTurnOver = ['This field is required for Treezor']
    }
  }
  return errors
}
const fieldRequired = [required()]

const expenseManagersChoices = [{ value: 'a4456ed2-e093-48dc-b36f-2cde81c2ed0b', label: 'Skipr4Business' }]
export default ({ type = 'edit', permissions, ...props }) => {
  return (
    <TabbedForm toolbar={<PostEditToolbar permissions={permissions} {...props} />} {...props} validate={validateForm}>
      <FormTab label="Company Details">
        <label>
          <h3>Skipr 4 business parameters</h3>
          <p>Check to indicate that it is a management company</p>
        </label>
        <BooleanInput label="Freelancer" source="isFreelancer" defaultValue={false} />
        <SelectInput
          source="expenseManagerId"
          choices={expenseManagersChoices}
          optionText="label"
          optionValue="value"
          validate={fieldRequired}
        />
        <label>
          <h3>Company info</h3>
        </label>
        <TextInput source="name" validate={fieldRequired} />
        <TextInput source="legalName" validate={fieldRequired} />
        <TextInput source="legalForm" validate={fieldRequired} />
        <TextInput source="vatNumber" validate={fieldRequired} />

        <DateInput source="legalRegistrationDate" />
        <TextInput source="legalSector" />
      </FormTab>
      <FormTab label="Company Contacts">
        <label>
          <h3>Contacts Info</h3>
        </label>
        <TextInput source="email" validate={fieldRequired} />
        <TextInput source="phoneNumber" placeholder="+32475121212" />
        <label>
          <h3>Company address</h3>
        </label>
        <TextInput source="address.street" validate={fieldRequired} />
        <TextInput source="address.city" validate={fieldRequired} />
        <TextInput source="address.postalCode" validate={fieldRequired} />
        <CountrySelectorInput source="address.countryCode" defaultValue="BE" validate={fieldRequired} />
      </FormTab>

      <FormTab label="Km allowance">
        <label>
          <h3>Kmallowance infos</h3>
        </label>
        <BooleanInput label="Enable km allowances" source="kmAllowance.enabled" defaultValue={false}></BooleanInput>
        <label>
          <h3>Car coefficients</h3>
        </label>
        <SelectInput
          label="type"
          source="kmAllowance.parameters.carAllowanceType"
          choices={kmAllowanceCoefTypes}
          optionText="label"
          optionValue="value"
          defaultValue="LEGAL"
        />
        <NumberInput
          source="kmAllowance.parameters.feePerKmCar"
          label="value (€/km)"
          format={v => (v === undefined || v === null ? '' : v / 100)}
          parse={v => (v ? Math.round(v * 100) : v)}
        />
        <label>
          <h3>Bike coefficients</h3>
        </label>
        <SelectInput
          label="type"
          source="kmAllowance.parameters.bikeAllowanceType"
          choices={kmAllowanceCoefTypes}
          optionText="label"
          optionValue="value"
          defaultValue="LEGAL"
        />
        <NumberInput
          source="kmAllowance.parameters.feePerKmBike"
          label="value (€/km)"
          format={v => (v === undefined || v === null ? '' : v / 100)}
          parse={v => (v ? Math.round(v * 100) : v)}
        />
      </FormTab>

      <FormTab label="Treezor Details">
        <label>
          <p>Check the box below to trigger association with Treezor</p>
        </label>
        <BooleanInput label="Associate with Treezor" source="isTreezor" defaultValue={false}></BooleanInput>
        <SelectInput
          source="legalNumberOfEmployeeRange"
          choices={legalNumberOfEmployeeRanges}
          optionText="label"
          optionValue="value"
        />
        <SelectInput
          source="legalNetIncomeRange"
          choices={legalNetIncomeRanges}
          optionText="label"
          optionValue="value"
        />
        <SelectInput
          source="legalAnnualTurnOver"
          choices={legalAnnualTurnOverRanges}
          optionText="label"
          optionValue="value"
        />
        <BooleanInput label="involvedSanctions" source="involvedSanctions" defaultValue={false}></BooleanInput>
        <BooleanInput label="activityOutsideEu" source="activityOutsideEu" defaultValue={false}></BooleanInput>
        <BooleanInput label="economicSanctions" source="economicSanctions" defaultValue={false}></BooleanInput>
        <BooleanInput
          label="residentCountriesSanctions"
          source="residentCountriesSanctions"
          defaultValue={false}
        ></BooleanInput>
      </FormTab>
      <FormTab label="Settings">
        <label>
          <h3>Expense auto-validation</h3>
        </label>
        <label>
          <p>Minimum amount for PRO expenses comming from the APP automatic approval</p>
        </label>
        <NumberInput
          source="appExpenseApprovalLimit"
          format={v => (v === undefined || v === null ? '' : v / 100)}
          parse={v => (v ? Math.round(v * 100) : v)}
          initialValue="0"
        />
        <label>
          <p>Minimum amount for PRO expenses comming from the CARD automatic approval</p>
        </label>
        <NumberInput
          source="cardExpenseApprovalLimit"
          format={v => (v === undefined || v === null ? '' : v / 100)}
          parse={v => (v ? Math.round(v * 100) : v)}
          initialValue="0"
        />
        <label>
          <p>Maximum amount for approval by direct manager instead of Fleet Manager</p>
        </label>
        <NumberInput
          source="managerValidationThreshold"
          format={v => (v === undefined || v === null ? '' : v / 100)}
          parse={v => (v ? Math.round(v * 100) : v)}
          initialValue="0"
        />
        <label>
          <h3>Optionnal budget types</h3>
        </label>
        <BooleanInput label="Use mobility budgets" source="useMobilityBudget" defaultValue={false}></BooleanInput>
        <BooleanInput label="Use commute budgets" source="useCommuteBudget" defaultValue={false}></BooleanInput>
      </FormTab>

      <FormTab label="Mbrella PAPI link">
        <label>
          <h3>Mbrella Info</h3>
          <p>
            If this value is set, expenses for employees who have their social secretary info set will be sent to
            mbrella.
          </p>
        </label>
        <TextInput source="socialSecretaryReference" />
      </FormTab>

      {!(!props.record || Object.keys(props.record).length === 0) ? (
        <FormTab label="Wallet options">
          <label>
            <p>Check to activate refill of the company wallet, each time the wallet reaches the minimum amount</p>
          </label>
          <BooleanInput source="automaticRefill" defaultValue={false} />
          <NumberInput
            source="minimumAmount"
            format={v => (v === undefined || v === null ? '' : v / 100)}
            parse={v => (v ? Math.round(v * 100) : v)}
          />
        </FormTab>
      ) : null}
    </TabbedForm>
  )
}
