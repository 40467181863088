import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, crudUpdateMany, withDataProvider, UPDATE_MANY, refreshView, showNotification } from 'react-admin'

class SyncWithTreezorButton extends Component {
  handleClick = () => {
    const { selectedIds } = this.props
    const { showNotification } = this.props
    this.props
      .dataProvider(UPDATE_MANY, 'employees', { ids: selectedIds, data: { action: 'SYNC_TREEZOR' } })
      .then(({ data }) => {
        showNotification('users sync with success')
      })
      .catch(error => {
        showNotification(error.message, 'warning')
        this.props.refreshView()
      })
  }

  render() {
    return <Button label="Sync Treezor" onClick={this.handleClick} />
  }
}

export default withDataProvider(
  connect(undefined, { crudUpdateMany, refreshView, showNotification })(SyncWithTreezorButton),
)
