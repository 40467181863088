import React from 'react'
import {
  Edit,
  TabbedForm,
  FormTab,
  FileField,
  FileInput,
  SelectInput,
  TextInput,
  DateInput,
  BooleanInput,
} from 'react-admin'
import UMBreadcrumbs from '../../components/UMBreadcrumbs'
import shareholders from '../../api/resources/shareholders'
import CountrySelectorInput from '../../components/CountrySelectorInput'

const choicesIdentityDocumentTypes = [
  { key: '9', label: 'Identity Card' },
  { key: '17', label: 'Passport' },
  { key: '15', label: 'Residence Permit' },
]
const choicesResidenceDocumentTypes = [
  { key: '12', label: 'Proof of Address' },
  { key: '19', label: 'A company registration official paper' },
  { key: '24', label: 'Tax Statement' },
]
const choicesAdditionalDocumentTypes = [{ key: '15', label: 'A residence permit' }]

const choicesUserTitle = [{ _key: 'M', _label: 'M' }, { _key: 'MME', _label: 'MME' }, { _key: 'MLLE', _label: 'MLLE' }]

const ShareholderEdit = ({ permissions, dispatch, ...props }) => {
  return (
    <Edit
      title={<UMBreadcrumbs resourceName={shareholders.getName()} currentLabel="Edit shareholder" />}
      undoable={false}
      {...props}
    >
      <TabbedForm>
        <FormTab label="Personal">
          <TextInput source="firstName" />
          <TextInput source="lastName" />
          <SelectInput
            source="title"
            choices={choicesUserTitle}
            optionText="_label"
            optionValue="_key"
            allowEmpty={false}
          />
          <TextInput source="email" />
          <TextInput source="phoneNumber" />
          <CountrySelectorInput source="nationality" defaultValue="BE" />
          <BooleanInput source="isUsPerson" label="Is a US citizen" />
          <DateInput source="birthday" />
          <TextInput source="placeOfBirth" />
        </FormTab>
        <FormTab label="Address">
          <TextInput source="address.street" />
          <TextInput source="address.city" />
          <TextInput source="address.postalCode" />
          <CountrySelectorInput source="address.countryCode" defaultValue="BE" />
        </FormTab>
        <FormTab label="Documents">
          <SelectInput
            source="identityDocument.documentTypeReference"
            label="Document Type"
            choices={choicesIdentityDocumentTypes}
            optionText="label"
            optionValue="key"
          />
          <FileInput source="identityDocument.fileContentBase64" label="File" multiple="true">
            <FileField source="src" title="title" />
          </FileInput>
          <SelectInput
            source="residenceDocument.documentTypeReference"
            label="Document Type"
            choices={choicesResidenceDocumentTypes}
            optionText="label"
            optionValue="key"
          />
          <FileInput source="residenceDocument.fileContentBase64" label="File">
            <FileField source="src" title="title" />
          </FileInput>
          <SelectInput
            source="additionalDocument.documentTypeReference"
            label="Document Type"
            choices={choicesAdditionalDocumentTypes}
            optionText="label"
            optionValue="key"
          />
          <FileInput source="additionalDocument.fileContentBase64" label="File">
            <FileField source="src" title="title" />
          </FileInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}

export default ShareholderEdit
