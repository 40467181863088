import React from 'react'
import { Create } from 'react-admin'
import UMBreadcrumbs from '../../components/UMBreadcrumbs'
import shareholders from '../../api/resources/shareholders'
import ShareholderForm from './form'

const ShareholderCreate = ({ permissions, dispatch, ...props }) => {
  const formProps = { permissions }
  return (
    <Create
      title={<UMBreadcrumbs resourceName={shareholders.getName()} currentLabel="Create shareholder" />}
      {...props}
    >
      <ShareholderForm {...formProps} />
    </Create>
  )
}

export default ShareholderCreate
