import React from 'react'
import { List, TextField, ReferenceField } from 'react-admin'
import { withStyles, Card } from '@material-ui/core'
import commonStyles from '../../layouts/common/styles'
import { UMWrappedDatagrid } from '../../components/UMDatagrid'
import UMDateField from '../../components/UMDateField'
import UMPriceField from '../../components/UMPriceField'
import UMActions from '../../components/UMActions'
import UMFullNameField from '../UMFullNameField'
import ProToBusinessField from '../../layouts/expenses/ProToBusinessField'

const Actions = ({ record, classes }) => {
  return <UMActions classes={classes} title="Recent significant expenses"></UMActions>
}

const NoPagination = ({ record, classes }) => {
  return null
}

const RecentSignificantExpenses = withStyles(commonStyles.list)(
  ({ classes, parentRecord, record, organisationRecord, dispatch, permissions, ...props }) => {
    return (
      <Card>
        <List
          bulkActions={false}
          sort={{ field: 'amount', order: 'DESC' }}
          filterDefaultValues={{ organisationId: organisationRecord.id, isSignificant: true }}
          pagination={<NoPagination />}
          perPage={5}
          actions={<Actions classes={classes} record={organisationRecord} />}
          {...props}
        >
          <UMWrappedDatagrid emptyText="No significant expenses">
            <UMPriceField sortable={false} source="amount" label="Total Amount" format={v => (v ? v / 100 : 0)} />
            <ReferenceField
              linkType={false}
              source="budget.membership.id"
              reference="memberships"
              label="Employee"
              sortable={false}
            >
              <UMFullNameField firstNameKey="user.firstName" lastNameKey="user.lastName" />
            </ReferenceField>
            <TextField sortable={false} source="partner.name" label="Provider" />
            <TextField sortable={false} source="category.name" label="Category" />
            <ProToBusinessField sortable={false} source="type" label="Type of expense" />
            <UMDateField sortable={false} source="expenseOn" label="Date" />
            {/* <TextField sortable={false} label="Payment method" /> */}
          </UMWrappedDatagrid>
        </List>
      </Card>
    )
  },
)

export default RecentSignificantExpenses
