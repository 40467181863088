import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import get from 'lodash/get'
import classnames from 'classnames'
import { withStyles } from '@material-ui/core'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import { getResourceByName } from '../api/resources'
import me from '../api/resources/me'

const styles = theme => ({
  root: {
    color: 'inherit',
    textDecoration: 'inherit',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  primary: {
    color: theme.palette.primary.main,
  },
})

const Link = ({ children, classes, className, variant, ...props }) => (
  <a
    className={classnames(
      classes.root,
      {
        [classes.primary]: variant === 'primary',
      },
      className,
    )}
    {...props}
  >
    {children}
  </a>
)

Link.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['primary']),
}

const StyledLink = withStyles(styles)(Link)

const getBreadcrumb = (state, resourceName, recordId) => {
  const resource = getResourceByName(resourceName)
  if (!resource) {
    return null
  }
  if (!recordId) {
    return {
      key: resourceName,
      label: resource.getLabel(state),
      link: '/#/' + resourceName,
    }
  }
  const record = resource.getRecordById(state, recordId)
  return {
    key: `${resourceName}/${recordId}`,
    label: record ? resource.getLabelForRecord(record) : '…',
    link: '/#' + resource.getShowPathForId(recordId),
  }
}

const getBreadcrumbs = (state, currentResourceName, currentRecord, currentLabel) => {
  const currentUser = me.getMainRecord(state)
  const role = get(currentUser, 'memberships[0].role')
  const currentResource = getResourceByName(currentResourceName)
  let breadcrumbs = []
  if (currentResource && currentRecord) {
    let resource = currentResource
    let record = currentRecord
    let recordId = currentRecord.id
    while (resource) {
      breadcrumbs.push(getBreadcrumb(state, resource.getName(), recordId))
      let parentResource
      let parentRecordId
      const { name: parentResourceName, idKey: parentRecordIdKey } = resource.getParentResource(role) || {}
      record = record || resource.getRecordById(state, recordId)
      if (record && parentResourceName && parentRecordIdKey) {
        parentResource = getResourceByName(parentResourceName)
        parentRecordId = get(record, parentRecordIdKey)
      }
      resource = parentResource
      recordId = parentRecordId
      record = null
    }
  }

  const duplicates = new Set()
  breadcrumbs = breadcrumbs.reverse().filter(breadcrumb => {
    if (!breadcrumb || duplicates.has(breadcrumb.key)) {
      return false
    }
    duplicates.add(breadcrumb.key)
    return true
  })
  if (currentLabel) {
    breadcrumbs = breadcrumbs.concat([
      {
        key: currentLabel,
        label: currentLabel,
      },
    ])
  }
  return breadcrumbs
}

const Breadcrumbs = ({ breadcrumbs = [] }) => {
  const parents = [...breadcrumbs]
  const current = parents.pop()
  return (
    <>
      <StyledLink key="title" href={'/index.html'}>
        SkiprForBusiness
        <ChevronRightIcon style={{ verticalAlign: 'bottom', opacity: 0.5 }} />
      </StyledLink>
      {parents.filter(Boolean).map(parent => (
        <span key={parent.key}>
          <StyledLink href={parent.link}>{parent.label}</StyledLink>
          <ChevronRightIcon style={{ verticalAlign: 'bottom', opacity: 0.5 }} />
        </span>
      ))}
      {current ? <span key={current.key}>{current.label}</span> : null}
    </>
  )
}

const UMBreadcrumbs = connect((state, ownProps) => ({
  breadcrumbs: getBreadcrumbs(
    state,
    ownProps.resourceName,
    ownProps.forceRecord || ownProps.record,
    ownProps.currentLabel,
  ),
}))(Breadcrumbs)

export default UMBreadcrumbs
