import React from 'react'
import { List, Button, EditButton, TextField, FunctionField } from 'react-admin'
import { Link } from 'react-router-dom'
import AddIcon from '@material-ui/icons/Add'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import { stringify, parse } from 'query-string'
import get from 'lodash/get'
import commonStyles from '../common/styles'
import { UMPagination, UMWrappedDatagrid } from '../../components/UMDatagrid'
import UMDateField from '../../components/UMDateField'
import UMPriceField from '../../components/UMPriceField'
import UMBreadcrumbs from '../../components/UMBreadcrumbs'
import UMActions from '../../components/UMActions'
import MembershipShow from '../memberships/show'
import UMChartField from '../../components/UMChartField'
import memberships from '../../api/resources/memberships'

const Actions = ({ record, classes }) => {
  return (
    <UMActions classes={classes} title="Budgets">
      <Button
        component={Link}
        to={{
          pathname: '/budgets/create',
          search: stringify({ membershipId: record.membership.id }),
        }}
        label="New budget"
      >
        <AddIcon />
      </Button>
      <Button
        component={Link}
        to={{
          pathname: '/virtualCards/create',
          search: stringify({ membershipReference: record.membership.id }),
        }}
        label="New card"
      >
        <AddIcon />
      </Button>
    </UMActions>
  )
}

const BudgetList = withStyles(commonStyles.list)(
  ({ classes, parentRecord, record, dispatch, permissions, ...props }) => {
    return (
      <List
        className={classes.root}
        bulkActions={false}
        actions={<Actions classes={classes} record={record} />}
        sort={{ field: 'endOn', order: 'DESC' }}
        pagination={<UMPagination />}
        perPage={50}
        title={<UMBreadcrumbs resourceName={memberships.getName()} forceRecord={parentRecord} />}
        aside={<MembershipShow permissions={permissions} classes={classes} forceRecord={parentRecord} />}
        {...props}
      >
        <UMWrappedDatagrid emptyText="No budgets" rowClick="show">
          {/* <UMWrappedDatagrid emptyText="No budgets" rowClick={expenses.getListPathForOrganisationId("1e3a13d1-b1c5-45c1-a74c-e00b066abbe6","1a30ab78-4a1a-422a-8455-f5d254f57050")}> */}
          <TextField source="type.code" label="Type" />
          <UMDateField source="startOn" label="Start date" />
          <UMDateField source="endOn" label="End date" />
          <FunctionField
            label="Available amount"
            render={record => '€' + ((record.remaining + record.carriedOver) / 100).toFixed(2)}
            textAlign="right"
            style={{ color: 'blue' }}
          />
          <UMPriceField source="total" format={v => (v ? v / 100 : 0)} />
          <UMPriceField source="used" format={v => (v ? v / 100 : 0)} />
          <UMPriceField source="remaining" format={v => (v ? v / 100 : 0)} />
          <TextField source="carryOver" label="Carry over" style={{ background: 'lightgreen' }} textAlign="right" />
          <UMPriceField source="carriedOver" label="Carried over amount" format={v => (v ? v / 100 : 0)} />
          <UMChartField label="Budget Status" />
          <EditButton />
        </UMWrappedDatagrid>
      </List>
    )
  },
)

export default connect((state, ownProps) => {
  const query = get(ownProps, 'location.search')
  const params = parse(query)
  const filterStr = params.filter || '{}'
  const filter = JSON.parse(filterStr)
  const { membershipId } = filter
  const parentRecord = memberships.getRecordById(state, membershipId) || { id: membershipId }
  const record = { membership: parentRecord }
  return {
    record,
    parentRecord,
  }
})(BudgetList)
