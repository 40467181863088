import React from 'react'
import {
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  NumberInput,
  LongTextInput,
  DateTimeInput,
  FileInput,
  Toolbar,
  SaveButton,
  required,
  number,
  minValue,
  maxValue,
  Button,
  FormDataConsumer,
  TextField,
} from 'react-admin'
import get from 'lodash.get'
import { CURRENCY_SYMBOL_DEFAULT } from '../../config/locale'
import { EXPENSE_TYPES, EXPENSE_TYPE_MIXED } from '../../api/resources/expenses'
import { isDateValid } from '../../utils/dates'
import UMFileField from '../../components/UMFileField'
import { InputAdornment, withStyles } from '@material-ui/core'
import capabilities from '../../utils/capabilities'
import expenses from '../../api/resources/expenses'

const CancelButton = ({ basePath = '', label = 'ra.action.cancel', icon, record, ...rest }) => {
  const goBack = () => window.history.go(-1)
  return (
    <Button style={{ marginLeft: '0.5em' }} onClick={goBack} label={label}>
      {icon}
    </Button>
  )
}

const ExpenseToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
    <CancelButton />
  </Toolbar>
)

const validateTitle = [required()]
const validateCategory = [required()]
const validatePartner = [required()]
const validateType = [required()]
const validateEmployerAmount = [required(), number()]
const validateExpenseOn = [required()]

const validate = values => {
  const errors = {}
  if (!values.expenseOn) {
    errors.expenseOn = ['Required']
  } else if (!isDateValid(values.expenseOn)) {
    errors.expenseOn = ['Date and time required']
  }
  return errors
}

const styles = {
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
  fileInput: {
    '& .previews': {
      marginTop: '0.5rem',
      '& > div': {
        '& a': {
          color: 'inherit',
        },
      },
    },
  },
  hiddenField: {
    display: 'none',
  },
}
let validateAmount = (record, isRefund) => {
  let minAmount = 1
  let maxAmount = 50000
  let minValueMessage = `cannot expense less than 1 euros`
  let maxValueMessage = `cannot expense more than ${maxAmount / 100} euros`
  if (isRefund) {
    minAmount = record.amount ? record.amount : -2000
    maxAmount = 0
    minValueMessage = `cannot refund more than ${(minAmount / 100) * -1} euros`
  }
  return [required(), number(), minValue(minAmount, minValueMessage), maxValue(maxAmount, maxValueMessage)]
}
export default withStyles(styles)(({ mode = 'edit', defaultPartner, permissions, classes, ...props }) => (
  <SimpleForm
    {...props}
    redirect={expenses.getListPathForParentId(get(props.record, 'budget.id', get(props.defaultValue, 'budget.id')))}
    toolbar={<ExpenseToolbar />}
    validate={validate}
  >
    <TextInput source="title" validate={validateTitle} disabled={!props.newExpense} />

    <ReferenceInput
      label="Category"
      source="category.id"
      reference="expenseCategories"
      validate={validateCategory}
      disabled={!props.newExpense}
    >
      <SelectInput optionText="name" optionValue="id" allowEmpty={false} />
    </ReferenceInput>

    <ReferenceInput
      className={classes.hiddenField}
      source="partner.id"
      label="Partner"
      reference="partners"
      validate={validatePartner}
      defaultValue={params => {
        return defaultPartner ? defaultPartner.id : undefined
      }}
      disabled={!props.newExpense}
    >
      <SelectInput optionText="name" optionValue="id" allowEmpty={false} disabled />
    </ReferenceInput>

    <ReferenceInput
      source="tag.id"
      label="Tag"
      reference="expenseTags"
      defaultValue={props.record.tag ? props.record.tag.id : null}
      resettable={true}
      disabled={!props.newExpense}
    >
      <SelectInput optionText="name" optionValue="id" allowEmpty={true} />
    </ReferenceInput>

    <SelectInput
      source="type"
      choices={Object.entries(EXPENSE_TYPES).map(([k, v]) => ({ id: k, name: v }))}
      validate={validateType}
      disabled={!props.newExpense}
    />

    <TextField source="validationStatus" label="status" />

    <FormDataConsumer>
      {({ formData, ...rest }) => {
        const disabled = mode === 'edit' && !formData.internal
        return (
          <NumberInput
            source="amount"
            label="Total amount"
            disabled={disabled}
            format={v => (v === undefined || v === null ? '' : v / 100)}
            parse={v => (v ? Math.round(v * 100) : v)}
            validate={validateAmount(props.record, props.record.isRefund)}
            options={{
              InputProps: {
                startAdornment: <InputAdornment position="start">{CURRENCY_SYMBOL_DEFAULT}</InputAdornment>,
              },
              ...(disabled ? { helperText: 'Amount of external expense cannot be edited' } : null),
            }}
          />
        )
      }}
    </FormDataConsumer>
    <FormDataConsumer>
      {({ formData, ...rest }) => {
        if (formData.type === EXPENSE_TYPE_MIXED) {
          return (
            <NumberInput
              source="employerAmount"
              label="Employer amount"
              format={v => (v === undefined || v === null ? '' : v / 100)}
              parse={v => (v ? Math.round(v * 100) : v)}
              validate={validateEmployerAmount}
              options={{
                InputProps: {
                  startAdornment: <InputAdornment position="start">{CURRENCY_SYMBOL_DEFAULT}</InputAdornment>,
                },
              }}
              {...rest}
            />
          )
        }
        return <TextInput source="employerAmount" defaultValue={null} disabled style={{ display: 'none' }} />
      }}
    </FormDataConsumer>

    <DateTimeInput
      source="expenseOn"
      label="Date"
      disabled={!capabilities.dateInput}
      validate={validateExpenseOn}
      options={!capabilities.dateInput ? { helperText: 'Please use another browser to edit dates' } : null}
    />

    <LongTextInput source="justification" />

    <FileInput className={classes.fileInput} source="attachments" label="Justification documents" multiple>
      <UMFileField source="url" title="name" target="_blank" />
    </FileInput>
  </SimpleForm>
))
