import buildGraphQLProvider from 'ra-data-graphql'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { AUTH_CHECK } from 'react-admin'
import ApolloClient from 'apollo-client'
import middlewares from './middlewares'
import { buildQuery } from './BuildQuery'

export default {
  init: async authProvider => {
    const httpLink = createHttpLink({ uri: process.env.REACT_APP_API_URL_GQL + '' })
    const authLink = setContext(async ({ operationName }, { headers }) => {
      if (operationName !== 'IntrospectionQuery') {
        const token = await authProvider(AUTH_CHECK)
        if (token) {
          return {
            headers: {
              ...headers,
              authorization: `Bearer ${token}`,
            },
          }
        }
      }
      return { headers }
    })

    const client = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache().restore({}),
    })
    const provider = await buildGraphQLProvider({
      client,
      buildQuery,
      introspection: false,
    })
    return async (...args) => {
      for (const middleware of middlewares) {
        await middleware(client, ...args)
      }
      return provider(...args)
    }
  },
}
