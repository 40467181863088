import * as React from 'react'
import { SimpleForm, FileInput, FileField } from 'react-admin'

export default ({ onSubmit, formName, ...props }) => {
  return (
    <SimpleForm form={formName} onSubmit={onSubmit} toolbar={null} {...props}>
      <FileInput source="files" label="Files" accept=".csv">
        <FileField source="src" title="title" />
      </FileInput>
    </SimpleForm>
  )
}
