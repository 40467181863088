import React from 'react'
import { Show, SimpleShowLayout, TextField, DateField, EditButton, BooleanField, FunctionField } from 'react-admin'
import { withStyles, CardActions } from '@material-ui/core'
import commonStyles from '../common/styles'
import UMBreadcrumbs from '../../components/UMBreadcrumbs'
import virtualCards from '../../api/resources/virtualCards'
import ActivateVirtualCardButton from '../../components/POActivateButton'
import UMPriceField from '../../components/UMPriceField'
import SetPinButton from './SetPinButton'
import { connect } from 'react-redux'
import get from 'lodash/get'

const ShowActions = ({ basePath, data, permissions, resource }) => {
  if (permissions === 'FLEET_MGR' || permissions === 'EMPLOYEE')
    return (
      <CardActions>
        <ActivateVirtualCardButton basePath={basePath} record={data} label="Lock Card" status="LOCK_CARD" />
        <ActivateVirtualCardButton basePath={basePath} record={data} label="Unlock Card" status="UNLOCK_CARD" />
        <ActivateVirtualCardButton basePath={basePath} record={data} label="Lost Card" status="LOST_CARD" />
        <ActivateVirtualCardButton basePath={basePath} record={data} label="Stolen Card" status="STOLEN_CARD" />
      </CardActions>
    )
  else
    return (
      <CardActions>
        <ActivateVirtualCardButton basePath={basePath} record={data} label="Activate" status="ACTIVATE" />
        <ActivateVirtualCardButton basePath={basePath} record={data} label="Lock Card" status="LOCK_CARD" />
        <ActivateVirtualCardButton basePath={basePath} record={data} label="Unlock Card" status="UNLOCK_CARD" />
        <ActivateVirtualCardButton basePath={basePath} record={data} label="Lost Card" status="LOST_CARD" />
        <ActivateVirtualCardButton basePath={basePath} record={data} label="Stolen Card" status="STOLEN_CARD" />
        <ActivateVirtualCardButton basePath={basePath} record={data} label="Unblock Pin" status="UNBLOCK_PIN" />
        <ActivateVirtualCardButton
          basePath={basePath}
          record={data}
          label="Convert to physical card"
          status="CONVERT"
        />
        <SetPinButton record={data} />
        <EditButton basePath={basePath} record={data} />
      </CardActions>
    )
}

const VirtualCardShow = withStyles(commonStyles.show)(({ classes, permissions, parentRecord, ...props }) => (
  <Show
    {...props}
    className={classes.root}
    title={<UMBreadcrumbs resourceName={virtualCards.getName()} forceRecord={parentRecord} />}
    actions={<ShowActions classes={classes} permissions={permissions} />}
  >
    <SimpleShowLayout>
      <BooleanField source="isLive" label="is active" />
      <TextField source="status" />
      <FunctionField
        source="pinTryExceeds"
        label="PIN card status (indicates if the user can change the pin code or if it's blocked because of too many attempts)"
        render={(record, source) => (
          <BooleanField record={{ ...record, pinTryExceeds: !record.pinTryExceeds }} source={source} />
        )}
      />
      <TextField source="cardReference" />
      <TextField source="mccRestrictionGroup.name" label="MCC Restriction Group" />
      <TextField source="merchantIdRestrictionGroup.name" label="MID Restriction Group" />
      <UMPriceField source="limitPaymentYear" format={v => (v ? v / 100 : 0)} />
      <UMPriceField source="limitPaymentMonth" format={v => (v ? v / 100 : 0)} />
      <UMPriceField source="limitPaymentWeek" format={v => (v ? v / 100 : 0)} />
      <UMPriceField source="limitPaymentDay" format={v => (v ? v / 100 : 0)} />
      <UMPriceField source="limitPaymentAll" format={v => (v ? v / 100 : 0)} />
      <DateField label="Created on" source="createdOn" />
      <BooleanField source="isPhysical" />
    </SimpleShowLayout>
  </Show>
))

export default connect((state, ownProps) => {
  const cardId = get(ownProps, 'id')
  const parentRecord = virtualCards.getRecordById(state, cardId)
  return {
    parentRecord,
  }
})(VirtualCardShow)
