import budgets from './budgets'
import me from './me'
import memberships from './memberships'
import expenses from './expenses'
import organisations from './organisations'
import partners from './partners'
import expenseCategories from './expenseCategory'
import documents from './documents'
import paymentOrganisations from './paymentOrganisations'
import users from './users'
import virtualCards from './virtualCards'
import shareholders from './shareholders'
import employees from './employees'
import mccRestrictionGroups from './mccRestrictionGroups'
import merchantIdRestrictionGroups from './merchantIdRestrictionGroups'
import expensesbis from './expenses'
import merchants from './merchants'
import expenseTags from './expenseTag'
import cardTransactions from './cardTransactions'

const resources = {
  budgets,
  me,
  memberships,
  expenses,
  organisations,
  partners,
  expenseCategories,
  documents,
  paymentOrganisations,
  users,
  virtualCards,
  shareholders,
  employees,
  mccRestrictionGroups,
  merchantIdRestrictionGroups,
  expensesbis,
  merchants,
  expenseTags,
  cardTransactions,
}

export const getResourceByName = resourceName => resources[resourceName]

export default resources
