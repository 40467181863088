import React from 'react'
import { List, TextField } from 'react-admin'
import UMDatagrid from '../../components/UMDatagrid'
import UMDateField from '../../components/UMDateField'

export default props => (
  <List bulkActions={false} perPage={500} {...props}>
    <UMDatagrid isFullList rowClick="show">
      <TextField source="cardTransactionReference" />
      <TextField source="cardReference" />
      <TextField source="paymentStatus" />
      <TextField source="paymentReference" />
      <TextField source="paymentLocalAmount" />
      <TextField source="merchantName" />
      <UMDateField source="createdOn" />
      <UMDateField source="updatedOn" />
    </UMDatagrid>
  </List>
)
