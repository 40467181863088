import React from 'react'
import { SimpleShowLayout, TextField, ReferenceField, Button, FunctionField } from 'react-admin'
import { Link } from 'react-router-dom'
import get from 'lodash/get'
import { Card } from '@material-ui/core'
import UMDateField from '../../components/UMDateField'
import { ROLE_EMPLOYEE } from '../../config/roles'
import UMFullNameField from '../../components/UMFullNameField'
import memberships from '../../api/resources/memberships'
import UMResourceLoader from '../../components/UMResourceLoader'
import organisations from '../../api/resources/organisations'

const EditMembershipButton = ({ record }) => {
  if (!record) {
    return null
  }
  return (
    <Button
      component={Link}
      to={{
        pathname: `/memberships/${record.id}`,
      }}
      label="Edit Membership"
    ></Button>
  )
}

const MembershipInfoShowLayout = ({ permissions, ...props }) => (
  <SimpleShowLayout {...props}>
    <UMFullNameField
      firstNameKey="user.firstName"
      lastNameKey="user.lastName"
      variant="title"
      addLabel={false}
      gutterBottom
    />
    <ReferenceField
      {...props}
      source="organisation.id"
      reference="organisations"
      linkType={permissions !== ROLE_EMPLOYEE ? 'show' : false}
      label="Organisation"
    >
      <TextField source="name" label="Organisation" />
    </ReferenceField>
    <TextField source="jobTitle" />
    <UMDateField source="createdOn" label="Creation date" />
    <FunctionField
      render={record => record.parent && `${record.parent.user.firstName} ${record.parent.user.lastName}`}
      label="Manager"
    />
    <EditMembershipButton />
  </SimpleShowLayout>
)

export default ({ permissions, basePath, forceRecord, resource, version, classes }) => {
  const showProps = {
    basePath,
    record: forceRecord,
    resource,
    version,
    permissions,
    addLabel: true,
  }

  const membershipId = get(showProps.record, 'id')

  return (
    <Card className={classes.aside}>
      <UMResourceLoader resourceName={memberships.getName()} recordId={membershipId}>
        {data => (
          <UMResourceLoader resourceName={organisations.getName()} recordId={get(data, 'organisation.id')}>
            {() => {
              return <MembershipInfoShowLayout {...showProps} record={data} />
            }}
          </UMResourceLoader>
        )}
      </UMResourceLoader>
    </Card>
  )
}
