import React from 'react'
import { Edit, SimpleForm, TextInput, DateInput, LongTextInput, SelectInput } from 'react-admin'
import UMBreadcrumbs from '../../components/UMBreadcrumbs'
import mccRestrictionGroups from '../../api/resources/mccRestrictionGroups'

const choicesStatus = [
  { text: 'Pending', value: 'PENDING' },
  { text: 'Canceled', value: 'CANCELED' },
  { text: 'Validated', value: 'VALIDATED' },
]

const MccRestrictionGroupEdit = ({ permissions, dispatch, ...props }) => {
  return (
    <Edit
      title={<UMBreadcrumbs resourceName={mccRestrictionGroups.getName()} currentLabel="Edit mcc restriction group" />}
      undoable={false}
      {...props}
    >
      <SimpleForm {...props}>
        <TextInput source="name" />
        <DateInput source="startDate" />
        <SelectInput source="status" choices={choicesStatus} optionText="text" optionValue="value" />
        <LongTextInput source="mccList" />
      </SimpleForm>
    </Edit>
  )
}

export default MccRestrictionGroupEdit
