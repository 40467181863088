import React, { cloneElement, Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { NavLink } from 'react-router-dom'
import { Badge, Chip, MenuItem } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    color: theme.palette.text.secondary,
    display: 'flex',
    alignItems: 'flex-start',
    overflow: 'visible',
  },
  active: {
    color: theme.palette.text.primary,
  },
  icon: { paddingRight: '1.2em' },
  leftBadge: {
    right: 8,
  },
  rightBadge: {
    height: 'auto',
    marginLeft: '0.5em',
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    pointerEvents: 'none',
  },
  rightBadgeLabel: {
    paddingLeft: 10,
    paddingRight: 10,
  },
})

class UMMenuItemLink extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    leftIcon: PropTypes.node,
    onClick: PropTypes.func,
    primaryText: PropTypes.string,
    badge: PropTypes.node,
    dense: PropTypes.bool,
    open: PropTypes.bool.isRequired,
    staticContext: PropTypes.object,
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  }

  static defaultProps = {
    dense: false,
  }

  handleMenuTap = e => {
    this.props.onClick && this.props.onClick(e)
  }

  render() {
    const { classes, className, primaryText, leftIcon, badge, dense, open, staticContext, ...props } = this.props

    const badgeValue = badge ? (badge > 99 ? '99+' : badge) : null

    let leftBadge
    let leftPart
    if (leftIcon) {
      leftPart = <span className={classes.icon}>{cloneElement(leftIcon, { titleAccess: primaryText })}</span>
      leftBadge = dense && !open ? badgeValue : null
      if (leftBadge) {
        leftPart = (
          <Badge
            classes={{
              badge: classes.leftBadge,
            }}
            badgeContent={leftBadge}
            color="secondary"
          >
            {leftPart}
          </Badge>
        )
      }
    }

    const content = primaryText
    const rightBadge =
      leftBadge || !badgeValue ? null : (
        <Chip
          label={badgeValue}
          classes={{
            root: classes.rightBadge,
            label: classes.rightBadgeLabel,
          }}
        />
      )

    return (
      <MenuItem
        className={classnames(classes.root, className)}
        activeClassName={classes.active}
        component={NavLink}
        dense={dense}
        {...props}
        onClick={this.handleMenuTap}
      >
        {leftPart && leftPart}
        {content}
        {rightBadge}
      </MenuItem>
    )
  }
}

export default withStyles(styles)(UMMenuItemLink)
