import React from 'react'
import { List, TextField } from 'react-admin'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { parse } from 'query-string'
import get from 'lodash/get'
import commonStyles from '../../layouts/common/styles'
import { UMWrappedDatagrid } from '../../components/UMDatagrid'
import UMDateField from '../../components/UMDateField'
import UMPriceField from '../../components/UMPriceField'
import UMBreadcrumbs from '../../components/UMBreadcrumbs'
import UMActions from '../../components/UMActions'
import UMChartField from '../../components/UMChartField'
import memberships from '../../api/resources/memberships'
import organisations from '../../api/resources/organisations'
import { stringify } from 'query-string'

const ViewAllEmployees = ({ record }) => {
  return (
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: '/memberships',
        // state: { record: { organisationId: record.id } },
        search: stringify({
          filter: JSON.stringify({ organisationId: record.id }),
        }),
      }}
    >
      VIEW ALL EMPLOYEES
    </Button>
  )
}

const Actions = ({ record, classes }) => {
  return (
    <UMActions classes={classes} title="Top 5 Employees">
      <ViewAllEmployees record={record} />
    </UMActions>
  )
}

const NoPagination = ({ record, classes }) => {
  return null
}

const TopFiveEmployeeList = withStyles(commonStyles.list)(
  ({ classes, parentRecord, record, dispatch, organisationRecord, permissions, ...props }) => {
    return (
      <List
        className={classes.root}
        bulkActions={false}
        actions={<Actions classes={classes} record={organisationRecord} />}
        sort={{ field: 'recent_expenses_count', order: 'DESC' }}
        pagination={<NoPagination />}
        perPage={5}
        hasCreate={false}
        hasEdit={true}
        hasList={true}
        hasShow={false}
        filter={{ organisationId: organisationRecord.id }}
        title={<UMBreadcrumbs resourceName={organisations.getName()} forceRecord={parentRecord} />}
        {...props}
      >
        <UMWrappedDatagrid
          rowClick={(id, basePath, record) => memberships.getShowPathForId(id)}
          emptyText="No employees"
        >
          <TextField sortable={false} source="user.lastName" label="Last name" />
          <TextField sortable={false} source="user.firstName" label="First name" />
          <UMPriceField
            sortable={false}
            source="budgetsSummary.privateTotal"
            label="Budget total"
            format={v => (v ? v / 100 : 0)}
          />
          >
          <UMPriceField
            sortable={false}
            source="budgetsSummary.privateUsed"
            label="Budget spent"
            format={v => (v ? v / 100 : 0)}
          />
          >
          <UMPriceField
            sortable={false}
            source="budgetsSummary.privateRemaining"
            label="Budget remaining"
            format={v => (v ? v / 100 : 0)}
          />
          <UMChartField
            sortable={false}
            usedKey="budgetsSummary.privateUsed"
            totalKey="budgetsSummary.privateTotal"
            label="Budget status"
          />
          <UMDateField sortable={false} source="createdOn" label="Created" />
        </UMWrappedDatagrid>
      </List>
    )
  },
)

export default connect((state, ownProps) => {
  const query = get(ownProps, 'location.search')
  const params = parse(query)
  const filterStr = params.filter || '{}'
  const filter = JSON.parse(filterStr)
  const { organisationId } = filter
  const parentRecord = organisations.getRecordById(state, organisationId) || { id: organisationId }
  const record = { organisation: parentRecord }
  return {
    record,
    parentRecord,
  }
})(TopFiveEmployeeList)
