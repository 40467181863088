import React from 'react'
import get from 'lodash/get'
import { FunctionField } from 'react-admin'
import { withStyles } from '@material-ui/core'
import { RadialBarChart, RadialBar } from 'recharts'

const styles = {
  root: {
    width: 54,
    height: 28,
    overflow: 'hidden',
    pointerEvents: 'none',
  },
  inner: {
    position: 'relative',
    top: -23,
    left: -23,
  },
}

const UMChartField = withStyles(styles)(({ usedKey = 'used', totalKey = 'total', classes, ...props }) => (
  <FunctionField
    render={record => {
      const data = [
        {
          uv: get(record, totalKey, 1),
          fill: '#eeeeee',
        },
        {
          uv: get(record, usedKey, 0),
          fill: '#ff5240',
        },
      ]

      return (
        <div className={classes.root}>
          <div className={classes.inner}>
            <RadialBarChart
              width={100}
              height={100}
              innerRadius="10%"
              outerRadius="80%"
              data={data}
              startAngle={180}
              endAngle={0}
              barCategoryGap={1}
            >
              <RadialBar background clockWise={true} dataKey="uv" cx={'10%'} />
            </RadialBarChart>
          </div>
        </div>
      )
    }}
    {...props}
  />
))

UMChartField.defaultProps = {
  addLabel: true,
}

export default UMChartField
