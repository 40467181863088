import React from 'react'
import { SimpleForm, TextInput, BooleanInput, Toolbar, SaveButton } from 'react-admin'

const UserEditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
)

export default ({ type = 'edit', defaultPartner, permissions, ...props }) => (
  <SimpleForm {...props} toolbar={<UserEditToolbar />}>
    <TextInput source="firstName" />
    <TextInput source="lastName" />
    <TextInput source="email" />
    <TextInput source="phoneNumber" />
    <BooleanInput source="isSuperuser" defaultValue={false} />
  </SimpleForm>
)
