export const choicesUserIncomeRanges = [
  { _key: 'less_twenty', _label: '0-18' },
  { _key: 'less_twenty_five', _label: '19-23' },
  { _key: 'less_thirty', _label: '24-27' },
  { _key: 'less_thirty_five', _label: '28-35' },
  { _key: 'less_fifty_five', _label: '36-56' },
  { _key: 'over_fifty_five', _label: '57-*' },
]

export const choicesUserPersonalAssets = [
  { _key: 'less_two', _label: '0-2' },
  { _key: 'less_twenty_two', _label: '3-22' },
  { _key: 'less_hundred_thirthy', _label: '23-128' },
  { _key: 'less_three_hundred_twenty', _label: '129-319' },
  { _key: 'less_four_hundred_sixty', _label: '320-464' },
  { _key: 'over_four_hundred_sixty', _label: '465-' },
]

export const choicesUserTitle = [
  { _key: 'M', _label: 'M' },
  { _key: 'MME', _label: 'MME' },
  { _key: 'MLLE', _label: 'MLLE' },
]

export const choicesSocialSecretaryName = [
  { _key: 'ATTENTIA', label: 'ATTENTIA' },
  { _key: 'PARTENA', label: 'PARTENA' },
]
export const choicesSocialSecretaryType = [{ _key: 'WORKER', label: 'WORKER' }, { _key: 'CLERK', label: 'CLERK' }]

export const choicesKycLevels = [
  { _value: '0', _text: 'NOT STARTED' },
  { _value: '1', _text: 'PENDING' },
  { _value: '2', _text: 'REGULAR' },
  { _value: '3', _text: 'STRONG' },
  { _value: '4', _text: 'REFUSED' },
  { _value: '5', _text: 'INVESTIGATING' },
]

export const choicesKycReviews = [
  { _value: '0', _text: 'NOT STARTED' },
  { _value: '1', _text: 'PENDING' },
  { _value: '2', _text: 'VALIDATED' },
  { _value: '3', _text: 'REFUSED' },
]

export const choicesUserStatus = [{ _key: 'ACTIVE', label: 'ACTIVE' }, { _key: 'SUSPENDED', label: 'SUSPENDED' }]
