import React from 'react'
import { Show, TabbedShowLayout, Tab, TextField, DateField, EditButton, SelectField, ReferenceField } from 'react-admin'
import { withStyles, CardActions, Chip } from '@material-ui/core'
import commonStyles from '../common/styles'
import UMBreadcrumbs from '../../components/UMBreadcrumbs'
import employees from '../../api/resources/employees'
import StartKYCButton from './StartKycButton'
import { choicesKycLevels, choicesKycReviews } from '../../config/enums'
import { connect } from 'react-redux'
import get from 'lodash/get'
import LinkBudgetsButton from '../../components/POLinkBudgetsButton'
import ResetPasscodeButton from '../../components/POResetPasscodeButton'

const styles = {
  cell: {
    padding: '15px',
  },
}

const ShowActions = ({ basePath, data, resource }) => (
  <CardActions>
    <EditButton basePath={basePath} record={data} />
    <StartKYCButton basePath={basePath} record={data} label="Start KYC" status="START_KYC" />
    <LinkBudgetsButton basePath={basePath} record={data} label="Link Budgets" status="REQUEST" />
    <ResetPasscodeButton basePath={basePath} record={data} label="Reset Passcode" status="REQUEST" />
  </CardActions>
)
const TagsField = ({ record }) => {
  if (!record.paymentProfiles || record.paymentProfiles.length === 0) {
    return <div>No payment profiles</div>
  }
  return (
    <div style={{ display: 'flex', 'justify-content': 'space-between', 'max-width': '150px' }}>
      {record.paymentProfiles.map(item => (
        <Chip key={item} label={item} />
      ))}
    </div>
  )
}

function getColor(status) {
  switch (status) {
    case 'APPROVED':
    case 'complete':
      return 'green'
    case 'not required':
      return 'gray'
    default:
      return 'red'
  }
}

function formatStatus(status) {
  switch (status) {
    case 'SERVICE_REG_UNREGISTERED':
      return 'UNREGISTERED'
    case 'SERVICE_REG_REJECTED':
      return 'REJECTED'
    default:
      return status
  }
}
const PoppyField = withStyles(styles)(({ classes, record }) => {
  if (!record.accountStatus) {
    return <h3>Has no poppy account</h3>
  } else if (!record.accountStatus.servicesStatus || !record.accountStatus.documentsStatus) {
    return <h3>No status is available at the moment</h3>
  }
  const poppyServicesStatus = JSON.parse(record.accountStatus.servicesStatus)
  const poppyDocumentStatus = record.accountStatus.documentsStatus
  return (
    <table>
      <tr>
        <th className={classes.cell}>Service</th>
        <th className={classes.cell}>Status</th>
        <th className={classes.cell}>Documents</th>
      </tr>
      {Object.keys(poppyServicesStatus).map(item => (
        <tr>
          <td className={classes.cell}>{item}</td>
          <td className={classes.cell} style={{ color: getColor(poppyServicesStatus[item]) }}>
            {formatStatus(poppyServicesStatus[item])}
          </td>
          <td
            className={classes.cell}
            style={{ color: getColor(item !== 'Kickscooter sharing' ? poppyDocumentStatus : 'not required') }}
          >
            {item !== 'Kickscooter sharing' ? poppyDocumentStatus : 'Not required'}
          </td>
        </tr>
      ))}
    </table>
  )
})
const EmployeeShow = withStyles(commonStyles.show)(({ classes, permissions, parentRecord, ...props }) => (
  <Show
    {...props}
    className={classes.root}
    title={<UMBreadcrumbs resourceName={employees.getName()} forceRecord={parentRecord} />}
    actions={<ShowActions classes={classes} />}
  >
    <TabbedShowLayout>
      <Tab label="References">
        <TextField source="employeeReference" label="Treezor Id" />
        <TextField source="id" label="Skipr Id" />
        <TextField source="paymentOrganisationId" label="Skipr Organisation Id" />
        <TextField label="Payment profiles" />
        <TagsField />

        {/* <ReferenceField label="Organisation" source="paymentOrganisationId" reference="paymentOrganisations">
          <TextField source="name" />
        </ReferenceField> */}
      </Tab>
      <Tab label="Personal">
        <TextField source="title" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="email" />
        <TextField source="phoneNumber" />
        <DateField source="birthday" />
        <TextField source="address.street" />
        <TextField source="address.city" />
        <TextField source="address.postalCode" />
        <TextField source="address.countryCode" />
        <TextField source="nationality" />
        <TextField source="placeOfBirth" />
        <TextField source="birthCountry" />
        <ReferenceField label="Role" source="membershipReference" reference="memberships">
          <TextField source="role" />
        </ReferenceField>
        <ReferenceField label="Start date" source="membershipReference" reference="memberships">
          <DateField source="startOn" />
        </ReferenceField>
        <ReferenceField label="Job title" source="membershipReference" reference="memberships">
          <TextField source="jobTitle" />
        </ReferenceField>
        <ReferenceField label="Internal reference" source="membershipReference" reference="memberships">
          <TextField source="internalPayrollId" />
        </ReferenceField>
      </Tab>
      <Tab label="Social Secretary">
        <ReferenceField source="membershipReference" reference="memberships" label="Social Secretary name">
          <TextField source="socialSecretary.name" />
        </ReferenceField>
        <ReferenceField source="membershipReference" reference="memberships" label="Social Secretary reference">
          <TextField source="socialSecretary.employeeReference" />
        </ReferenceField>
        <ReferenceField source="membershipReference" reference="memberships" label="Social Secretary type">
          <TextField source="socialSecretary.employeeType" />
        </ReferenceField>
      </Tab>
      <Tab label="Documents">
        <TextField source="identityDocument.status" label="Identity Check" />
      </Tab>
      <Tab label="KYC">
        <TextField source="status" />
        <SelectField source="kycReview" choices={choicesKycReviews} optionText="_text" optionValue="_value" />
        <SelectField source="kycLevel" choices={choicesKycLevels} optionText="_text" optionValue="_value" />
        <TextField source="kycReviewComment" label="Review Comment" />
      </Tab>
      <Tab label="Poppy Status">
        <PoppyField source="accountStatus" />
      </Tab>
      <Tab label="Integration Status">
        <TextField source="integrationStatus" />
      </Tab>
    </TabbedShowLayout>
  </Show>
))

export default connect((state, ownProps) => {
  const employeeId = get(ownProps, 'id')
  const parentRecord = employees.getRecordById(state, employeeId)

  return {
    parentRecord,
  }
})(EmployeeShow)
