import React from 'react'
import { Show, TabbedShowLayout, Tab, TextField } from 'react-admin'
import { withStyles } from '@material-ui/core'
import commonStyles from '../common/styles'
import UMBreadcrumbs from '../../components/UMBreadcrumbs'
import { connect } from 'react-redux'
import get from 'lodash/get'
import cardTransactions from '../../api/resources/cardTransactions'
import UMDateField from '../../components/UMDateField'

const CardTransactionShow = withStyles(commonStyles.show)(({ classes, permissions, parentRecord, ...props }) => (
  <Show
    {...props}
    className={classes.root}
    title={<UMBreadcrumbs resourceName={cardTransactions.getName()} forceRecord={parentRecord} />}
  >
    <TabbedShowLayout>
      <Tab label="card transactions">
        <TextField source="id" />
        <TextField source="cardTransactionReference" />
        <TextField source="cardReference" />
        <TextField source="walletReference" />
        <TextField source="acquirerReference" />
        <TextField source="merchantReference" />
        <TextField source="merchantCity" />
        <TextField source="merchantCountry" />
        <TextField source="mccCode" />
        <TextField source="walletCurrency" />
        <TextField source="paymentAmount" />
        <TextField source="paymentCurrency" />
        <TextField source="fees" />
        <TextField source="paymentCountry" />
        <TextField source="paymentReference" />
        <TextField source="paymentStatus" />
        <TextField source="paymentLocalAmount" />
        <TextField source="authorizationNote" />
        <TextField source="authorizationResponseCode" />
        <TextField source="authorizationIssuerReference" />
        <UMDateField source="createdOn" />
        <UMDateField source="updatedOn" />
        <TextField source="merchantName" />
        <TextField source="isExpensed" />
        <UMDateField source="authorizationIssuerTime" />
      </Tab>
    </TabbedShowLayout>
  </Show>
))

export default connect((state, ownProps) => {
  const cardTransactionId = get(ownProps, 'id')
  const parentRecord = cardTransactions.getRecordById(state, cardTransactionId)

  return {
    parentRecord,
  }
})(CardTransactionShow)
