import React from 'react'
import { Create } from 'react-admin'
import UMBreadcrumbs from '../../components/UMBreadcrumbs'
import users from '../../api/resources/users'
import UserForm from './form'

const UserCreate = ({ permissions, dispatch, ...props }) => {
  const formProps = { permissions }
  return (
    <Create title={<UMBreadcrumbs resourceName={users.getName()} currentLabel="Create user" />} {...props}>
      <UserForm {...formProps} />
    </Create>
  )
}

export default UserCreate
