import React from 'react'
import { Mutation } from 'react-admin'
import { Button } from '@material-ui/core'

const options = {
  undoable: false,
  onSuccess: {
    notification: { body: 'Passcode reset', level: 'info' },
    refresh: true,
  },
  onFailure: {
    notification: { body: 'Error: passcode cannot be reset', level: 'warning' },
  },
}

const ResetPasscodeButton = ({ record, label, status }) => {
  const payload = record
    ? { id: record.membershipReference, data: { id: record.membershipReference, resetPasscodeRequest: status } }
    : {}
  return (
    <Mutation type="UPDATE" resource="memberships" payload={payload} options={options}>
      {activate => (
        <Button label="Reset Passcode" onClick={activate}>
          {label}
        </Button>
      )}
    </Mutation>
  )
}

export default ResetPasscodeButton
