import React from 'react'
import { List, TextField, CardActions, CreateButton, Filter, TextInput } from 'react-admin'
import UMDatagrid from '../../components/UMDatagrid'
import UMDateField from '../../components/UMDateField'

const UsersActions = ({
  bulkActions,
  basePath,
  displayedFilters,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
}) => (
  <CardActions>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <CreateButton basePath={basePath} />
  </CardActions>
)

const UserFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
)

export default props => (
  <List actions={<UsersActions />} bulkActions={false} {...props} perPage={10} filters={<UserFilter />}>
    <UMDatagrid isFullList rowClick="show">
      <TextField source="firstName" />
      <TextField source="lastName" />
      <UMDateField source="createdOn" />
      <UMDateField source="updatedOn" />
      <TextField source="id" />
    </UMDatagrid>
  </List>
)
