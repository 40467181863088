import React from 'react'
import { Create, SimpleForm, TextInput, DateInput } from 'react-admin'
import UMBreadcrumbs from '../../components/UMBreadcrumbs'
import merchantIdRestrictionGroups from '../../api/resources/merchantIdRestrictionGroups'

const MerchantIdRestrictionGroupCreate = ({ permissions, dispatch, ...props }) => {
  return (
    <Create
      title={
        <UMBreadcrumbs
          resourceName={merchantIdRestrictionGroups.getName()}
          currentLabel="Create a new restriction based on MID's"
        />
      }
      {...props}
    >
      <SimpleForm {...props}>
        <TextInput source="name" />
        <DateInput source="startDate" />
      </SimpleForm>
    </Create>
  )
}

export default MerchantIdRestrictionGroupCreate
