import React from 'react'
import { Edit } from 'react-admin'
import { connect } from 'react-redux'
import UMBreadcrumbs from '../../components/UMBreadcrumbs'
import expenses from '../../api/resources/expenses'
import partners from '../../api/resources/partners'
import ExpenseForm from './form'
import { withStyles } from '@material-ui/core'
import commonStyles from '../common/styles'
import BudgetShow from '../budgets/show'
import UMActions from '../../components/UMActions'
import budgets from '../../api/resources/budgets'
import EmployeeExpenseForm from './employeeForm'

const ExpenseEdit = withStyles(commonStyles.show)(
  ({ record, parentRecord, defaultPartner, permissions, dispatch, classes, ...props }) => {
    const formProps = { mode: 'edit', defaultPartner, permissions }
    if (permissions === 'EMPLOYEE') {
      return (
        <Edit
          className={classes.root}
          actions={<UMActions classes={classes} title="Edit expense" />}
          title={<UMBreadcrumbs resourceName={expenses.getName()} permissions={permissions} />}
          aside={
            <BudgetShow
              permissions={permissions}
              classes={classes}
              forceRecord={parentRecord || (record ? { id: record.budget.id } : null)}
            />
          }
          undoable={false}
          {...props}
        >
          <EmployeeExpenseForm {...formProps} />
        </Edit>
      )
    }
    return (
      <Edit
        className={classes.root}
        actions={<UMActions classes={classes} title="Edit expense" />}
        title={<UMBreadcrumbs resourceName={expenses.getName()} permissions={permissions} />}
        aside={
          <BudgetShow
            permissions={permissions}
            classes={classes}
            forceRecord={parentRecord || (record ? { id: record.budget.id } : null)}
          />
        }
        undoable={false}
        {...props}
      >
        <ExpenseForm {...formProps} />
      </Edit>
    )
  },
)

export default connect((state, ownProps) => {
  const record = expenses.getRecordById(state, ownProps.id)
  const parentRecord = record ? budgets.getRecordById(state, record.budget.id) : null
  const defaultPartner = partners.getMainRecord(state)
  return {
    record,
    parentRecord,
    defaultPartner,
  }
})(ExpenseEdit)
