import React from 'react'
import { TabbedForm, FormTab, NumberInput, DisabledInput, ReferenceInput, SelectInput } from 'react-admin'

const validateVirtualCardUpdate = values => {
  const errors = {}
  const limitPaymentYearMax = 20000
  const limitPaymentMonthMax = 20000
  const limitPaymentWeekMax = 20000
  const limitPaymentDayMax = 20000
  const limitPaymentAllMax = 20000
  const pinLength = 4

  if (values.limitPaymentYear > limitPaymentYearMax * 100) {
    errors.limitPaymentYear = ['limit payment year should be less than ' + limitPaymentYearMax]
  }
  if (values.limitPaymentMonth > limitPaymentMonthMax * 100) {
    errors.limitPaymentMonth = ['limit payment month should be less than ' + limitPaymentMonthMax]
  }
  if (values.limitPaymentWeek > limitPaymentWeekMax * 100) {
    errors.limitPaymentWeek = ['limit payment week should be less than ' + limitPaymentWeekMax]
  }
  if (values.limitPaymentDay > limitPaymentDayMax * 100) {
    errors.limitPaymentDay = ['limit payment day should be less than ' + limitPaymentDayMax]
  }
  if (values.limitPaymentAll > limitPaymentAllMax * 100) {
    errors.limitPaymentAll = ['limit payment all should be less than ' + limitPaymentAllMax]
  }

  let currentPin = values.currentPin ? values.currentPin.toString() : null
  let newPin = values.newPin ? values.newPin.toString() : null
  let confirmPin = values.confirmPin ? values.confirmPin.toString() : null
  if (currentPin && currentPin.length !== pinLength) {
    errors.currentPin = ['Pin code must be exactly ' + pinLength + ' digits']
  }

  if (newPin && newPin.length !== pinLength) {
    errors.newPin = ['Pin code must be exactly ' + pinLength + ' digits']
  }

  if (confirmPin && confirmPin.length !== pinLength) {
    errors.confirmPin = ['Pin code must be exactly ' + pinLength + ' digits']
  }

  if (newPin && confirmPin && newPin !== confirmPin) {
    errors.confirmPin = ['Pin confirmation is not the same as new']
  }

  return errors
}

export default ({ type = 'edit', defaultPartner, permissions, ...props }) => (
  <TabbedForm {...props} validate={validateVirtualCardUpdate}>
    <FormTab label="References &amp; Limits" {...props}>
      <DisabledInput source="cardReference" label="Treezor Id" />
      <DisabledInput source="userReference" label="Treezor User Id" />
      <ReferenceInput
        label="Mcc restriction group"
        source="mccRestrictionGroupId"
        reference="mccRestrictionGroups"
        perPage={100}
        defaultValue={() => {
          if (props.record.mccRestrictionGroup) return props.record.mccRestrictionGroup.id
          return null
        }}
      >
        <SelectInput
          source="mccRestrictionGroupId"
          optionText="name"
          optionValue="id"
          allowEmpty={false}
          resettable={true}
        />
      </ReferenceInput>
      <ReferenceInput
        label="MID restriction group"
        source="merchantIdRestrictionGroupId"
        reference="merchantIdRestrictionGroups"
        perPage={100}
        defaultValue={() => {
          if (props.record.merchantIdRestrictionGroup) return props.record.merchantIdRestrictionGroup.id
          return null
        }}
      >
        <SelectInput
          source="merchantIdRestrictionGroupId"
          optionText="name"
          optionValue="id"
          allowEmpty={false}
          resettable={true}
        />
      </ReferenceInput>
      <NumberInput
        source="limitPaymentYear"
        format={v => (v ? v / 100 : 0)}
        parse={v => (v ? v * 100 : 0)}
        inputProps={{
          step: 1,
          min: 0,
          max: 20000,
        }}
      />
      <NumberInput
        source="limitPaymentMonth"
        format={v => (v ? v / 100 : 0)}
        parse={v => (v ? v * 100 : 0)}
        inputProps={{
          step: 1,
          min: 0,
          max: 20000,
        }}
      />
      <NumberInput
        source="limitPaymentWeek"
        format={v => (v ? v / 100 : 0)}
        parse={v => (v ? v * 100 : 0)}
        inputProps={{
          step: 1,
          min: 0,
          max: 20000,
        }}
      />
      <NumberInput
        source="limitPaymentAll"
        format={v => (v ? v / 100 : 0)}
        parse={v => (v ? v * 100 : 0)}
        inputProps={{
          step: 1,
          min: 1,
          max: 20000,
        }}
      />
    </FormTab>
    <FormTab label="Pin">
      <NumberInput source="currentPin" />
      <NumberInput source="newPin" />
      <NumberInput source="confirmPin" />
    </FormTab>
  </TabbedForm>
)
