import { GET_ONE, GET_LIST, GET_MANY, GET_MANY_REFERENCE, CREATE, UPDATE, UPDATE_MANY } from 'react-admin'
import { getResourceByName } from './resources'
import { singularizeString } from '../utils/strings'
import {
  getItemQuery,
  getCollectionQuery,
  getMutationQuery,
  getMutationInput,
  getCollectionQueryParts,
  buildParseItemQueryResponse,
  buildParseCollectionQueryResponse,
  buildParseMutationResponse,
  getMutationManyQuery,
  buildParseMutationManyResponse,
} from './utils'

export const buildQuery = introspectionResults => (fetchType, resourceName, params) => {
  console.debug('Build query', { fetchType, resourceName, params })

  const resource = getResourceByName(resourceName)
  if (!resource) {
    return null
  }

  const query = resource.buildQuery && resource.buildQuery(fetchType, params)
  if (query) {
    return query
  }

  if (resourceName.includes('expenses')) resourceName = 'expenses'
  const collectionKey = resourceName
  const itemKey = singularizeString(resourceName)

  switch (fetchType) {
    case GET_ONE: {
      //FIXME: handle fields that we want only for a get properly
      let fieldsPartial = resource.fieldsPartial
      if (resourceName.includes('employees')) {
        fieldsPartial = resource.fieldsPartialForShow
      }
      return {
        query: getItemQuery(itemKey, fieldsPartial),
        variables: {
          id: params.id,
        },
        parseResponse: buildParseItemQueryResponse(itemKey),
      }
    }
    case GET_LIST:
    case GET_MANY:
    case GET_MANY_REFERENCE: {
      const { variablePart, argumentPart, variables } = getCollectionQueryParts(params, resource.filterTypes)
      //FIXME: handle fields that we want only for a get properly
      let fieldsPartial = resource.fieldsPartial
      if (
        resourceName.includes('organisations') ||
        resourceName.includes('users') ||
        resourceName.includes('memberships') ||
        resourceName.includes('shareholders')
      ) {
        fieldsPartial = resource.fieldsPartialForList
      }
      return {
        query: getCollectionQuery(collectionKey, fieldsPartial, variablePart, argumentPart),
        variables,
        parseResponse:
          (resource.buildParseCollectionQueryResponse && resource.buildParseCollectionQueryResponse(collectionKey)) ||
          buildParseCollectionQueryResponse(collectionKey),
      }
    }
    case CREATE: {
      return {
        query: getMutationQuery(fetchType, itemKey, resource.fieldsPartial),
        variables: {
          input: getMutationInput(resource, params.data),
        },
        parseResponse: buildParseMutationResponse(fetchType, itemKey),
      }
    }
    case UPDATE: {
      return {
        query: getMutationQuery(fetchType, itemKey, resource.fieldsPartial),
        variables: {
          input: getMutationInput(resource, params.data),
          id: params.id,
        },
        parseResponse: buildParseMutationResponse(fetchType, itemKey),
      }
    }
    case UPDATE_MANY: {
      return {
        query: getMutationManyQuery(fetchType, itemKey, resource.fieldsPartial),
        variables: {
          input: params.data,
          ids: params.ids,
        },
        parseResponse: buildParseMutationManyResponse(fetchType, itemKey),
      }
    }
    case 'CUSTOM': {
      return {
        query: params.query,
        variables: { ...params.data },
        parseResponse: response => ({ data: response.data }),
      }
    }
    default:
      return null
  }
}
