import React from 'react'
import get from 'lodash/get'
import { FileField } from 'react-admin'
import { formatFileSize } from '../utils/numbers'

const getTitleWithSize = (record, title, size) => {
  const titleValue = get(record, title) || title
  if (size === false) {
    return titleValue
  }
  const sizeValue = get(record, size || 'size', get(record, 'rawFile.size'))
  return titleValue + (sizeValue ? ' (' + formatFileSize(sizeValue) + ')' : '')
}

const UMFileField = ({ record, title, source, size, ...props }) => {
  const sourceValue = get(record, source)
  if (Array.isArray(sourceValue)) {
    const newSourceValue = sourceValue.map(file => {
      const titleValue = getTitleWithSize(file, title, size)
      return { ...file, [title]: titleValue }
    })
    return <FileField {...props} ecord={{ ...record, [source]: newSourceValue }} title={title} source={source} />
  }
  const titleValue = getTitleWithSize(record, title, size)
  return <FileField {...props} record={{ ...record, [title]: titleValue }} title={title} source={source} />
}

UMFileField.defaultProps = {
  addLabel: true,
}

export default UMFileField
