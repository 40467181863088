import get from 'lodash/get'
import { formatDate } from '../../utils/dates'

const fieldsPartial = `
  id
  name
`

const filterTypes = {}

const getParentResource = () => null

const getName = () => 'expenseTag'

const getLabel = () => 'Expense tags'

const getRecordById = (state, id) => get(state, `admin.resources.expenseTag.data.${id}`, null)

const getLabelForRecord = record => {
  if (!record) {
    return ''
  }
  return `${formatDate(record.name)}`
}

export default {
  fieldsPartial,
  getParentResource,
  getName,
  getLabel,
  getRecordById,
  getLabelForRecord,
  filterTypes,
}
