import React from 'react'
import {
  SimpleForm,
  SelectInput,
  DateTimeInput,
  TextInput,
  required,
  Toolbar,
  SaveButton,
  Button,
  ReferenceInput,
} from 'react-admin'
import { choicesSocialSecretaryName, choicesSocialSecretaryType } from '../../config/enums'
import UMFullNameField from '../../components/UMFullNameField'

const CancelButton = ({ basePath = '', label = 'ra.action.cancel', icon, record, ...rest }) => {
  const goBack = () => window.history.go(-1)
  return (
    <Button style={{ marginLeft: '0.5em' }} onClick={goBack} label={label}>
      {icon}
    </Button>
  )
}

const choicesMembershipRole = [{ _key: 'EMPLOYEE', label: 'EMPLOYEE' }, { _key: 'FLEET_MGR', label: 'FLEET_MGR' }]

const validateRole = [required()]
const validateJobTitle = [required()]
const validateStartOn = [required()]

const validateMembershipForm = values => {
  // const errors = {}
  // const minStartOn = new Date()
  // if (values.startOn && values.startOn < minStartOn) {
  //   errors.confirmtPin = ['Start day should be greater then ' + minStartOn]
  // }
  // return errors
}

const MembershipEditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
    <CancelButton />
  </Toolbar>
)

export default ({ type = 'edit', defaultPartner, organisationId, classes, permissions, ...props }) => (
  <SimpleForm {...props} validate={validateMembershipForm} toolbar={<MembershipEditToolbar />}>
    <TextInput source="jobTitle" validate={validateJobTitle} />
    <SelectInput
      source="role"
      choices={choicesMembershipRole}
      optionText="label"
      optionValue="_key"
      allowEmpty={false}
      validate={validateRole}
    />
    <DateTimeInput source="startOn" validate={validateStartOn} />
    <TextInput source="internalPayrollId" label="Internal reference" />
    <SelectInput
      source="socialSecretary.name"
      choices={choicesSocialSecretaryName}
      optionText="label"
      optionValue="_key"
      allowEmpty={true}
      label="Social Secretary name"
    />
    <TextInput source="socialSecretary.employeeReference" label="Social Secretary reference" />
    <SelectInput
      source="socialSecretary.employeeType"
      choices={choicesSocialSecretaryType}
      optionText="label"
      optionValue="_key"
      allowEmpty={true}
      label="Social Secretary type"
    />
    <ReferenceInput
      label="Manager"
      source="parent.id"
      reference="memberships"
      filter={{ organisationId: props?.record?.organisation?.id }}
    >
      <SelectInput
        source="membershipReference"
        optionText={<UMFullNameField firstNameKey="user.firstName" lastNameKey="user.lastName" />}
        optionValue="id"
        allowEmpty={true}
        resettable={true}
      />
    </ReferenceInput>
  </SimpleForm>
)
