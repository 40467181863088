import React from 'react'
import { List, TextField, CardActions, CreateButton, RefreshButton } from 'react-admin'
import UMDatagrid from '../../components/UMDatagrid'

const CardsActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
}) => (
  <CardActions>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <CreateButton basePath={basePath} />
    <RefreshButton />
    {/* Add your custom actions */}
  </CardActions>
)

export default props => (
  <List actions={<CardsActions />} bulkActions={false} {...props}>
    <UMDatagrid isFullList rowClick="show">
      <TextField source="restrictionGroupReference" label="Treezor Id" />
      <TextField source="name" />
      <TextField source="startDate" label="Start Date" />
    </UMDatagrid>
  </List>
)
