import React from 'react'
import { Mutation } from 'react-admin'
import { Button } from '@material-ui/core'

const options = {
  undoable: false,
  onSuccess: {
    notification: { body: 'Virtual Card activated', level: 'info' },
    redirectTo: '/virtualcards',
  },
  onFailure: {
    notification: { body: 'Error: virtual card can not be activated', level: 'warning' },
  },
}

const ActivateVirtualCardButton = ({ record, label, status }) => {
  console.log('activate')
  const payload = record ? { id: record.id, data: { id: record.id, status: status } } : {}
  return (
    <Mutation type="UPDATE" resource="virtualCards" payload={payload} options={options}>
      {activate => (
        <Button label="Activate" onClick={activate}>
          {label}
        </Button>
      )}
    </Mutation>
  )
}

export default ActivateVirtualCardButton
