import React from 'react'
import { List, TextField, CardActions, SelectField, ReferenceField } from 'react-admin'
import UMDatagrid from '../../components/UMDatagrid'
import UMDateField from '../../components/UMDateField'
import { choicesKycReviews, choicesKycLevels } from '../../config/enums'

const PaymentOrganisationActions = ({
  bulkActions,
  basePath,
  displayedFilters,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
}) => (
  <CardActions>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    {/* <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
    <RefreshButton /> */}
  </CardActions>
)

export default props => (
  <List
    actions={<PaymentOrganisationActions />}
    bulkActions={false}
    perPage={10}
    {...props}
    sort={{ field: 'kycReview', order: 'ASC' }}
  >
    <UMDatagrid isFullList rowClick="show">
      <TextField source="name" label="name" />
      <ReferenceField
        label="Organisation"
        source="organisationReference"
        reference="organisations"
        perPage={500}
        allowEmpty
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="userReference" label="Treezor Id" />
      <SelectField source="kycReview" choices={choicesKycReviews} optionText="_text" optionValue="_value" />
      <SelectField source="kycLevel" choices={choicesKycLevels} optionText="_text" optionValue="_value" />
      <TextField source="identityDocument.status" label="Identity Check" />
      <UMDateField source="createdOn" />
      <UMDateField source="updatedOn" />
    </UMDatagrid>
  </List>
)
