import React, { Fragment } from 'react'
import { List, TextField, BulkDeleteButton, ReferenceField, Filter, TextInput } from 'react-admin'
import { UMPagination, UMWrappedDatagrid } from '../../components/UMDatagrid'
import SyncWithTreezorButton from './SyncWithTreezorButton'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import { stringify } from 'query-string'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import commonStyles from '../common/styles'
import OrganisationShow from '../organisations/show'
import get from 'lodash/get'
import { parse } from 'query-string'
import organisations from '../../api/resources/organisations'
import virtualCards from '../../api/resources/virtualCards'
import UMBreadcrumbs from '../../components/UMBreadcrumbs'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import UMActions from '../../components/UMActions'
import UMResourceLoader from '../../components/UMResourceLoader'
import ImportEmployeesButton from '../../components/ImportEmployeesCsvButton'

const BudgetsButton = ({ record }) => {
  return (
    <Button
      onClick={e => {
        e.stopPropagation()
      }}
      component={Link}
      variant="outlined"
      color="primary"
      to={{
        pathname: '/budgets',
        // state: { record: { organisationId: record.id } },
        search: '?' + stringify({ filter: JSON.stringify({ membershipId: record.membershipReference }) }),
      }}
    >
      <AttachMoneyIcon />
      Budgets
    </Button>
  )
}

const VirtualCardsButtons = ({ record }) => {
  let cards = record.virtualCards
  if (cards && cards.length === 1) {
    return (
      <Button
        onClick={e => {
          e.stopPropagation()
        }}
        component={Link}
        variant="outlined"
        color="primary"
        to={{
          pathname: '/virtualCards/' + cards[0].id + '/show',
        }}
      >
        <CreditCardIcon />
        Card
      </Button>
    )
  } else if (cards && cards.length > 1) {
    return (
      <div>
        {cards.map((card, index) => {
          return (
            <Button
              onClick={e => {
                e.stopPropagation()
              }}
              component={Link}
              variant="outlined"
              color="primary"
              to={{
                pathname: '/virtualCards/' + card.id + '/show',
              }}
            >
              <CreditCardIcon />
              Card{index}
            </Button>
          )
        })}
      </div>
    )
  } else {
    return <div />
  }
}

const CardTransactionsButton = ({ record }) => {
  let cards = record.virtualCards
  if (cards && cards.length === 1) {
    return (
      <UMResourceLoader resourceName={virtualCards.getName()} recordId={cards[0].id}>
        {data => {
          if (data) {
            return (
              <Button
                onClick={e => {
                  e.stopPropagation()
                }}
                component={Link}
                variant="outlined"
                color="primary"
                to={{
                  pathname: '/cardTransactions?filter=%7B"cardReference"%3A"' + data.cardReference + '"%7D',
                }}
              >
                <CreditCardIcon />
                Transactions
              </Button>
            )
          } else {
            return <div />
          }
        }}
      </UMResourceLoader>
    )
  } else if (cards && cards.length > 1) {
    return (
      <div>
        {cards.map((card, index) => {
          return (
            <UMResourceLoader resourceName={virtualCards.getName()} recordId={card.id}>
              {data => {
                if (data) {
                  return (
                    <Button
                      onClick={e => {
                        e.stopPropagation()
                      }}
                      component={Link}
                      variant="outlined"
                      color="primary"
                      to={{
                        pathname: '/cardTransactions?filter=%7B"cardReference"%3A"' + data.cardReference + '"%7D',
                      }}
                    >
                      <CreditCardIcon />
                      Transactions Card{index}
                    </Button>
                  )
                } else {
                  return <div />
                }
              }}
            </UMResourceLoader>
          )
        })}
      </div>
    )
  } else {
    return <div />
  }
}

const EmployeeBulkActionButtons = props => (
  <Fragment>
    {/* Add the default bulk delete action */}
    <SyncWithTreezorButton {...props} />
    <BulkDeleteButton {...props} />
  </Fragment>
)

const CreateRelatedMembership = ({ record }) => {
  return (
    <Button
      component={Link}
      to={{
        pathname: '/employees/create',
        search: '?organisationId=' + record,
      }}
    >
      New Employee
    </Button>
  )
}

const Actions = ({ record, classes, permissions }) => {
  if (permissions === 'FLEET_MGR' || permissions === 'EMPLOYEE')
    return <UMActions classes={classes} title="Employees"></UMActions>
  else
    return (
      <UMActions classes={classes} title="Employees">
        <CreateRelatedMembership record={record} />
        <ImportEmployeesButton label="Import employees" organisationReference={record} />
      </UMActions>
    )
}

const EmployeeFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
)

const EmployeeList = withStyles(commonStyles.list)(
  ({ classes, parentRecord, record, organisationReference, dispatch, permissions, ...props }) => {
    return (
      <List
        className={classes.root}
        actions={<Actions classes={classes} record={organisationReference} permissions={permissions} />}
        bulkActionButtons={<EmployeeBulkActionButtons />}
        perPage={100}
        pagination={<UMPagination />}
        title={<UMBreadcrumbs resourceName={organisations.getName()} forceRecord={parentRecord} />}
        aside={<OrganisationShow permissions={props.permissions} classes={classes} forceRecord={parentRecord} />}
        {...props}
        filters={<EmployeeFilter />}
      >
        <UMWrappedDatagrid isFullList rowClick="show">
          <TextField source="lastName" />
          <TextField source="firstName" />
          <ReferenceField label=" Internal reference" source="membershipReference" reference="memberships">
            <TextField source="internalPayrollId" />
          </ReferenceField>
          {/* <TextField style={{ color: '#ff5240' }} source="paymentOrganisation.name" label="Legal Name" /> */}
          <TextField source="employeeReference" label="Treezor Id" />
          {/* <TextField style={{ color: '#ff5240' }} source="paymentOrganisation.name" label="Organisation" /> */}
          <TextField source="identityDocument.status" label="Identity Check" />
          <BudgetsButton />
          <VirtualCardsButtons />
          <CardTransactionsButton />
          <TextField source="integrationStatus" label="Integration" />
        </UMWrappedDatagrid>
      </List>
    )
  },
)

export default connect((state, ownProps) => {
  const query = get(ownProps, 'location.search')
  const params = parse(query)
  const filterStr = params.filter || '{}'
  const filter = JSON.parse(filterStr)
  const { organisationReference } = filter
  const parentRecord = organisations.getRecordById(state, organisationReference) || { id: organisationReference }
  const record = { organisation: parentRecord }

  return {
    record,
    parentRecord,
    organisationReference,
  }
})(EmployeeList)
