import buildGraphQLProvider from 'ra-data-graphql'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { AUTH_CHECK } from 'react-admin'
import { buildQuery } from './BuildQuery'
import mergeImages from 'merge-images'

const convertFileToBase64 = rawFile =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(rawFile)
    reader.onload = () => resolve(reader.result.split('base64,')[1])
    reader.onerror = reject
  })

const handleUpload = requestHandler => async (type, resource, params) => {
  if (['CREATE', 'UPDATE'].includes(type)) {
    let file
    if (params.data.files) {
      console.log('handleUpdate', params.data.files)
      if (params.data.files.length) {
        file = params.data.files[0]
      } else {
        file = params.data.files
      }
      delete params.data.files
    }
    if (file) {
      const fileContentBase64 = await convertFileToBase64(file.rawFile)
      params.data = {
        ...params.data,
        fileContentBase64,
      }
    }
    console.log('handleUpload', params)
  }
  return requestHandler(type, resource, params)
}

const handleUpdloadIdentityDocument = requestHandler => async (type, resource, params) => {
  if (['UPDATE'].includes(type)) {
    let file
    const paramDocument = params.data.identityDocument ? params.data.identityDocument : null
    if (paramDocument) {
      file = paramDocument.length ? paramDocument[0] : paramDocument
    }
    if (file && file.fileContentBase64 && paramDocument.documentTypeReference) {
      //if length, means multifile input component (shareholder)
      if (file.fileContentBase64.length) {
        if (file.fileContentBase64.length < 3) {
          var fileContentBase64
          //if only one file opload, previous comportment but adapted to multiple inputFile component
          if (file.fileContentBase64.length === 1) {
            fileContentBase64 = await convertFileToBase64(file.fileContentBase64[0].rawFile)
          }
          //if two files upload, merge them and return the b64
          else if (file.fileContentBase64.length === 2) {
            fileContentBase64 = await mergeImgToBase64(file.fileContentBase64[0].src, file.fileContentBase64[1].src)
          }
        }
      }
      //if no length, single input component (paymentOrganisation)
      else {
        fileContentBase64 = await convertFileToBase64(file.fileContentBase64.rawFile)
      }
      params.data = {
        ...params.data,
        identityDocument: {
          fileContentBase64,
          name: 'identity',
          documentTypeReference: paramDocument.documentTypeReference,
        },
      }
    }
  }
  return requestHandler(type, resource, params)
}

const handleUpdloadResidenceDocument = requestHandler => async (type, resource, params) => {
  if (['UPDATE'].includes(type)) {
    let file
    const paramDocument = params.data.residenceDocument ? params.data.residenceDocument : null
    if (paramDocument) {
      file = paramDocument.length ? paramDocument[0] : paramDocument
    }
    if (file && file.fileContentBase64 && paramDocument.documentTypeReference) {
      const fileContentBase64 = await convertFileToBase64(file.fileContentBase64.rawFile)
      params.data = {
        ...params.data,
        residenceDocument: {
          fileContentBase64,
          name: 'residence',
          documentTypeReference: paramDocument.documentTypeReference,
        },
      }
    }
  }
  return requestHandler(type, resource, params)
}

const handleUpdloadAdditionalDocument = requestHandler => async (type, resource, params) => {
  if (['UPDATE'].includes(type)) {
    let file
    const paramDocument = params.data.additionalDocument ? params.data.additionalDocument : null
    if (paramDocument) {
      file = paramDocument.length ? paramDocument[0] : paramDocument
    }
    if (file && file.fileContentBase64 && paramDocument.documentTypeReference) {
      const fileContentBase64 = await convertFileToBase64(file.fileContentBase64.rawFile)
      params.data = {
        ...params.data,
        additionalDocument: {
          fileContentBase64,
          name: 'additional',
          documentTypeReference: paramDocument.documentTypeReference,
        },
      }
    }
  }
  return requestHandler(type, resource, params)
}

const handleUpdloadBceDocument = requestHandler => async (type, resource, params) => {
  if (['UPDATE'].includes(type)) {
    let file
    const paramDocument = params.data.bceDocument ? params.data.bceDocument : null
    if (paramDocument) {
      file = paramDocument.length ? paramDocument[0] : paramDocument
    }
    if (file && file.fileContentBase64 && paramDocument.documentTypeReference) {
      const fileContentBase64 = await convertFileToBase64(file.fileContentBase64.rawFile)
      params.data = {
        ...params.data,
        bceDocument: {
          fileContentBase64,
          name: 'bce',
          documentTypeReference: paramDocument.documentTypeReference,
        },
      }
    }
  }
  return requestHandler(type, resource, params)
}

export default {
  init: async authProvider => {
    const httpLink = createHttpLink({ uri: process.env.REACT_APP_API_URL_GQL_WALLET + '' })
    const authLink = setContext(async ({ operationName }, { headers }) => {
      if (operationName !== 'IntrospectionQuery') {
        const token = await authProvider(AUTH_CHECK)
        if (token) {
          return {
            headers: {
              ...headers,
              authorization: `Bearer ${token}`,
            },
          }
        }
      }
      return { headers }
    })

    const link = authLink.concat(httpLink)

    const provider = await buildGraphQLProvider({
      clientOptions: { link: link },
      buildQuery,
      introspection: false,
    })

    return handleUpdloadBceDocument(
      handleUpdloadAdditionalDocument(
        handleUpdloadResidenceDocument(handleUpdloadIdentityDocument(handleUpload(provider))),
      ),
    )
  },
}

const mergeImgToBase64 = (srcImg1, srcImg2) =>
  new Promise((resolve, reject) => {
    var img1 = new Image()
    img1.src = srcImg1
    var img2 = new Image()
    img2.src = srcImg2
    img1.onload = function() {
      img2.onload = function() {
        try {
          mergeImages([{ src: img1.src, x: 0, y: 0 }, { src: img2.src, x: 0, y: img1.height }], {
            height: img1.height + img2.height,
          }).then(b64 => {
            resolve(b64.split('base64,')[1])
          })
        } catch (err) {
          reject(err)
        }
      }
    }
  })
