import get from 'lodash/get'
import { stringify } from 'query-string'
import { ROLE_EMPLOYEE } from '../../config/roles'
import budgets from './budgets'

const fieldsPartial = `
  id
  organisation {
    id
    name
  }
  user {
    id
    firstName
    lastName
  }
  role
  startOn
  endOn
  jobTitle
  createdOn
  updatedOn
  internalPayrollId
  currentBudgets{
    id
    total
    remaining
    used
    type{
      name
      code
      profile
    }
  }
  socialSecretary{
    name
    employeeReference
    employeeType
  }
  parent {
    id
    user {
      id
      firstName
      lastName
    }
  }
`

const filterTypes = {
  ids: '[ID]',
  organisationId: 'ID',
}

const getMutationInputPair = (key, data) => {
  switch (key) {
    case 'id':
    case 'updatedOn':
    case 'createdOn':
    case 'currentBudgets':
    case 'organisation':
    case 'user':
      return null
    case 'startOn':
      return [key, data[key]]
    case 'parent':
      return [key + 'Id', !data[key] || data[key]['id'] === '' ? null : data[key]['id']]
    case 'socialSecretary':
      if (!data[key]) {
        return [key, null]
      }
      var name = data[key]['name']
      var employeeReference = data[key]['employeeReference']
      var employeeType = data[key]['employeeType']
      return name && name !== '' && employeeReference && employeeReference !== '' && employeeType && employeeType !== ''
        ? [
            key,
            {
              name: name,
              employeeReference: employeeReference,
              employeeType: employeeType,
            },
          ]
        : [key, null]
    default:
      return [key, data[key]]
  }
}

const getParentResource = role => ({
  name: role === ROLE_EMPLOYEE ? null : 'organisations',
  idKey: role === ROLE_EMPLOYEE ? null : 'organisation.id',
})

const getName = () => 'memberships'

const getLabel = () => 'Employees'

const getRecordById = (state, id) => get(state, `admin.resources.memberships.data.${id}`, null)

const getShowPathForId = id => budgets.getListPathForParentId(id)

const getListPathForParentId = organisationId =>
  '/' + getName() + '?' + stringify({ filter: JSON.stringify({ organisationId }) })

const getLabelForRecord = record => {
  if (!record) {
    return ''
  }
  return `${record.user.firstName} ${record.user.lastName}`
}

const fieldsPartialForList = `
  id
  user {
    id
    firstName
    lastName
  }
  role
  startOn
  endOn
  jobTitle
  internalPayrollId
  createdOn
  updatedOn
  socialSecretary{
    name
    employeeReference
    employeeType
  }
  organisation {
    id
    name
  }
  parent {
    id
    user {
      id
      firstName
      lastName
    }
  }
`

export default {
  fieldsPartial,
  getParentResource,
  getName,
  getLabel,
  getRecordById,
  getShowPathForId,
  getListPathForParentId,
  getLabelForRecord,
  filterTypes,
  getMutationInputPair,
  fieldsPartialForList,
}
