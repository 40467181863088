import React from 'react'
import { Edit, SimpleForm, TextInput, SelectInput } from 'react-admin'
import UMBreadcrumbs from '../../components/UMBreadcrumbs'
import merchants from '../../api/resources/merchants'

const choicesCategories = [
  { text: 'Car', value: 'car' },
  { text: 'Bike', value: 'bike' },
  { text: 'Motor Scooter', value: 'motor_scooter' },
  { text: 'Kick Scooter', value: 'kick_scooter' },
  { text: 'Public transport', value: 'public_transport' },
  { text: 'Taxi', value: 'taxi' },
  { text: 'Parking', value: 'parking' },
  { text: 'Purchase of bike, e-scooter, e-step', value: 'e-vehicle_purchase' },
  { text: 'Purchase of equipment', value: 'e-equipment_purchase' },
  { text: 'Leasing of bike, e-scooter, e-step', value: 'e-vehicle_leasing' },
  { text: 'House rent', value: 'housing_rent' },
  { text: 'House interest', value: 'housing_interest' },
  { text: 'Bike lease', value: 'company_lease_bike' },
  { text: 'Toll', value: 'fuel_charging_parking_toll' },
  { text: 'Car rent or lease (long term)', value: 'car_rent_or_lease_long_term' },
  { text: 'Parking at station', value: 'parking_at_station' },
  { text: 'Fuel or charging', value: 'fuel_or_charging' },
  { text: 'Unknown', value: 'unknown' },
]

const MerchantsEdit = ({ permissions, dispatch, ...props }) => {
  return (
    <Edit
      title={<UMBreadcrumbs resourceName={merchants.getName()} currentLabel="Make mappings" />}
      undoable={false}
      {...props}
    >
      <SimpleForm {...props}>
        <TextInput source="merchantName" disabled />
        <TextInput source="merchantReference" disabled />
        <TextInput source="name" />
        <SelectInput source="categoryCode" choices={choicesCategories} optionText="text" optionValue="value" />
      </SimpleForm>
    </Edit>
  )
}

export default MerchantsEdit
