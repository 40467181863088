import React from 'react'
import { List, TextField, CardActions, CreateButton, ExportButton, RefreshButton, Filter, TextInput } from 'react-admin'
import UMDatagrid from '../../components/UMDatagrid'
import UMDateField from '../../components/UMDateField'

const OrganisationActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
}) => (
  <CardActions>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
    <RefreshButton />
    {/* Add your custom actions */}
  </CardActions>
)

const OrganisationFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
)

export default props => (
  <List actions={<OrganisationActions />} bulkActions={false} {...props} perPage={10} filters={<OrganisationFilter />}>
    <UMDatagrid isFullList rowClick="show">
      <TextField source="name" />
      <UMDateField source="createdOn" />
      <UMDateField source="updatedOn" />
    </UMDatagrid>
  </List>
)
