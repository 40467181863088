import { createStyles } from '@material-ui/core'

const list = theme => {
  return createStyles({
    root: {
      flexDirection: 'row-reverse',
    },
    aside: {
      width: 240,
      flexShrink: 0,
      marginRight: '1em',
      paddingTop: 5,
    },
    actions: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottomColor: theme.palette.divider,
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      boxSizing: 'border-box',
      minHeight: 2 + theme.spacing.unit * 4 + theme.spacing.unit * 2 + 1,
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
    },
    actionsTitle: {
      margin: 0,
    },
  })
}

const commonStyles = {
  show: list,
  create: list,
  list,
}

export default commonStyles
