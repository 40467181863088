import get from 'lodash/get'

const fieldsPartial = `
  id
  cardTransactionReference
  cardReference
  walletReference
  acquirerReference
  merchantReference
  merchantCity
  merchantCountry
  mccCode
  walletCurrency
  paymentAmount
  paymentCurrency
  fees
  paymentCountry
  paymentReference
  paymentStatus
  paymentLocalAmount
  authorizationNote
  authorizationResponseCode
  authorizationIssuerReference
  createdOn
  updatedOn
  merchantName
  isExpensed
  authorizationIssuerTime
`

const filterTypes = {
  ids: '[ID]',
  cardReference: 'String',
}

const buildQuery = (fetchType, resourceName, params) => {
  return null
}

const getMutationInputPair = (key, data) => {
  switch (key) {
    default:
      return [key, data[key]]
  }
}

const getParentResource = role => ({
  name: 'virtualCards',
  idKey: 'cardReference',
})

const getName = () => 'cardTransactions'

const getLabel = () => 'Card - Transactions'

const getRecordById = (state, id) => get(state, `admin.resources.cardTransactions.data.${id}`, null)

const getShowPathForId = id => '/' + getName() + '/' + id + '/show'

const getLabelForRecord = record => {
  return 'Card Transactions'
}

export default {
  fieldsPartial,
  buildQuery,
  getParentResource,
  getName,
  getLabel,
  getRecordById,
  getShowPathForId,
  getLabelForRecord,
  filterTypes,
  getMutationInputPair,
}
