import React from 'react'
import { connect } from 'react-redux'
import get from 'lodash.get'
import { parse } from 'query-string'
import { Create, SimpleForm, ReferenceInput, SelectInput, TextInput, required } from 'react-admin'
import UMBreadcrumbs from '../../components/UMBreadcrumbs'
import virtualCards from '../../api/resources/virtualCards'

const FullNameField = ({ record }) => (
  <span>
    {record.user.firstName} {record.user.lastName}
  </span>
)

const fieldRequired = [required()]

const VirtualCardCreate = ({ membershipReference, permissions, dispatch, ...props }) => {
  return (
    <Create
      title={<UMBreadcrumbs resourceName={virtualCards.getName()} currentLabel="Create virtual card" />}
      {...props}
    >
      <SimpleForm {...props}>
        {membershipReference != null ? (
          <TextInput label="Membership" source="membershipReference" defaultValue={membershipReference} />
        ) : (
          <ReferenceInput
            label="Membership"
            source="membership.id"
            reference="memberships"
            perPage={2000}
            validate={fieldRequired}
          >
            <SelectInput
              source="membershipReference"
              optionText={<FullNameField />}
              optionValue="id"
              allowEmpty={false}
            />
          </ReferenceInput>
        )}
        <TextInput source="pinCode" />
        <ReferenceInput
          label="Mcc restriction group"
          source="mccRestrictionGroupId"
          reference="mccRestrictionGroups"
          perPage={100}
        >
          <SelectInput source="mccRestrictionGroupId" optionText="name" optionValue="id" allowEmpty={false} />
        </ReferenceInput>
        <ReferenceInput
          label="MID restriction group"
          source="merchantIdRestrictionGroup"
          reference="merchantIdRestrictionGroups"
          perPage={100}
          validate={fieldRequired}
        >
          <SelectInput source="merchantIdRestrictionGroup" optionText="name" optionValue="id" allowEmpty={false} />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  )
}

export default connect((state, ownProps) => {
  const query = get(ownProps, 'location.search')
  const params = parse(query)
  const { membershipReference } = params

  return {
    membershipReference,
  }
})(VirtualCardCreate)
