import React, { Component } from 'react'
import { Admin, Resource } from 'react-admin'
import DomainIcon from '@material-ui/icons/Domain'
import AuthProvider from './api/AuthProvider'
import OrganisationList from './layouts/organisations/list'
import OrganisationShow from './layouts/organisations/show'
import OrganisationEdit from './layouts/organisations/edit'
import OrganisationCreate from './layouts/organisations/create'
import MembershipList from './layouts/memberships/list'
import MembershipShow from './layouts/memberships/show'
import MembershipCreate from './layouts/memberships/create'
import MembershipEdit from './layouts/memberships/edit'
import BudgetCreate from './layouts/budgets/create'
import BudgetEdit from './layouts/budgets/edit'
import BudgetList from './layouts/budgets/list'
import ExpenseEdit from './layouts/expenses/edit'
import ExpenseList from './layouts/expenses/list'
import ExpenseCreate from './layouts/expenses/create'
import PaymentOrganisationList from './layouts/paymentOrganisations/list'
import PaymentOrganisationShow from './layouts/paymentOrganisations/show'
import PaymentOrganisationEdit from './layouts/paymentOrganisations/edit'
import UserList from './layouts/users/list'
import UserShow from './layouts/users/show'
import UserEdit from './layouts/users/edit'
import UserCreate from './layouts/users/create'
import sessionSaga from './sagas/sessionSaga'
import { createMuiTheme } from '@material-ui/core'
import UMLayout from './components/UMLayout'
import UMLoading from './components/UMLoading'
import UMLogin from './components/UMLogin'
import VirtualCardsList from './layouts/virtualCards/list'
import VirtualCardsShow from './layouts/virtualCards/show'
import VirtualCardsEdit from './layouts/virtualCards/edit'
import VirtualCardsCreate from './layouts/virtualCards/create'
import SuperDataProvider from './api/SuperDataProvider'
import ShareholdersList from './layouts/shareholders/list'
import ShareholdersShow from './layouts/shareholders/show'
import ShareholdersCreate from './layouts/shareholders/create'
import ShareholdersEdit from './layouts/shareholders/edit'
import EmployeesList from './layouts/employees/list'
import EmployeesShow from './layouts/employees/show'
import EmployeesCreate from './layouts/employees/create'
import EmployeesEdit from './layouts/employees/edit'
import MccRestrictionGroupList from './layouts/mccRestrictionGroups/list'
import MccRestrictionGroupCreate from './layouts/mccRestrictionGroups/create'
import MccRestrictionGroupShow from './layouts/mccRestrictionGroups/show'
import MccRestrictionGroupEdit from './layouts/mccRestrictionGroups/edit'
import MerchantIdRestrictionGroupList from './layouts/merchantIdRestrictionGroups/list'
import MerchantIdRestrictionGroupCreate from './layouts/merchantIdRestrictionGroups/create'
import MerchantIdRestrictionGroupShow from './layouts/merchantIdRestrictionGroups/show'
import MerchantIdRestrictionGroupEdit from './layouts/merchantIdRestrictionGroups/edit'
import MerchantList from './layouts/merchants/list'
import MerchantEdit from './layouts/merchants/edit'
import CardTransactionList from './layouts/cardTransactions/list'
import CardTransactionShow from './layouts/cardTransactions/show'

const umTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#ff5240',
      contrastText: '#fff',
    },
    secondary: {
      main: '#ff5240',
      contrastText: '#fff',
    },
  },
})

class App extends Component {
  state = {
    dataProvider: null,
  }

  componentDidMount() {
    SuperDataProvider.init(AuthProvider).then(dataProvider => this.setState({ dataProvider }))
  }

  render() {
    const { dataProvider } = this.state

    if (!dataProvider) {
      return null
    }

    return (
      <Admin
        appLayout={UMLayout}
        loginPage={() => <UMLogin theme={umTheme} />}
        dashboard={UMLoading}
        dataProvider={dataProvider}
        authProvider={AuthProvider}
        customSagas={[sessionSaga]}
        theme={umTheme}
      >
        {role => {
          return [
            <Resource
              name="organisations"
              icon={DomainIcon}
              list={OrganisationList}
              show={OrganisationShow}
              edit={OrganisationEdit}
              create={OrganisationCreate}
            />,
            <Resource name="me" />,
            <Resource name="users" list={UserList} show={UserShow} edit={UserEdit} create={UserCreate} />,
            <Resource
              name="memberships"
              show={MembershipShow}
              list={MembershipList}
              create={MembershipCreate}
              edit={MembershipEdit}
            />,
            <Resource name="budgets" create={BudgetCreate} list={BudgetList} edit={BudgetEdit} />,
            <Resource name="expenses" edit={ExpenseEdit} list={ExpenseList} create={ExpenseCreate} />,
            <Resource name="expensesbis" />,
            <Resource name="expenseCategories" />,
            <Resource name="partners" />,
            <Resource
              name="mccRestrictionGroups"
              list={MerchantIdRestrictionGroupList}
              create={MccRestrictionGroupCreate}
              show={MccRestrictionGroupShow}
              edit={MccRestrictionGroupEdit}
            />,
            <Resource
              name="merchantIdRestrictionGroups"
              options={{ label: 'MID restrictions' }}
              list={MccRestrictionGroupList}
              create={MerchantIdRestrictionGroupCreate}
              show={MerchantIdRestrictionGroupShow}
              edit={MerchantIdRestrictionGroupEdit}
            />,
            <Resource
              name="virtualCards"
              options={{ label: 'Virtual Cards' }}
              list={VirtualCardsList}
              create={VirtualCardsCreate}
              show={VirtualCardsShow}
              edit={VirtualCardsEdit}
            />,
            <Resource
              name="paymentOrganisations"
              options={{ label: 'KYC - Organisations' }}
              list={PaymentOrganisationList}
              show={PaymentOrganisationShow}
              edit={PaymentOrganisationEdit}
            />,
            <Resource
              name="shareholders"
              options={{ label: 'KYC - Shareholders' }}
              list={ShareholdersList}
              show={ShareholdersShow}
              create={ShareholdersCreate}
              edit={ShareholdersEdit}
            />,
            <Resource
              name="employees"
              options={{ label: 'KYC - Employees' }}
              list={EmployeesList}
              show={EmployeesShow}
              create={EmployeesCreate}
              edit={EmployeesEdit}
            />,
            <Resource name="merchants" options={{ label: 'Merchants' }} list={MerchantList} edit={MerchantEdit} />,
            <Resource name="expenseTags" />,
            <Resource
              name="cardTransactions"
              options={{ label: 'Card Transactions' }}
              list={CardTransactionList}
              show={CardTransactionShow}
            />,
          ]
        }}
      </Admin>
    )
  }
}

export default App
