import React from 'react'
import { SimpleShowLayout, TextField, NumberField, Button } from 'react-admin'
import { Link } from 'react-router-dom'
import get from 'lodash/get'
import { withStyles, Card } from '@material-ui/core'
import UMResourceLoader from '../../components/UMResourceLoader'
import UMChartField from '../../components/UMChartField'
import UMPriceField from '../../components/UMPriceField'
import organisations from '../../api/resources/organisations'
import commonStyles from '../common/styles'

const OrganisationInfoShowLayout = props => (
  <SimpleShowLayout {...props}>
    <TextField source="name" variant="title" addLabel={false} gutterBottom />
    <NumberField source="summary.employeeCount" label="Employees" />
    <UMChartField label="Status" usedKey="summary.usedBudget" totalKey="summary.totalBudget" />
    <UMPriceField source="summary.totalBudget" label="Total budget" format={v => (v ? v / 100 : 0)} />
    <UMPriceField source="summary.usedBudget" label="Used budget" format={v => (v ? v / 100 : 0)} />
    <UMPriceField source="summary.remainingBudget" label="Remaining budget" format={v => (v ? v / 100 : 0)} />
    <UMPriceField source="summary.professionalUsedBudget" label="Business expenses" format={v => (v ? v / 100 : 0)} />
    <EditOrganisationButton record={props.record} />
    <TextField source="userReference" label="Treezor ID" default="N/A" />
    <UMPriceField source="walletAuthorizedBalance" label="Wallet Amount" format={v => (v ? v / 100 : 0)} />
    <GoToKycOrganisationButton record={props.record} />
  </SimpleShowLayout>
)

const EditOrganisationButton = ({ record }) => {
  if (!record) {
    return null
  }
  return (
    <Button
      component={Link}
      to={{
        pathname: `/organisations/${record.id}`,
      }}
      label="Edit Organisation"
    ></Button>
  )
}

const GoToKycOrganisationButton = ({ record }) => {
  if (!record) {
    return null
  }
  return (
    <Button
      component={Link}
      to={{
        pathname: `/paymentOrganisations/${record.paymentOrganisationId}/show`,
      }}
      label="KYC-Organisation"
    ></Button>
  )
}

export default withStyles(commonStyles.show)(({ permissions, basePath, forceRecord, resource, version, classes }) => {
  const showProps = {
    basePath,
    record: forceRecord,
    resource,
    version,
    permissions,
    addLabel: true,
  }

  const organisationId = get(showProps.record, 'id')
  return (
    <Card className={classes.aside}>
      <UMResourceLoader resourceName={organisations.getName()} recordId={organisationId}>
        {data => {
          return <OrganisationInfoShowLayout {...showProps} record={data} />
        }}
      </UMResourceLoader>
    </Card>
  )
})
