import React from 'react'
import { List, TextField, ReferenceField, ChipField, downloadCSV, ExportButton, Link } from 'react-admin'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import { parse } from 'query-string'
import get from 'lodash/get'
import commonStyles from '../common/styles'
import { UMPagination, UMWrappedDatagrid } from '../../components/UMDatagrid'
import UMDateField from '../../components/UMDateField'
import UMPriceField from '../../components/UMPriceField'
import UMBreadcrumbs from '../../components/UMBreadcrumbs'
import OrganisationShow from '../organisations/show'
import organisations from '../../api/resources/organisations'
import UMFullNameField from '../../components/UMFullNameField'
import { unparse as convertToCSV } from 'papaparse/papaparse.min'
import ProToBusinessField from './ProToBusinessField'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import { stringify } from 'query-string'
import { getTodayDate } from '../../utils/dates'
const PARTNER_ID = 'f0655bfa-84e5-4f45-a900-f203c6f73fd1'
const RefundButton = props =>
  props.record.amount >= 0 ? (
    <Button
      onClick={e => {
        e.stopPropagation()
      }}
      component={Link}
      to={{
        pathname: '/expenses/create',
        state: {
          record: {
            ...props.record,
            title: 'Refund - ' + props.record.title,
            amount: props.record.amount * -1,
            employerAmount: props.record.employerAmount * -1,
            expenseOn: getTodayDate(),
            partnerReference: null,
            partnerId: PARTNER_ID,
            isRefund: true,
          },
        },
        search:
          '?' +
          stringify({
            filter: JSON.stringify({ budgetId: props.record.budget.id, title: 'Refund expense', refund: true }),
          }),
      }}
    >
      Refund
    </Button>
  ) : (
    <div></div>
  )

const exporter = records => {
  const data = records.map(record => {
    return {
      ...record,
      firstname: record.budget.membership.user.firstName,
      lastname: record.budget.membership.user.lastName,
      amount: record.amount / 100,
      employerAmount: record.employerAmount / 100,
      category: record.category.name,
      partner: record.partner.name,
      budgetStartOn: record.startOn,
      budgetEndOn: record.endOn,
      tag: record.tag ? record.tag.name : null,
    }
  })
  const csv = convertToCSV({
    data,
    fields: [
      'firstname',
      'lastname',
      'title',
      'type',
      'category',
      'partner',
      'amount',
      'employerAmount',
      'budgetStartOn',
      'budgetEndOn',
      'expenseOn',
      'justification',
      'providerName',
      'paymentSource',
      'validationStatus',
      'validationComment',
      'tag',
    ],
  })
  downloadCSV(csv, 'expenses')
}

const ExpenseActions = ({
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  budgetId,
}) => (
  <Toolbar>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
    <Button
      component={Link}
      variant="outlined"
      color="primary"
      to={{
        pathname: '/expenses/create',
        state: {
          record: {
            title: 'Swapfiets: ',
            validationStatus: 'APPROVED',
            expenseOn: getTodayDate(),
            partnerReference: null,
            partnerId: PARTNER_ID,
            budgetId: budgetId,
          },
        },
        search:
          '?' +
          stringify({
            filter: JSON.stringify({ budgetId: budgetId, title: 'Add swapfiets', newExpense: true, refund: false }),
          }),
      }}
    >
      Add Swapfiets
    </Button>
    <Button
      component={Link}
      variant="outlined"
      color="primary"
      to={{
        pathname: '/expenses/create',
        state: {
          record: {
            expenseOn: getTodayDate(),
            partnerReference: null,
            partnerId: PARTNER_ID,
            budgetId: budgetId,
            isRefund: true,
          },
        },
        search:
          '?' +
          stringify({
            filter: JSON.stringify({ budgetId: budgetId, title: 'Refund expense', newExpense: true, refund: true }),
          }),
      }}
    >
      General refund
    </Button>
  </Toolbar>
)

const ExpenseList = withStyles(commonStyles.list)(
  ({ classes, parentRecord, record, organisationId, budget, dispatch, permissions, ...props }) => {
    if (organisationId)
      return (
        <List
          className={classes.root}
          bulkActions={false}
          actions={<ExpenseActions />}
          sort={{ field: 'expenseOn', order: 'DESC' }}
          pagination={<UMPagination />}
          perPage={25}
          filter={{ organisationId: organisationId }}
          exporter={exporter}
          title={<UMBreadcrumbs resourceName={organisations.getName()} forceRecord={parentRecord} />}
          aside={<OrganisationShow permissions={props.permissions} classes={classes} forceRecord={parentRecord} />}
          {...props}
        >
          <UMWrappedDatagrid rowClick="edit" emptyText="No expenses">
            <TextField source="title" />
            <ReferenceField
              source="budget.membership.id"
              reference="memberships"
              label="First name"
              linkType={false}
              sortable={false}
            >
              <UMFullNameField firstNameKey="user.firstName" />
            </ReferenceField>
            <ReferenceField
              source="budget.membership.id"
              reference="memberships"
              label="Last name"
              linkType={false}
              sortable={false}
            >
              <UMFullNameField lastNameKey="user.lastName" />
            </ReferenceField>
            <TextField source="partner.name" label="Partner" />
            <TextField source="category.name" label="Category" />
            <ProToBusinessField source="type" sortable={false} />
            <UMPriceField source="amount" label="Total amount" format={v => (v ? v / 100 : 0)} />
            <UMPriceField source="employerAmount" label="Employer amount" format={v => (v ? v / 100 : 0)} />
            <UMDateField source="expenseOn" label="Date" />
            <ChipField source="tag.name" label="Tag" />
            <TextField source="validationStatus" label="Status" />
            <TextField source="providerName" label="Provider" />
            <TextField source="paymentSource" label="Payment source" />
          </UMWrappedDatagrid>
        </List>
      )
    else
      return (
        <List
          className={classes.root}
          actions={<ExpenseActions budgetId={budget.id} />}
          bulkActions={false}
          sort={{ field: 'expenseOn', order: 'DESC' }}
          pagination={<UMPagination />}
          perPage={25}
          exporter={exporter}
          filter={{ organisationId: organisationId }}
          title={<UMBreadcrumbs resourceName={organisations.getName()} forceRecord={parentRecord} />}
          {...props}
        >
          <UMWrappedDatagrid rowClick="edit" emptyText="No expenses">
            <TextField source="title" />
            <ReferenceField
              source="budget.membership.id"
              reference="memberships"
              label="First name"
              linkType={false}
              sortable={false}
            >
              <UMFullNameField firstNameKey="user.firstName" />
            </ReferenceField>
            <ReferenceField
              source="budget.membership.id"
              reference="memberships"
              label="Last name"
              linkType={false}
              sortable={false}
            >
              <UMFullNameField lastNameKey="user.lastName" />
            </ReferenceField>
            <TextField source="category.name" label="Category" />
            <ProToBusinessField source="type" sortable={false} />
            <UMPriceField source="amount" label="Total amount" format={v => (v ? v / 100 : 0)} />
            <UMPriceField source="employerAmount" label="Employer amount" format={v => (v ? v / 100 : 0)} />
            <UMDateField source="expenseOn" label="Date" />
            <ChipField source="tag.name" label="Tag" />
            <TextField source="validationStatus" label="Status" />
            <TextField source="providerName" label="Provider" />
            <TextField source="paymentSource" label="Payment source" />
            <RefundButton {...props} />
          </UMWrappedDatagrid>
        </List>
      )
  },
)

export default connect((state, ownProps) => {
  const query = get(ownProps, 'location.search')
  const params = parse(query)
  const filterStr = params.filter || '{}'
  const filter = JSON.parse(filterStr)
  const { organisationId, budgetId } = filter
  const parentRecord = organisations.getRecordById(state, organisationId) || { id: organisationId }
  const record = { organisation: parentRecord }
  const budget = { id: budgetId }
  return {
    record,
    parentRecord,
    organisationId,
    budget,
  }
})(ExpenseList)
