import React from 'react'
import { Edit } from 'react-admin'
import { connect } from 'react-redux'
import UMBreadcrumbs from '../../components/UMBreadcrumbs'
import organisations from '../../api/resources/organisations'
import OrganisationForm from './form'

const OrganisationEdit = ({ permissions, dispatch, ...props }) => {
  const formProps = { permissions }
  return (
    <Edit
      title={<UMBreadcrumbs resourceName={organisations.getName()} currentLabel="Edit organisation" />}
      undoable={false}
      {...props}
    >
      <OrganisationForm permissions={permissions} {...formProps} />
    </Edit>
  )
}

export default connect()(OrganisationEdit)
