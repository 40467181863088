import React, { Component } from 'react'
import { UPDATE } from 'react-admin'
import { Card, CardContent, Typography, withStyles, createStyles, Icon, IconButton } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

const styles = theme =>
  createStyles({
    rowCardText: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    rowButton: {
      display: 'flex',
    },
    text: {
      //alignSelf: 'center',
      marginLeft: '2%',
    },
    grayCard: {
      opacity: '0.2',
      filter: 'alpha(opacity=20)',
      borderRadius: '15px',
      width: '227px',
      height: '144px',
    },
    cardImage: {
      borderRadius: '15px',
      width: '227px',
      height: '144px',
    },
    titleText: {
      display: 'flex',
    },
  })

class CardPanel extends Component {
  state = {
    card: this.props.card,
    status: this.props.card.status,
    dataProvider: this.props.dataProvider,
  }

  AlertDialog = () => {
    const [open, setOpen] = React.useState(false)

    const handleClickOpen = () => {
      setOpen(true)
    }

    const handleClose = () => {
      setOpen(false)
    }

    const handleBlockCard = async e => {
      this.handleClick()
      handleClose()
    }

    return (
      <div>
        <IconButton
          style={{ border: '2px solid #252321', borderRadius: '15px', marginTop: '5%', marginLeft: '6%' }}
          onClick={handleClickOpen}
        >
          <Icon>
            <img style={{ height: '100%' }} src="/images/icons/Lock.png" alt="Block card button" />
          </Icon>
        </IconButton>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Are you sure you want to block your card?'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <b>It could be unblocked only by your fleet manager!</b>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              NO
            </Button>
            <Button onClick={handleBlockCard} color="primary" autoFocus>
              YES
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
  handleClick = async e => {
    const { dataProvider } = this.state
    let card = await dataProvider(UPDATE, 'virtualCards', {
      id: this.state.card.id,
      data: { id: this.state.card.id, status: 'LOCK_CARD' },
    })
    if (card.data.status === 'LOCK') {
      this.setState({
        status: 'LOCK',
      })
    }
  }

  render() {
    const { classes } = this.props
    const { status } = this.state
    switch (status) {
      case 'UNLOCK':
        return (
          <Card>
            <CardContent className={classes.cardContent}>
              <div className={classes.titleText}>
                <h3>Your virtual payment card</h3>
              </div>
              <div className={classes.rowCardText}>
                <div className={classes.cardAndButton}>
                  <img src={this.state.card.image} className={classes.cardImage} alt="virtual card" />
                  <this.AlertDialog />
                  <p style={{ marginTop: '5px' }}>block card</p>
                </div>
                <div className={classes.text}>
                  <Typography
                    gutterBottom
                    style={{
                      fontSize: '14px',
                      padding: '0px',
                      marginBottom: '0px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Icon style={{ paddingRight: '3px' }}>
                      <img style={{ height: '100%' }} src="/images/icons/Info Copy 1.png" alt="Info icon" />
                    </Icon>
                    <strong>About your card</strong>
                  </Typography>
                  <p style={{ fontSize: '14px', padding: '10px', margin: '0' }}>
                    You can use it to buy tickets and to pay for other mobility services on websites and in mobile
                    applications outside of the Skipr app. It works just like a regular payment card: simply add the
                    card number, the expiration date and the CVV code.
                    <br /> <br />
                    But remember! The card is only meant for mobility services. Every expense will be checked by your
                    fleet manager. Be sure to exclusively pay for mobility services you’re eligible for.
                  </p>
                </div>
              </div>
            </CardContent>
          </Card>
        )
      case 'LOCK':
        return (
          <Card>
            <CardContent className={classes.cardContent}>
              <div className={classes.titleText}>
                <h3>Your virtual payment card</h3>
              </div>
              <div className={classes.rowCardText}>
                <div className={classes.cardAndButton}>
                  <img src={this.state.card.image} className={classes.grayCard} alt="virtual card" />
                  <div style={{ marginTop: '10px' }}>
                    <strong>Your card is blocked</strong>
                    <p style={{ fontSize: '14px' }}>To unblock it, you need to contact your fleet manager.</p>
                  </div>
                </div>
                <div className={classes.text}>
                  <Typography
                    gutterBottom
                    style={{
                      fontSize: '14px',
                      padding: '0px',
                      marginBottom: '0px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Icon style={{ paddingRight: '3px' }}>
                      <img style={{ height: '100%' }} src="/images/icons/Info Copy 1.png" alt="Info icon" />
                    </Icon>
                    <strong>About your card</strong>
                  </Typography>
                  <p style={{ fontSize: '14px', padding: '10px', margin: '0' }}>
                    You can use it to buy tickets and to pay for other mobility services on websites and in mobile
                    applications outside of the Skipr app. It works just like a regular payment card: simply add the
                    card number, the expiration date and the CVV code.
                    <br /> <br />
                    You’ll find more information on how to use your virtual payment card here:
                    <br /> <br />
                    But remember! The card is only meant for mobility services. Every expense will be checked by your
                    fleet manager. Be sure to exclusively pay for mobility services you’re eligible for.
                  </p>
                </div>
              </div>
            </CardContent>
          </Card>
        )
      default:
        return null //no Card found
    }
  }
}

export default withStyles(styles)(CardPanel)
