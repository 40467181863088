import get from 'lodash/get'
import { stringify } from 'query-string'
import { formatDate, parseDate, parseDateAsDateTime } from '../../utils/dates'
import expenses from './expenses'

const fieldsPartial = `
  id
  total
  used
  remaining
  professionalUsed
  startOn
  endOn
  status
  type{
    code
  }
  membership {
    id
    organisation {
      id
      name
    }
  }
  carriedOver
  carryOver
`

const filterTypes = {
  ids: '[ID]',
  membershipId: 'ID',
}

const getMutationInputPair = (key, data) => {
  switch (key) {
    case 'id':
    case 'remaining':
    case 'used':
    case 'membership':
    case 'professionalUsed':
    case 'carriedOver':
      return null
    case 'startOn':
      return [key, parseDate(data[key])]
    case 'endOn':
      // Adding 1 day to send an exclusive date (where in the UI we rather play with an inclusive one
      return [key, parseDate(data[key])]
    case 'type':
      return ['budgetType', data[key]['code']]
    default:
      return [key, data[key]]
  }
}

const getParentResource = () => ({
  name: 'memberships',
  idKey: 'membership.id',
})

const getName = () => 'budgets'

const getLabel = () => 'Budgets'

const getRecordById = (state, id) => get(state, `admin.resources.budgets.data.${id}`, null)

const getShowPathForId = id => expenses.getListPathForParentId(id)

const getListPathForParentId = membershipId =>
  '/' + getName() + '?' + stringify({ filter: JSON.stringify({ membershipId }) })

const getLabelForRecord = record => {
  if (!record) {
    return ''
  }
  return `${record.type.code} budget from ${formatDate(record.startOn)} to ${formatDate(record.endOn)}`
}

const buildParseCollectionQueryResponse = collectionKey => response => {
  const data = response.data[collectionKey]

  let items = get(data, 'items', []).map(item => ({
    ...item,
    endOn: parseDateAsDateTime(item['endOn']),
  }))

  return { data: items, total: get(data, 'count', 0) }
}

const buildParseItemQueryResponse = itemKey => response => {
  const data = response.data[itemKey]
  return { data: { ...data, endOn: parseDateAsDateTime(data['endOn']) } }
}

export default {
  fieldsPartial,
  getMutationInputPair,
  getParentResource,
  getName,
  getLabel,
  getRecordById,
  getShowPathForId,
  getListPathForParentId,
  getLabelForRecord,
  filterTypes,
  buildParseCollectionQueryResponse,
  buildParseItemQueryResponse,
}
