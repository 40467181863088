import get from 'lodash/get'

let fieldsPartial = `
    cardReference
    userReference
    membershipReference
    status
    limitPaymentYear
    limitPaymentMonth
    limitPaymentWeek
    limitPaymentDay
    limitPaymentAll
    isLive
    isPhysical
    updatedOn
    createdOn
    id
    mccRestrictionGroup {
      id
      name
    }
    merchantIdRestrictionGroup {
      id
      name
    }
    employee {
      lastName
      firstName
      email
      phoneNumber
      updatedOn
      paymentOrganisation{
        name
      }
      organisationReference
    }
    pinTryExceeds
`

const fieldsPartialWithoutimage = `
    cardReference
    userReference
    membershipReference
    status
    isLive
    updatedOn
    createdOn
    id
    mccRestrictionGroup {
      id
      name
    }
    merchantIdRestrictionGroup {
      id
      name
    }
    pin
`
const fieldsWithImage = `
cardReference
userReference
membershipReference
status
limitPaymentYear
limitPaymentMonth
limitPaymentWeek
limitPaymentDay
limitPaymentAll
isLive
isPhysical
image
updatedOn
createdOn
id
mccRestrictionGroup {
  id
  name
}
`

const filterTypes = {
  ids: '[ID]',
}

const buildQuery = (fetchType, resourceName, params) => {
  return null
}

const getMutationInputPair = (key, data) => {
  console.log('data:', data)
  switch (key) {
    case 'id':
    case 'createdOn':
    case 'updatedOn':
    case 'cardReference':
    case 'employee':
    case 'pin':
    case 'pinTryExceeds':
      return null
    case 'membership':
      return [key + 'Reference', data[key]['id']]
    case 'mccRestrictionGroup':
      return [key + 'Id', data[key + 'id']]
    case 'merchantIdRestrictionGroup':
      return [key + 'Id', data[key + 'id']]
    case 'currentPin':
    case 'newPin':
    case 'confirmPin':
      return [key, data[key].toString()]
    case 'pinCode':
      return ['pin', data[key].toString()]
    default:
      return [key, data[key]]
  }
}

const getParentResource = role => ({
  name: 'organisations',
  idKey: 'employee.organisationReference',
})

const getName = () => 'virtualCards'

const getLabel = () => 'Cards Management'

const getRecordById = (state, id) => get(state, `admin.resources.virtualCards.data.${id}`, null)

const getShowPathForId = id => '/' + getName() + '/' + id + '/show'

const getLabelForRecord = record => {
  if (!record) {
    return ''
  }
  return `${record.employee.firstName} ${record.employee.lastName}`
}

export default {
  fieldsPartial,
  buildQuery,
  getParentResource,
  getName,
  getLabel,
  getRecordById,
  getShowPathForId,
  getLabelForRecord,
  filterTypes,
  getMutationInputPair,
  fieldsPartialWithoutimage,
  fieldsWithImage,
}
