import React from 'react'
import { Edit, SimpleForm, FileField, FileInput, SelectInput } from 'react-admin'
import UMBreadcrumbs from '../../components/UMBreadcrumbs'
import paymentOrganisations from '../../api/resources/paymentOrganisations'

const choicesResidenceDocumentTypes = [{ key: '19', label: 'A company registration official paper' }]
const choicesBCEDocumentTypes = [{ key: '19', label: 'BCE' }]

const PaymentOrganisationEdit = ({ permissions, dispatch, ...props }) => {
  return (
    <Edit
      title={<UMBreadcrumbs resourceName={paymentOrganisations.getName()} currentLabel="Edit payment organisation" />}
      undoable={false}
      {...props}
    >
      <SimpleForm {...props}>
        <FileInput source="identityDocument.fileContentBase64" label="File" multiple="true">
          <FileField source="src" title="title" />
        </FileInput>
        <SelectInput
          source="identityDocument.documentTypeReference"
          label="Document Type"
          choices={choicesResidenceDocumentTypes}
          optionText="label"
          optionValue="key"
        />
        <FileInput source="bceDocument.fileContentBase64" label="File">
          <FileField source="src" title="title" />
        </FileInput>
        <SelectInput
          source="bceDocument.documentTypeReference"
          label="Document Type"
          choices={choicesBCEDocumentTypes}
          optionText="label"
          optionValue="key"
        />
      </SimpleForm>
    </Edit>
  )
}

export default PaymentOrganisationEdit
