import React from 'react'
import { Edit, SimpleForm, TextInput, DateInput, LongTextInput, SelectInput } from 'react-admin'
import UMBreadcrumbs from '../../components/UMBreadcrumbs'
import merchantIdRestrictionGroups from '../../api/resources/merchantIdRestrictionGroups'

const choicesStatus = [
  { text: 'Pending', value: 'PENDING' },
  { text: 'Canceled', value: 'CANCELED' },
  { text: 'Validated', value: 'VALIDATED' },
]

const MerchantIdRestrictionGroupEdit = ({ permissions, dispatch, ...props }) => {
  return (
    <Edit
      title={
        <UMBreadcrumbs resourceName={merchantIdRestrictionGroups.getName()} currentLabel="Edit mcc restriction group" />
      }
      undoable={false}
      {...props}
    >
      <SimpleForm {...props}>
        <TextInput source="name" />
        <DateInput source="startDate" />
        <SelectInput source="status" choices={choicesStatus} optionText="text" optionValue="value" />
        <LongTextInput source="merchantsList" />
      </SimpleForm>
    </Edit>
  )
}

export default MerchantIdRestrictionGroupEdit
