import React from 'react'
import {
  SimpleForm,
  DateInput,
  NumberInput,
  Toolbar,
  SaveButton,
  required,
  Button,
  number,
  SelectInput,
  TextField,
  BooleanInput,
} from 'react-admin'
import { InputAdornment, withStyles } from '@material-ui/core'
import get from 'lodash.get'
import { CURRENCY_SYMBOL_DEFAULT } from '../../config/locale'
import { isDateValid } from '../../utils/dates'
import budgets from '../../api/resources/budgets'

const CancelButton = ({ basePath = '', label = 'ra.action.cancel', icon, record, ...rest }) => {
  const goBack = () => window.history.go(-1)
  return (
    <Button style={{ marginLeft: '0.5em' }} onClick={goBack} label={label}>
      {icon}
    </Button>
  )
}

const BudgetToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
    <CancelButton />
  </Toolbar>
)

const validateStartDate = [required()]
const validateEndDate = [required()]
const validateAmount = [required(), number()]

const validate = values => {
  const errors = {}
  if (values.amount <= 0) {
    errors.amount = ['Must be greater than zero']
  }
  if (!values.startOn) {
    errors.startOn = ['Required']
  } else if (!isDateValid(values.startOn)) {
    errors.startOn = ['Date required']
  }
  if (!values.endOn) {
    errors.endOn = ['Required']
  } else if (!isDateValid(values.endOn)) {
    errors.endOn = ['Date required']
  }
  if (new Date(values.endOn) < new Date(values.startOn)) {
    errors.endOn = ['End date cannot be earlier than start date']
  }
  return errors
}

const styles = {
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
  fileInput: {
    '& .previews': {
      marginTop: '0.5rem',
      '& > div': {
        '& a': {
          color: 'inherit',
        },
      },
    },
  },
  hiddenField: {
    display: 'none',
  },
}

export const choicesBudgetStatus = [{ _key: 'VALIDATED', _label: 'VALIDATED' }, { _key: 'BLOCKED', _label: 'BLOCKED' }]
export const choicesBudgetTypes = [
  { _key: 'MAIN', _label: 'MAIN' },
  { _key: 'MOBILITY', _label: 'MOBILITY' },
  { _key: 'COMMUTE', _label: 'COMMUTE' },
  { _key: 'BONUS', _label: 'BONUS' },
  { _key: 'PRO', _label: 'PRO' },
  { _key: 'UNCATEGORIZED', _label: 'UNCATEGORIZED' },
  { _key: 'KM_ALLOWANCE', _label: 'KM_ALLOWANCE' },
]

export default withStyles(styles)(({ mode = 'edit', defaultPartner, permissions, classes, ...props }) => (
  <SimpleForm
    {...props}
    redirect={budgets.getListPathForParentId(
      get(props.record, 'membership.id', get(props.defaultValue, 'membership.id')),
    )}
    toolbar={<BudgetToolbar />}
    validate={validate}
  >
    <NumberInput
      source="total"
      label="Total amount for this budget"
      format={v => (v === undefined || v === null ? '' : v / 100)}
      parse={v => (v ? Math.round(v * 100) : v)}
      validate={validateAmount}
      options={{
        InputProps: {
          startAdornment: <InputAdornment position="start">{CURRENCY_SYMBOL_DEFAULT}</InputAdornment>,
        },
      }}
    />

    <DateInput source="startOn" label="Starting date" validate={validateStartDate} />
    <DateInput source="endOn" label="Ending date (inclusive)" validate={validateEndDate} />
    <SelectInput source="status" label="Status" choices={choicesBudgetStatus} optionText="_key" optionValue="_label" />
    <BooleanInput source="carryOver" label="Carry over" />
    <TextField source="carriedOver" label="Carry over amount" />
    <SelectInput
      source="type.code"
      label="Types"
      choices={choicesBudgetTypes}
      optionText="_key"
      optionValue="_label"
      disabled="true"
    />
  </SimpleForm>
))
