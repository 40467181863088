import { race, take, put, call, select } from 'redux-saga/effects'
import {
  crudGetOne,
  AUTH_GET_PERMISSIONS,
  REGISTER_RESOURCE,
  UNREGISTER_RESOURCE,
  CRUD_GET_ONE_SUCCESS,
  CRUD_GET_ONE_FAILURE,
} from 'react-admin'
import { replace, LOCATION_CHANGE } from 'react-router-redux'
import me from '../api/resources/me'
import authProvider, { AUTH_GET_CURRENT_USER_ID } from '../api/AuthProvider'
import { ROLE_EMPLOYEE, ROLE_FLEET_MGR } from '../config/roles'
import memberships from '../api/resources/memberships'
import organisations from '../api/resources/organisations'
import budgets from '../api/resources/budgets'

function* handleLocationChange(pathName) {
  let matches
  if (['/', '/me'].includes(pathName)) {
    // Root
    //const currentUserId = yield call(authProvider, AUTH_GET_CURRENT_USER_ID)
    //const currentUser = yield select(state => me.getRecordById(state, currentUserId))
    const role = yield call(authProvider, AUTH_GET_PERMISSIONS)
    if (role === ROLE_EMPLOYEE) {
      console.log('employe')
      // console.log("employee")
      // const currentMembershipId = get(currentUser, 'memberships[0].id')
      // const route = memberships.getShowPathForId(currentMembershipId)
      // console.log(route)
      //yield put(replace('/'))
    } else if (role === ROLE_FLEET_MGR) {
      console.log('fleet_mgr')
    } else {
      // const currentOrganisationId = get(currentUser, 'memberships[0].organisation.id')
      // const route = organisations.getShowPathForId(currentOrganisationId)
      //we want to be redirected to the organisations list
      yield put(replace('/organisations'))
    }
  } else if ((matches = /organisations\/(.*)\/show$/.exec(pathName))) {
    // Organisations
    console.log(organisations.getShowPathForId(matches[1]))
    const route = organisations.getShowPathForId(matches[1])
    yield put(replace(route))
  } else if ((matches = /memberships\/(.*)\/show$/.exec(pathName))) {
    // Memberships
    console.log('memberships', memberships.getShowPathForId(matches[1]))
    const route = memberships.getShowPathForId(matches[1])
    yield put(replace(route))
  } else if ((matches = /budgets\/(.*)\/show$/.exec(pathName))) {
    // Budgets
    const route = budgets.getShowPathForId(matches[1])
    yield put(replace(route))
  }
}

export default function* sessionSaga() {
  while (true) {
    // Wait for 'me' resource to be fully loaded
    yield take(action => action.type === REGISTER_RESOURCE && action.payload.name === 'me')
    // Load current profile
    const currentUserId = yield call(authProvider, AUTH_GET_CURRENT_USER_ID)
    yield put(crudGetOne(me.getName(), currentUserId, '/' + me.getName(), false))
    // Try again indefinitely in case of failure, as we need the profile in the app
    let failure
    do {
      ;({ failure } = yield race({
        success: take(action => action.type === CRUD_GET_ONE_SUCCESS && action.meta.resource === 'me'),
        failure: take(action => action.type === CRUD_GET_ONE_FAILURE && action.meta.resource === 'me'),
      }))
    } while (failure)
    // Handle redirection whenever location changes to / or /me
    while (true) {
      const pathName = yield select(state => state.router.location.pathname)
      yield call(handleLocationChange, pathName)
      // Break out of this loop when we log out
      const { unregister } = yield race({
        location: take(LOCATION_CHANGE),
        unregister: take(action => action.type === UNREGISTER_RESOURCE && action.payload === 'me'),
      })
      if (unregister) {
        break
      }
    }
  }
}
