import React from 'react'
import { SimpleForm, TextInput, SelectInput, ReferenceInput, DateInput, required } from 'react-admin'
import { choicesUserTitle, choicesSocialSecretaryName, choicesSocialSecretaryType } from '../../config/enums'
import CountrySelectorInput from '../../components/CountrySelectorInput'

const choicesMembershipRole = [{ _key: 'EMPLOYEE', label: 'EMPLOYEE' }, { _key: 'FLEET_MGR', label: 'FLEET_MGR' }]

//validation
const fieldRequired = [required()]
const validateForm = values => {
  const errors = {}
  if (values.phoneNumber) {
    if (values.phoneNumber.length < 12) errors.phoneNumber = ['must contains at least 12 caracters ']

    if (!values.phoneNumber.includes('+')) {
      let message = 'must contains a "+" '
      errors.phoneNumber = errors.phoneNumber ? errors.phoneNumber.concat(message) : [message]
    }
  }
  return errors
}

let curr = new Date()
let date = curr.toISOString().substr(0, 10)
export default ({ type = 'edit', defaultPartner, organisationId, permissions, ...props }) => {
  return (
    <SimpleForm {...props} redirect={'/'} validate={validateForm}>
      <ReferenceInput
        source="organisation.id"
        label="organisation"
        reference="organisations"
        defaultValue={params => {
          return organisationId
        }}
      >
        <SelectInput optionText="name" optionValue="id" allowEmpty={false} disabled />
      </ReferenceInput>
      <TextInput source="firstName" validate={fieldRequired} />
      <TextInput source="lastName" validate={fieldRequired} />
      <SelectInput
        source="title"
        choices={choicesUserTitle}
        optionText="_label"
        optionValue="_key"
        allowEmpty={false}
        validate={fieldRequired}
      />
      <TextInput source="email" validate={fieldRequired} />
      <TextInput source="phoneNumber" validate={fieldRequired} />
      <DateInput source="birthday" validate={fieldRequired} />
      <TextInput source="address.street" validate={fieldRequired} />
      <TextInput source="address.city" validate={fieldRequired} />
      <TextInput source="address.postalCode" validate={fieldRequired} />
      <CountrySelectorInput source="address.countryCode" defaultValue="BE" />
      <TextInput source="jobTitle" validate={fieldRequired} />
      <SelectInput
        source="role"
        choices={choicesMembershipRole}
        optionText="label"
        optionValue="_key"
        allowEmpty={false}
        defaultValue={'EMPLOYEE'}
        validate={fieldRequired}
      />
      <DateInput source="startOn" defaultValue={date} validate={fieldRequired} />
      <TextInput source="placeOfBirth" />
      <CountrySelectorInput source="nationality" />
      <CountrySelectorInput source="birthCountry" />
      <SelectInput
        source="socialSecretary.socialSecretaryName"
        choices={choicesSocialSecretaryName}
        optionText="label"
        optionValue="_key"
        allowEmpty={true}
        label="Social Secretary name"
      />
      <TextInput source="socialSecretary.socialSecretaryEmployeeReference" label="Social Secretary reference" />
      <SelectInput
        source="socialSecretary.socialSecretaryEmployeeType"
        choices={choicesSocialSecretaryType}
        optionText="label"
        optionValue="_key"
        allowEmpty={true}
        label="Social Secretary type"
      />
      <TextInput disabled source="integrationStatus" defaultValue="INTEGRATED" />
    </SimpleForm>
  )
}
