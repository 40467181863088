import get from 'lodash/get'
import employees from './employees'

const fieldsPartial = `
  id
  name
  vatNumber
  legalForm
  legalName
  email
  phoneNumber
  legalSector
  legalRegistrationDate
  isFreelancer
  summary {
    employeeCount
    totalBudget
    usedBudget
    remainingBudget
    professionalUsedBudget
    mainBudgetRemaining
  }
  address{
    street
    city
    countryCode
    postalCode
  }
  createdOn
  updatedOn
  expenseManagerId
  kmAllowance {
    enabled
    parameters{
      feePerKmCar
      carAllowanceType
      feePerKmBike
      bikeAllowanceType
    }
  }
  appExpenseApprovalLimit
  cardExpenseApprovalLimit
  socialSecretaryReference
  useMobilityBudget
  useCommuteBudget
  managerValidationThreshold
`

const filterTypes = {
  ids: '[ID]',
  q: 'String',
}

const getMutationInputPair = (key, data) => {
  console.log('dd', data)
  switch (key) {
    case 'id':
    case 'summary':
    case 'type':
    case 'createdOn':
    case 'updatedOn':
    case 'userReference':
    case 'paymentOrganisationId':
    case 'walletAuthorizedBalance':
      return null
    case 'socialSecretaryReference':
      if (data[key] === null || data[key] === '') {
        return [key, null]
      } else {
        return [key, data[key]]
      }
    case 'address':
      return [
        key,
        {
          street: data[key]['street'],
          postalCode: data[key]['postalCode'],
          countryCode: data[key]['countryCode'],
          city: data[key]['city'],
        },
      ]
    case 'legalRegistrationDate':
      return [key, data[key] + ' ' + new Date().toLocaleTimeString()]
    case 'email':
      if (!data[key].includes('+organisation')) {
        var values = data[key].split('@')
        return [key, `${values[0]}+organisation@${values[1]}`]
      }
      return [key, data[key]]
    case 'kmAllowance':
      if (data[key] === null) {
        return null
      }
      return [
        key,
        {
          enabled: data[key]['enabled'],
          carAllowanceType: data[key]['parameters']['carAllowanceType'],
          feePerKmCar: data[key]['parameters']['feePerKmCar'],
          bikeAllowanceType: data[key]['parameters']['bikeAllowanceType'],
          feePerKmBike: data[key]['parameters']['feePerKmBike'],
        },
      ]
    default:
      return [key, data[key]]
  }
}
const getParentResource = () => null

const getName = () => 'organisations'

const getLabel = () => 'Organisations'

const getRecordById = (state, id) => get(state, `admin.resources.organisations.data.${id}`, null)

const getShowPathForId = id => employees.getListPathForParentId(id)
const getLabelForRecord = record => {
  if (!record) {
    return ''
  }
  return record.name
}

const fieldsPartialForList = `
  id
  name
  vatNumber
  legalForm
  legalName
  email
  phoneNumber
  legalSector
  legalRegistrationDate
  isFreelancer
  createdOn
  updatedOn
  expenseManagerId
  appExpenseApprovalLimit
  cardExpenseApprovalLimit
  socialSecretaryReference
  useMobilityBudget
  useCommuteBudget
  managerValidationThreshold
`

export default {
  fieldsPartial,
  getParentResource,
  getName,
  getLabel,
  getRecordById,
  getShowPathForId,
  getLabelForRecord,
  filterTypes,
  getMutationInputPair,
  fieldsPartialForList,
}
