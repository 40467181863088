import React from 'react'
import { Create, SimpleForm, TextInput, DateInput } from 'react-admin'
import UMBreadcrumbs from '../../components/UMBreadcrumbs'
import mccRestrictionGroups from '../../api/resources/mccRestrictionGroups'

const MccRestrictionGroupCreate = ({ permissions, dispatch, ...props }) => {
  return (
    <Create
      title={
        <UMBreadcrumbs
          resourceName={mccRestrictionGroups.getName()}
          currentLabel="Create a new restriction based on mcc's"
        />
      }
      {...props}
    >
      <SimpleForm {...props}>
        <TextInput source="name" />
        <DateInput source="startDate" />
      </SimpleForm>
    </Create>
  )
}

export default MccRestrictionGroupCreate
