import React from 'react'
import { List, TextField, CardActions, CreateButton, ReferenceField, Filter, TextInput } from 'react-admin'
import UMDatagrid from '../../components/UMDatagrid'

const Actions = ({
  bulkActions,
  basePath,
  displayedFilters,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
}) => (
  <CardActions>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <CreateButton basePath={basePath} />
  </CardActions>
)

const ShareholderFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
)

export default props => (
  <List
    actions={<Actions />}
    bulkActions={false}
    perPage={10}
    {...props}
    filters={<ShareholderFilter />}
    sort={{ field: 'updatedOn', order: 'DESC' }}
  >
    <UMDatagrid isFullList rowClick="show">
      <TextField source="userReference" label="Treezor Id" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="identityDocument.status" label="Identity Check" />
      <TextField source="residenceDocument.status" label="Residence Check" />
      <TextField source="additionalDocument.status" label="Additional Check" />
      <ReferenceField
        label="Organisation"
        source="paymentOrganisationId"
        reference="paymentOrganisations"
        perPage={500}
        allowEmpty
      >
        <TextField source="name" />
      </ReferenceField>
    </UMDatagrid>
  </List>
)
