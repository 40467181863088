import get from 'lodash/get'
import { stringify } from 'query-string'

const fieldsPartial = `
    id
    userReference
    membershipReference
    employeeReference
    organisationReference
    paymentOrganisation{
      id
      name
    }
    lastName
    firstName
    email
    title
    phoneNumber
    kycLevel
    kycReview
    kycReviewComment
    status
    identityDocument{
      name
      documentTypeReference
      status
    }
    address{
      street
      city
      countryCode
      postalCode
    }
    nationality
    placeOfBirth
    birthCountry
    birthday
    virtualCards{
      id
    }
    createdOn
    updatedOn
    membershipReference
    integrationStatus
`

const filterTypes = {
  ids: '[ID]',
  organisationReference: 'ID',
  q: 'String',
}

const buildQuery = (fetchType, resourceName, params) => {
  return null
}

const getMutationInputPair = (key, data) => {
  switch (key) {
    case 'id':
    case 'createdOn':
    case 'updatedOn':
    case 'kycLevel':
    case 'kycReview':
    case 'kycReviewComment':
    case 'status':
    case 'employeeReference':
    case 'paymentOrganisation':
    case 'userReference':
    case 'membershipReference':
    case 'virtualCards':
    case 'organisation':
    case 'organisationReference':
    case 'accountStatus':
    case 'paymentProfiles':
      return null
    case 'organisationId':
      return ['organisationReference', data[key]]
    case 'address':
      return [
        key,
        {
          street: data[key]['street'],
          postalCode: data[key]['postalCode'],
          countryCode: data[key]['countryCode'],
          city: data[key]['city'],
        },
      ]
    case 'socialSecretary':
      if (!data[key]) {
        return [key, null]
      }
      var name = data[key]['socialSecretaryName']
      var employeeReference = data[key]['socialSecretaryEmployeeReference']
      var employeeType = data[key]['socialSecretaryEmployeeType']
      return name && name !== '' && employeeReference && employeeReference !== '' && employeeType && employeeType !== ''
        ? [
            key,
            {
              socialSecretaryName: name,
              socialSecretaryEmployeeReference: employeeReference,
              socialSecretaryEmployeeType: employeeType,
            },
          ]
        : [key, null]
    case 'identityDocument':
      if (data[key] && data[key]['documentTypeReference'] && data[key]['fileContentBase64']) {
        return [
          key,
          {
            documentTypeReference: data[key]['documentTypeReference'],
            fileContentBase64: data[key]['fileContentBase64'],
          },
        ]
      }
      break
    default:
      return [key, data[key]]
  }
}

const getParentResource = role => ({
  name: 'organisations',
  idKey: 'organisationReference',
})

const getName = () => 'employees'

const getLabel = () => 'Employess'

const getRecordById = (state, id) => get(state, `admin.resources.employees.data.${id}`, null)

const getShowPathForId = id => '/' + getName() + '/' + id + '/show'

const getListPathForParentId = organisationReference =>
  '/' + getName() + '?' + stringify({ filter: JSON.stringify({ organisationReference }) })

const getLabelForRecord = record => {
  if (!record) {
    return ''
  }
  return `${record.firstName} ${record.lastName}`
}

const fieldsPartialForShow = `
    id
    userReference
    membershipReference
    employeeReference
    organisationReference
    paymentOrganisation{
      id
      name
    }
    lastName
    firstName
    email
    title
    phoneNumber
    kycLevel
    kycReview
    kycReviewComment
    status
    identityDocument{
      name
      documentTypeReference
      status
    }
    address{
      street
      city
      countryCode
      postalCode
    }
    nationality
    placeOfBirth
    birthCountry
    birthday
    virtualCards{
      id
    }
    createdOn
    updatedOn
    membershipReference
    paymentProfiles
    accountStatus{
      servicesStatus
      documentsStatus
    }
    integrationStatus
`

export default {
  fieldsPartial,
  buildQuery,
  getParentResource,
  getName,
  getLabel,
  getRecordById,
  getShowPathForId,
  getListPathForParentId,
  getLabelForRecord,
  filterTypes,
  getMutationInputPair,
  fieldsPartialForShow,
}
