import React from 'react'
import { Show, TabbedShowLayout, Tab, TextField, DateField, EditButton, ReferenceField, SelectField } from 'react-admin'
import { withStyles, CardActions } from '@material-ui/core'
import commonStyles from '../common/styles'
import UMBreadcrumbs from '../../components/UMBreadcrumbs'
import POTransferBudgetButton from '../../components/POTransferBudgetButton'
import UpdatePaymentOrganisationButton from '../../components/POUpdatePaymentOrganisationButton'
import { choicesKycLevels, choicesKycReviews } from '../../config/enums'
import UMPriceField from '../../components/UMPriceField'
import { connect } from 'react-redux'
import get from 'lodash/get'
import paymentOrganisations from '../../api/resources/paymentOrganisations'

const ShowActions = ({ basePath, data, resource }) => (
  <CardActions>
    <EditButton basePath={basePath} record={data} label="Add Document" />
    <UpdatePaymentOrganisationButton basePath={basePath} record={data} label="Start KYC" status="START_KYC" />
    <UpdatePaymentOrganisationButton basePath={basePath} record={data} label="Cancel Org" status="CANCEL_ORG" />
    <UpdatePaymentOrganisationButton basePath={basePath} record={data} label="Cancel Wallet" status="CANCEL_WALLET" />
  </CardActions>
)

const PaymentOrganisationShow = withStyles(commonStyles.show)(({ classes, permissions, parentRecord, ...props }) => (
  <Show
    {...props}
    className={classes.root}
    title={<UMBreadcrumbs resourceName={paymentOrganisations.getName()} forceRecord={parentRecord} />}
    actions={<ShowActions classes={classes} />}
  >
    <TabbedShowLayout>
      <Tab label="References">
        <TextField source="userReference" label="Treezor Id" />
        <TextField source="id" label="Skipr Id" />
        <ReferenceField label="organisation" source="organisationReference" reference="organisations">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="walletReference" />
        <TextField source="walletIban" />
      </Tab>
      <Tab label="KYC">
        <SelectField source="kycReview" choices={choicesKycReviews} optionText="_text" optionValue="_value" />
        <SelectField source="kycLevel" choices={choicesKycLevels} optionText="_text" optionValue="_value" />
        <TextField source="kycReviewComment" label="Review Comment" />
      </Tab>
      <Tab label="Dates">
        <DateField label="Publication date" source="createdOn" />
      </Tab>
      <Tab label="Wallet">
        <UMPriceField label="Wallet balance" source="walletTotalBalance" format={v => (v ? v / 100 : 0)} />
        <POTransferBudgetButton label="Transfer budget" />
      </Tab>
    </TabbedShowLayout>
  </Show>
))

export default connect((state, ownProps) => {
  const paymentOrganisationId = get(ownProps, 'id')
  const parentRecord = paymentOrganisations.getRecordById(state, paymentOrganisationId)

  return {
    parentRecord,
  }
})(PaymentOrganisationShow)
