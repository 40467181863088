import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin'
import get from 'lodash/get'
import decodeJwt from 'jwt-decode'

export const AUTH_GET_CURRENT_USER_ID = 'AUTH_GET_CURRENT_USER_ID'

const query = async (path, params) => {
  const request = new Request(process.env.REACT_APP_API_URL_AUTH + path, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: new Headers({ 'Content-Type': 'application/json; charset=utf-8' }),
  })
  const response = await fetch(request)
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText)
  }
  return response.json()
}

const login = async (email, password) => {
  const { access: accessToken, refresh: refreshToken } = await query('/auth/token/', { email, password })
  const tokens = { accessToken, refreshToken }
  storeTokens(tokens)
  return tokens
}

let isTokenRefreshing = false
const refresh = async refreshToken => {
  isTokenRefreshing = true
  try {
    const { access: accessToken } = await query('/auth/token-refresh/', { refresh: refreshToken })
    const tokens = { accessToken }
    storeTokens(tokens)
    isTokenRefreshing = false
    return tokens
  } catch (error) {
    isTokenRefreshing = false
    throw new Error('Expired session. Please log in.')
  }
}

const storeTokens = ({ accessToken, refreshToken }) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken)
  }
  if (refreshToken) {
    localStorage.setItem('refreshToken', refreshToken)
  }
  return true
}

const clearTokens = () => {
  localStorage.removeItem('accessToken')
  localStorage.removeItem('refreshToken')
  return true
}

const getTokens = () => {
  return {
    accessToken: localStorage.getItem('accessToken'),
    refreshToken: localStorage.getItem('refreshToken'),
  }
}

const getTokensAndRefresh = () =>
  new Promise(async (resolve, reject) => {
    const { accessToken, refreshToken } = getTokens()
    if (!accessToken) {
      return reject(new Error('Session not found'))
    }
    let decodedAccessToken
    try {
      decodedAccessToken = decodeJwt(accessToken)
    } catch (error) {
      console.error(error)
      return reject(new Error('Invalid session'))
    }

    if (decodedAccessToken.exp * 1000 > Date.now()) {
      return resolve(getTokens())
    } else {
      // Access token is expired and needs refresh
      if (!isTokenRefreshing) {
        try {
          await refresh(refreshToken)
          resolve(getTokens())
        } catch (error) {
          console.error(error)
          return reject(error)
        }
      } else {
        let interval = setInterval(() => {
          if (!isTokenRefreshing) {
            clearInterval(interval)
            resolve(getTokens())
          }
        }, 100)
      }
    }
  })

const getRole = () => {
  const { accessToken } = getTokens()
  try {
    const { roles } = decodeJwt(accessToken)
    if (roles) {
      return roles[0]
    }
    throw new Error('Invalid session')
  } catch (error) {
    console.error(error)
    throw error
  }
}

const getUserId = () => {
  const { accessToken } = getTokens()
  try {
    const decodedAccessToken = decodeJwt(accessToken)
    return decodedAccessToken.sub
  } catch (error) {
    console.error(error)
  }
  return null
}

const parseError = ({ networkError, graphQLErrors, ...rest }) => {
  const message =
    get(graphQLErrors, '[0].message') ||
    get(networkError, 'result.errors[0].message') ||
    get(networkError, 'message') ||
    get(rest, 'message')
  const status =
    get(graphQLErrors, '[0].statusCode') ||
    get(networkError, 'statusCode') ||
    get(rest, 'statusCode') ||
    get(rest, 'status')
  return { message, status }
}

export default async (type, params) => {
  if (type === AUTH_LOGIN) {
    const { email, password } = params
    return login(email, password)
  }

  if (type === AUTH_LOGOUT) {
    return clearTokens()
  }

  if (type === AUTH_ERROR) {
    const { status } = parseError(params)
    if (status === 401 || status === 403) {
      clearTokens()
      throw new Error('Expired session. Please log in.')
    }
    return true
  }

  if (type === AUTH_CHECK) {
    const { accessToken } = await getTokensAndRefresh()
    return accessToken
  }

  if (type === AUTH_GET_PERMISSIONS) {
    return getRole()
  }

  // Custom API
  if (type === AUTH_GET_CURRENT_USER_ID) {
    return getUserId()
  }

  throw new Error('Unknown authentication method: ' + type)
}
