const fieldsPartial = `
  id
  name
  mimeType
  size
  url
`

export default {
  fieldsPartial,
}
