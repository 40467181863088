import React from 'react'
import { Edit } from 'react-admin'
import UMBreadcrumbs from '../../components/UMBreadcrumbs'
import users from '../../api/resources/users'
import UserForm from './form'

const UserEdit = ({ permissions, dispatch, ...props }) => {
  const formProps = { permissions }
  return (
    <Edit title={<UMBreadcrumbs resourceName={users.getName()} currentLabel="Edit user" />} undoable={false} {...props}>
      <UserForm {...formProps} />
    </Edit>
  )
}

export default UserEdit
