import React from 'react'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import compose from 'recompose/compose'
import { withTranslate, showNotification } from 'react-admin'
import { Button, CardActions, CircularProgress, TextField, withStyles, createStyles } from '@material-ui/core'

const styles = ({ spacing }) =>
  createStyles({
    form: {
      padding: '0 1em 1em 1em',
    },
    input: {
      marginTop: '1em',
    },
    button: {
      width: '100%',
      marginBottom: spacing.unit,
    },
    icon: {
      marginRight: spacing.unit,
    },
    link: {
      color: 'inherit',
      textDecoration: 'underline',
    },
  })

// see http://redux-form.com/6.4.3/examples/material-ui/
const renderInput = ({
  meta: { touched, autofilled, error } = { touched: false, autofilled: false, error: '' }, // eslint-disable-line react/prop-types
  input: { ...inputProps }, // eslint-disable-line react/prop-types
  ...props
}) => (
  <TextField
    error={!!(touched && error) && !autofilled}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
)

const query = async (path, params) => {
  const request = new Request(process.env.REACT_APP_API_URL_AUTH + path, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: new Headers({ 'Content-Type': 'application/json; charset=utf-8' }),
  })
  const response = await fetch(request)
  if (response.status < 200 || response.status >= 300) {
    return { error: true, detail: response.json() }
  }
  return { error: false, detail: response.json() }
}

const reset = async (data, dispatch, { redirectTo }) => {
  console.log('reset', data)
  const { error, detail } = await query('/auth/reset-password', {
    new_password: data['password'],
    reset_token: data['resetToken'],
  })
  if (!error) {
    dispatch(showNotification('Password reset, please login'))
    dispatch(push('/login'))
    return null //TODO: display success notification using dispatch?
  }
  dispatch(showNotification('Error while reseting your password', 'warning'))
  return detail //TODO: display error message to user
}

const required = value => (value || typeof value === 'string' ? undefined : 'Required')
const minLength = min => value => (value && value.length < min ? `Must be ${min} characters or more` : undefined)
const minLength8 = minLength(8)
const minLowerCase = value => (value && !/[a-z]/.test(value) ? 'Must have a least one lower case' : undefined)
const minUpperCase = value => (value && !/[A-Z]/.test(value) ? 'Must have a least one upper case' : undefined)
const minDigit = value => (value && !/[0-9]/.test(value) ? 'Must have a least one digit' : undefined)
const minSpecialChar = value =>
  value && !/^(?=.*[!@#$?%^&*])/.test(value) ? 'Must have a least one special character, e.g., ! @ # ?' : undefined

const ResetForm = ({ classes, isLoading, handleSubmit, translate }) => (
  <form onSubmit={handleSubmit(reset)}>
    <div className={classes.form}>
      <p>
        Please enter your new password twice.
        <br />
        Enter a password that is at least 8 characters long,
        <br />
        has both upper and lower case,
        <br />
        has at least one digit and a special character e.g., ! @ # ?"
      </p>
      <div className={classes.input}>
        <Field
          id="password"
          name="password"
          component={renderInput}
          label={translate('ra.auth.password')}
          type="password"
          disabled={isLoading}
          validate={[minLength8, minLowerCase, minUpperCase, minDigit, minSpecialChar, required]}
        />
      </div>
      <div className={classes.input}>
        <Field
          id="passwordRepeat"
          name="passwordRepeat"
          component={renderInput}
          label={translate('ra.auth.password')}
          type="password"
          disabled={isLoading}
          required
        />
      </div>
      <Field
        id="resetToken"
        name="resetToken"
        type="text"
        component={renderInput}
        props={{
          disabled: true, // like this
        }}
        style={{ display: 'none' }}
      />
    </div>
    <CardActions>
      <Button variant="raised" type="submit" color="primary" disabled={isLoading} className={classes.button}>
        {isLoading && <CircularProgress className={classes.icon} size={18} thickness={2} />}
        Validate
      </Button>
    </CardActions>
    <p>
      <center>
        <Link to={'./'}>Back to Login</Link>
      </center>
    </p>
  </form>
)

const mapStateToProps = state => ({
  isLoading: state.admin.loading > 0,
  location: state.location,
})

const enhance = compose(
  withStyles(styles),
  withRouter,
  withTranslate,
  connect(mapStateToProps),
  reduxForm({
    form: 'reset',
    validate: (values, props) => {
      const errors = { passwordRepeat: '', password: '' }
      if (!values.password) {
        errors.password = 'password is required'
      } else if (!values.passwordRepeat) {
        errors.passwordRepeat = 'password repetition is required'
      } else if (values.password !== values.passwordRepeat) {
        errors.passwordRepeat = 'repeat password is the same'
      }
      return errors
    },
  }),
)

const UMResetForm = enhance(ResetForm)

UMResetForm.propTypes = {
  redirectTo: PropTypes.string,
}

export default UMResetForm
