import React, { Component } from 'react'
import { GET_ONE } from 'react-admin'
import { createStyles, withStyles } from '@material-ui/core'
import SidePanel from './SidePanel'
import { GET_LIST } from 'ra-core'
import FiveRecentExpensesList from './FiveRecentExpensesList'
import RecentSignificantExpensesList from './RecentSignificantExpensesList'
import TopFiveEmployeesList from './TopFiveEmployeeList'

const styles = theme =>
  createStyles({
    mainContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    flexColumnContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '85%',
    },
    aside: {
      width: '240px',
      display: 'flex',
      marginRight: '1em',
    },
    RightListContainer: {
      marginBottom: '1em',
    },
    ButtonMenuTopList: {
      height: '50px',
      display: 'flex',
      flexDirection: 'row-reverse',
      verticalAlign: 'center',
    },
    ButtonContainer: {
      display: 'flex',
      fontSize: '20px',
      height: '50px',
      width: '20%',
      textAlign: 'center',
      verticalAlign: 'center',
    },
  })

class FleetMgrDashboard extends Component {
  state = {
    organisation: null,
    cardsCount: null,
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData() {
    this.fetchOrganisation()
    this.fetchCards()
  }

  async fetchOrganisation() {
    const { dataProvider } = this.props
    let { data: me } = await dataProvider(GET_ONE, 'me', {})
    this.setState({
      organisation: me.memberships[0].organisation,
    })
  }

  async fetchCards() {
    const { dataProvider } = this.props
    const { total: cardsCount } = await dataProvider(GET_LIST, 'virtualCards', { filter: this.state.organisation })
    this.setState({
      cardsCount: cardsCount,
    })
  }

  render() {
    if (this.state.organisation) {
      return (
        <main className={this.props.classes.mainContainer}>
          <div className={this.props.classes.aside}>
            <SidePanel
              basePath="/"
              {...this.props}
              forceRecord={this.state.record}
              organisationRecord={this.state.organisation}
              cardsCount={this.state.cardsCount}
              classes={this.props.classes}
            />
          </div>
          <div className={this.props.classes.flexColumnContainer}>
            <div className={this.props.classes.RightListContainer}>
              <TopFiveEmployeesList
                className={this.props.classes.memberships}
                location={this.props.location}
                match={this.props.match}
                basePath="/"
                resource="memberships"
                title={'SkiprForBusiness'}
                classes={this.props.classes}
                organisationRecord={this.state.organisation}
                parentRecord={this.state.record}
                forceRecord={this.state.record}
                record={this.state.organisation}
                {...this.props}
              />
            </div>
            <div className={this.props.classes.RightListContainer}>
              <FiveRecentExpensesList
                className={this.props.classes.expenses}
                location={this.props.location}
                match={this.props.match}
                basePath="/"
                resource="expenses"
                title={` `}
                record={this.state.record}
                organisationRecord={this.state.organisation}
                hasCreate={false}
                hasEdit={true}
                hasList={true}
                hasShow={false}
                classes={this.props.classes}
                {...this.props}
              />
            </div>
            <div className={this.props.classes.RightListContainer}>
              <RecentSignificantExpensesList
                className={this.props.classes.expenses}
                location={this.props.location}
                match={this.props.match}
                basePath="/"
                title={` `}
                resource="expensesbis"
                record={this.state.record}
                organisationRecord={this.state.organisation}
                hasCreate={false}
                hasEdit={true}
                hasList={true}
                hasShow={false}
                classes={this.props.classes}
                {...this.props}
              />
            </div>
          </div>
        </main>
      )
    }
    return null
  }
}

export default withStyles(styles)(FleetMgrDashboard)
