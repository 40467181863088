import React, { Component } from 'react'
import { connect } from 'react-redux'
import isEqual from 'lodash/isEqual'
import { GET_ONE, withDataProvider } from 'react-admin'
import { getResourceByName } from '../api/resources'

class Query extends Component {
  state = {
    data: null,
    total: null,
    loading: true,
    error: null,
  }

  callDataProvider = () => {
    const { dataProvider, type, resource, payload, options } = this.props
    dataProvider(type, resource, payload, options)
      .then(({ data, total }) => {
        if (this.hasMounted) {
          this.setState({
            data,
            total,
            loading: false,
          })
        }
      })
      .catch(error => {
        if (this.hasMounted) {
          this.setState({
            error,
            loading: false,
          })
        }
      })
  }

  componentDidMount = () => {
    this.hasMounted = true
    this.callDataProvider()
  }

  componentWillUnmount = () => {
    this.hasMounted = false
  }

  componentDidUpdate = prevProps => {
    if (
      prevProps.type !== this.props.type ||
      prevProps.resource !== this.props.resource ||
      !isEqual(prevProps.payload, this.props.payload) ||
      !isEqual(prevProps.options, this.props.options)
    ) {
      this.callDataProvider()
    }
  }

  render() {
    const { children } = this.props
    return children(this.state)
  }
}

const UMQuery = withDataProvider(Query)

const ResourceLoader = ({ record, resourceName, recordId, children }) => {
  // if (record) {
  //   return children(record)
  // }

  // if (!recordId) {
  //   return children(undefined)
  // }
  if (record) {
    return (
      <UMQuery type={GET_ONE} resource={resourceName} payload={{ id: recordId }}>
        {({ data }) => {
          if (data) {
            return children(data)
          }
          return children(undefined)
        }}
      </UMQuery>
    )
  } else {
    return children(undefined)
  }
}

const UMResourceLoader = connect((state, ownProps) => {
  let record
  const resource = getResourceByName(ownProps.resourceName)
  if (resource && ownProps.recordId != null) {
    record = resource.getRecordById(state, ownProps.recordId)
  }
  return {
    record,
  }
})(ResourceLoader)

export default UMResourceLoader
