import get from 'lodash/get'

const fieldsPartial = `
    id
    userReference
    paymentOrganisationId
    lastName
    firstName
    title
    email
    address{
      street
      city
      countryCode
      postalCode
    }
    phoneNumber
    birthday
    placeOfBirth
    nationality
    kycLevel
    kycReview
    kycReviewComment
    status
    isUsPerson
    identityDocument{
      name
      documentTypeReference
      status
    }
    residenceDocument{
      name
      documentTypeReference
      status
    }
    additionalDocument{
      name
      documentTypeReference
      status
    }
    taxResidence{
      taxPayerId
    }
    createdOn
    updatedOn
`

const filterTypes = {
  ids: '[ID]',
  q: 'String',
}

const buildQuery = (fetchType, resourceName, params) => {
  return null
}

const getMutationInputPair = (key, data) => {
  switch (key) {
    case 'id':
    case 'createdOn':
    case 'updatedOn':
    case 'kycLevel':
    case 'kycReview':
    case 'kycReviewComment':
    case 'status':
    case 'userReference':
    case 'organisation':
      return null
    case 'address':
      return [
        key,
        {
          street: data[key]['street'],
          postalCode: data[key]['postalCode'],
          countryCode: data[key]['countryCode'],
          city: data[key]['city'],
        },
      ]
    case 'identityDocument':
    case 'residenceDocument':
    case 'additionalDocument':
      if (data[key] && data[key]['documentTypeReference'] && data[key]['fileContentBase64']) {
        return [
          key,
          {
            documentTypeReference: data[key]['documentTypeReference'],
            fileContentBase64: data[key]['fileContentBase64'],
          },
        ]
      }
      break
    case 'taxResidence':
      return [
        key,
        {
          taxPayerId: data[key]['taxPayerId'],
        },
      ]
    case 'email':
      if (!data[key].includes('+shareholder')) {
        var values = data[key].split('@')
        return [key, `${values[0]}+shareholder@${values[1]}`]
      }
      return [key, data[key]]
    default:
      return [key, data[key]]
  }
}

const getParentResource = () => null

const getName = () => 'Shareholders'

const getLabel = () => 'KYC - Shareholders'

const getRecordById = (state, id) => get(state, `admin.resources.shareholders.data.${id}`, null)

const getShowPathForId = id => '/' + getName() + '/' + id + '/show'

const getLabelForRecord = record => {
  if (!record) {
    return ''
  }
  return record.name
}

const fieldsPartialForList = `
    id
    userReference
    paymentOrganisationId
    lastName
    firstName
    title
    email
    phoneNumber
    birthday
    placeOfBirth
    nationality
    kycLevel
    kycReview
    kycReviewComment
    status
    isUsPerson
    identityDocument{
      name
      documentTypeReference
      status
    }
    residenceDocument{
      name
      documentTypeReference
      status
    }
    additionalDocument{
      name
      documentTypeReference
      status
    }
    createdOn
    updatedOn
`

export default {
  fieldsPartial,
  buildQuery,
  getParentResource,
  getName,
  getLabel,
  getRecordById,
  getShowPathForId,
  getLabelForRecord,
  filterTypes,
  getMutationInputPair,
  fieldsPartialForList,
}
