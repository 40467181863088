import get from 'lodash/get'

const fieldsPartial = `
    id
    restrictionGroupReference
    name
    isWhitelist
    merchantsList
    status
    startDate
    createdOn
    updatedOn
`

const filterTypes = {
  ids: '[ID]',
}

const buildQuery = (fetchType, resourceName, params) => {
  return null
}

const getMutationInputPair = (key, data) => {
  switch (key) {
    case 'id':
    case 'createdOn':
    case 'updatedOn':
    case 'restrictionGroupReference':
    case 'isWhitelist':
      return null
    case 'merchantsList':
      if (typeof data[key] === 'string') return [key, data[key].replace(/\s/g, '').split(',')]
      return [key, data[key]]
    case 'startDate':
      return [key, data[key].slice(0, 19)]
    default:
      return [key, data[key]]
  }
}

const getParentResource = () => null

const getName = () => 'MerchantIdRestrictionGroups'

const getLabel = () => 'Card - Merchant Id'

const getRecordById = (state, id) => get(state, `admin.resources.MerchantIdRestrictionGroups.data.${id}`, null)

const getShowPathForId = id => '/' + getName() + '/' + id + '/show'

const getLabelForRecord = record => {
  if (!record) {
    return ''
  }
  return record.name
}

export default {
  fieldsPartial,
  buildQuery,
  getParentResource,
  getName,
  getLabel,
  getRecordById,
  getShowPathForId,
  getLabelForRecord,
  filterTypes,
  getMutationInputPair,
}
