import React from 'react'
import countryList from 'react-select-country-list'
import { SelectInput } from 'react-admin'

const CountrySelectorInput = ({ source, defaultValue }) => (
  <SelectInput
    source={source}
    optionText="label"
    optionValue="value"
    choices={countryList().getData()}
    defaultValue={defaultValue}
  />
)

export default CountrySelectorInput
