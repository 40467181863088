import { getResourceByName } from '../resources'
import { buildParseMutationResponse, getMutationQuery } from '../utils'
import attachmentsResource from '../resources/attachments'
import { CREATE, UPDATE } from 'react-admin'

const serializeFile = (file, params = {}) => {
  const { name, type } = file
  return { name, mimeType: type, ...params }
}

const convertFileToBase64 = file =>
  new Promise((resolve, reject) => {
    if (!file) {
      resolve(null)
      return
    }
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(serializeFile(file, { data: reader.result.split(';base64,')[1] }))
    reader.onerror = () => resolve(null)
  })

const prepareAttachments = files =>
  files
    .filter(Boolean)
    .map(f => f.rawFile)
    .map(convertFileToBase64)

export default async (client, fetchType, resourceName, params) => {
  if ([CREATE, UPDATE].includes(fetchType) && params.data.attachments) {
    const resource = getResourceByName(resourceName)
    if (!resource) {
      return true
    }
    let attachments
    if (params.data.attachments.length) {
      attachments = params.data.attachments
    } else {
      attachments = [params.data.attachments]
    }

    const previousAttachments = attachments.filter(f => f.id)
    const previousAttachmentIds = previousAttachments.map(a => a.id)
    const newFiles = attachments.filter(f => !f.id)
    let newAttachmentsData = await Promise.all(prepareAttachments(newFiles))
    newAttachmentsData = newAttachmentsData.filter(Boolean)
    const attachmentKey = 'attachment'
    const parseResponse = buildParseMutationResponse(CREATE, attachmentKey)
    const newAttachmentIds = []
    console.log(`Uploading ${newAttachmentsData.length} files…`)
    for (const [index, newAttachmentData] of newAttachmentsData.entries()) {
      const response = await client.mutate({
        mutation: getMutationQuery(CREATE, attachmentKey, attachmentsResource.fieldsPartial),
        variables: {
          input: newAttachmentData,
        },
      })
      const { data } = parseResponse(response)
      console.log(`Uploaded ${index + 1} of ${newAttachmentsData.length} files`)
      newAttachmentIds.push(data.id)
    }
    params.data.attachmentIds = previousAttachmentIds.concat(newAttachmentIds)
    delete params.data.attachments
  }
  return true
}
