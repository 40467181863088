import React from 'react'
import { SimpleShowLayout, TextField, ReferenceField } from 'react-admin'
import get from 'lodash/get'
import UMPriceField from '../../components/UMPriceField'
import UMDateField from '../../components/UMDateField'
import UMFullNameField from '../../components/UMFullNameField'
import { Card } from '@material-ui/core'
import UMChartField from '../../components/UMChartField'
import budgets from '../../api/resources/budgets'
import memberships from '../../api/resources/memberships'
import UMResourceLoader from '../../components/UMResourceLoader'

const BudgetInfoShowLayout = props => (
  <SimpleShowLayout {...props}>
    <TextField source="title" variant="title" gutterBottom addLabel={false} />
    <ReferenceField source="membership.id" reference="memberships" label="Employee" linkType="show">
      <UMFullNameField firstNameKey="user.firstName" lastNameKey="user.lastName" />
    </ReferenceField>
    <TextField source="type.code" label="Type" />
    <UMDateField source="startOn" label="Start date" />
    <UMDateField source="endOn" label="End date" />
    <UMChartField label="Status" />
    <UMPriceField source="total" format={v => (v ? v / 100 : 0)} />
    <UMPriceField source="used" format={v => (v ? v / 100 : 0)} />
    <UMPriceField source="remaining" format={v => (v ? v / 100 : 0)} />
    <UMPriceField source="professionalUsed" format={v => (v ? v / 100 : 0)} />
  </SimpleShowLayout>
)

export default ({ basePath, forceRecord, resource, version, classes }) => {
  const showProps = {
    basePath,
    record: forceRecord,
    resource,
    version,
    addLabel: true,
  }

  const budgetId = get(showProps.record, 'id')

  return (
    <Card className={classes.aside}>
      <UMResourceLoader resourceName={budgets.getName()} recordId={budgetId}>
        {data => (
          <UMResourceLoader resourceName={memberships.getName()} recordId={get(data, 'membership.id')}>
            {() => {
              return <BudgetInfoShowLayout {...showProps} record={{ ...data, title: 'Budget' }} />
            }}
          </UMResourceLoader>
        )}
      </UMResourceLoader>
    </Card>
  )
}
