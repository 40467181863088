import * as React from 'react'
import gql from 'graphql-tag'
import { Fragment, Component } from 'react'
import { Mutation, showNotification } from 'react-admin'
import { Button } from '@material-ui/core'
import SKUploadForm from './SimpleInputFileForm'
import { submit, isSubmitting } from 'redux-form'
import { connect } from 'react-redux'
import IconCancel from '@material-ui/icons/Cancel'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
class ImportEmployeesButton extends Component {
  constructor(organisationReference) {
    super()
    this.state = {
      fileContentBase64: null,
      importEmployeesFunc: null,
      showDialog: false,
      mutationStatus: null,
    }
    this.organisationReference = organisationReference.organisationReference
  }

  getMutationPayload(orgRef, b64) {
    return {
      query: gql`
        mutation importEmployees($input: ImportEmployeesInput!) {
          importEmployees(input: $input) {
            success
            response {
              total
              skipped {
                total
                items {
                  reason
                  email
                }
              }
              error {
                total
                items {
                  description
                  errorType
                }
              }
            }
            validationErrors {
              key
              code
              message
            }
          }
        }
      `,
      data: {
        input: {
          organisationReference: orgRef,
          fileContentBase64: b64,
        },
      },
    }
  }

  send_report = function(response) {
    var element = document.createElement('a')
    element.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(JSON.stringify(response, '\n', ' ')),
    )
    element.setAttribute('download', 'added_employees.txt')
    element.style.display = 'none'
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
  }

  blobToBase64 = function(blob, callback) {
    var reader = new FileReader()
    reader.onload = function() {
      var dataUrl = reader.result
      var base64 = dataUrl.split(',')[1]
      callback(base64)
    }
    reader.readAsDataURL(blob)
  }

  blobUrlToBlob = function(blobUrl, callback) {
    var xhr = new XMLHttpRequest()
    xhr.open('GET', blobUrl, true)
    xhr.responseType = 'blob'
    xhr.onload = function(e) {
      if (this.status === 200) {
        callback(this.response)
      }
    }
    xhr.send()
  }

  setMessages = function(employeesSkippedAmount, employeesErrorAmount) {
    document.getElementById('employees_skipped_amount').innerHTML = ''
    document.getElementById('employees_error_amount').innerHTML = ''
    if (this.state.mutationStatus === 'loading') {
      document.getElementById('employees_amount').innerHTML = ''
      document.getElementById('mutaton_status').innerHTML = 'Loading, please do no leave the page'
    } else if (this.state.mutationStatus === 'error') {
      document.getElementById('employees_skipped_amount').innerHTML =
        'Total employees skipped : ' + employeesSkippedAmount + '<br /><br />'
      document.getElementById('employees_error_amount').innerHTML =
        'Total employees with error : ' + employeesErrorAmount + '<br /><br />'
      document.getElementById('mutaton_status').innerHTML =
        'Some employees could not be imported, please read the exported report'
    } else if (this.state.mutationStatus === 'success') {
      document.getElementById('mutaton_status').innerHTML = 'Every employee has been imported'
    } else if (this.state.mutationStatus === 'noFile') {
      document.getElementById('mutaton_status').innerHTML = 'Please insert a file'
    }
  }

  handleSubmit = values => {
    if (values.files) {
      this.blobUrlToBlob(values.files.rawFile.preview, blob => {
        this.blobToBase64(blob, b64 => {
          this.setState(
            {
              fileContentBase64: b64,
            },
            () => {
              this.state.importEmployeesFunc()
            },
          )
        })
      })
    } else {
      this.setState(
        {
          mutationStatus: 'noFile',
        },
        () => {
          this.setMessages()
        },
      )
    }
  }

  render() {
    const { showDialog } = this.state
    const { isSubmitting } = this.props
    const options = {
      undoable: false,
      onSuccess: {
        callback: ({ data }) => {
          var response = data.importEmployees.response
          var employeesSkippedAmount = response.skipped.total
          var employeesErrorAmount = response.error.total
          document.getElementById('employees_amount').innerHTML =
            'Total employees parsed :' + response.total + '<br /><br />'
          var mutationStatus
          if (employeesSkippedAmount !== '0' || employeesErrorAmount !== '0') {
            mutationStatus = 'error'
            this.send_report(response)
          } else {
            mutationStatus = 'success'
          }
          this.setState(
            {
              mutationStatus: mutationStatus,
            },
            () => {
              this.setMessages(employeesSkippedAmount, employeesErrorAmount)
            },
          )
        },
      },
      onError: {
        notification: { body: 'Connection failure with service', level: 'warning' },
      },
    }
    return (
      <Mutation
        type="CUSTOM"
        resource="employees"
        payload={this.getMutationPayload(this.organisationReference, this.state.fileContentBase64)}
        options={options}
      >
        {(importEmployees, { loading: mutLoading, error: mutError }) => {
          // Define callbacks
          const handleClick = () => this.setState({ showDialog: true })
          const handleConfirm = () => {
            const { submit } = this.props
            this.setState(
              {
                importEmployeesFunc: importEmployees,
                mutationStatus: 'loading',
              },
              () => {
                this.setMessages()
              },
            )
            submit('importEmployeesForm')
          }
          const handleConfirmClose = () => this.setState({ showDialog: false })
          // Show notification on error
          if (mutLoading === false && mutError !== null) {
            console.error('importEmployees', mutError.message)
            alert(mutError.message)
          }

          return (
            <Fragment>
              {
                <Button color="primary" onClick={handleClick}>
                  Import Employees
                </Button>
              }
              <Dialog fullWidth open={showDialog} onClose={this.handleCloseClick}>
                <DialogTitle>Import employees for this organisation</DialogTitle>
                <DialogContent>
                  <SKUploadForm onSubmit={this.handleSubmit} formName={'importEmployeesForm'} />
                  <span id="employees_amount"></span>
                  <span id="employees_skipped_amount"></span>
                  <span id="employees_error_amount"></span>
                  <span id="mutaton_status"></span>
                </DialogContent>
                <DialogActions>
                  <Button color="primary" saving={isSubmitting} onClick={handleConfirm}>
                    Import Employees
                  </Button>
                  <Button label="ra.action.cancel" onClick={handleConfirmClose}>
                    <IconCancel />
                  </Button>
                </DialogActions>
              </Dialog>
            </Fragment>
          )
        }}
      </Mutation>
    )
  }
}
const mapStateToProps = state => ({
  isSubmitting: isSubmitting('importEmployeesForm')(state),
})
const mapDispatchToProps = {
  showNotification,
  submit,
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ImportEmployeesButton)
