import React from 'react'
import { Edit } from 'react-admin'
import { connect } from 'react-redux'
import UMBreadcrumbs from '../../components/UMBreadcrumbs'
import budgets from '../../api/resources/budgets'
import BudgetForm from './formEdit'
import { withStyles } from '@material-ui/core'
import commonStyles from '../common/styles'
import UMActions from '../../components/UMActions'
import memberships from '../../api/resources/memberships'
import MembershipShow from '../memberships/show'

const BudgetEdit = withStyles(commonStyles.show)(
  ({ record, parentRecord, permissions, dispatch, classes, ...props }) => {
    const formProps = { mode: 'edit', permissions }
    return (
      <Edit
        className={classes.root}
        actions={<UMActions classes={classes} title="Edit budget" />}
        title={<UMBreadcrumbs resourceName={budgets.getName()} />}
        aside={<MembershipShow permissions={permissions} classes={classes} forceRecord={parentRecord} />}
        undoable={false}
        {...props}
      >
        <BudgetForm {...formProps} />
      </Edit>
    )
  },
)

export default connect((state, ownProps) => {
  const record = budgets.getRecordById(state, ownProps.id)
  const parentRecord = record ? memberships.getRecordById(state, record.membership.id) : null
  return {
    record,
    parentRecord,
  }
})(BudgetEdit)
