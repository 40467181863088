import React, { Component } from 'react'
import { Loading, withDataProvider } from 'react-admin'
import compose from 'recompose/compose'
//import { connect } from 'react-redux'
import { Card, CardContent } from '@material-ui/core'
import EmployeeDashboard from './employeeView/EmployeeDashboard'
import FleetMgrDashboard from './fleetMgrView/FleetMgrDashboard'

class UMLoading extends Component {
  render() {
    if (this.props.permissions === 'EMPLOYEE') return <EmployeeDashboard {...this.props} />
    else if (this.props.permissions === 'FLEET_MGR') return <FleetMgrDashboard {...this.props} />

    return (
      <Card>
        <CardContent>
          <Loading loadingPrimary="" loadingSecondary="ra.page.loading" />
        </CardContent>
      </Card>
    )
  }
}

// const mapStateToProps = async state => {
//   console.log(state)
//   //console.log(me.getRecordById(state, userId))
//   return {
//     version: state.admin.ui.viewVersion,
//   }
// }

export default compose(
  withDataProvider,
  //withStyles(styles),
)(UMLoading)

// export default compose(
//   connect(mapStateToProps),
//   withDataProvider,
//   withStyles(styles),
// )(UMLoading)
