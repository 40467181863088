import get from 'lodash/get'

const fieldsPartial = `
  id
  userReference
  organisationReference
  name
  walletReference
  walletIban
  kycLevel
  kycReview
  kycReviewComment
  status
  identityDocument{
    name
    documentTypeReference
    status
  }
  bceDocument{
    name
    documentTypeReference
    status
  }
  walletTotalBalance
  walletAuthorizedBalance
  updatedOn
  createdOn
  legalNumberOfEmployeeRange
  legalNetIncomeRange
  legalAnnualTurnOver
  onTreezor
  involvedSanctions
  activityOutsideEu
  economicSanctions
  residentCountriesSanctions
  wallet{
    automaticRefill
    minimumAmount
  }

`

const filterTypes = {
  ids: '[ID]',
  organisationReference: 'ID',
}

const buildQuery = (fetchType, resourceName, params) => {
  return null
}

const getMutationInputPair = (key, data) => {
  switch (key) {
    case 'id':
    case 'createdOn':
    case 'updatedOn':
    case 'kycLevel':
    case 'kycReview':
    case 'kycReviewComment':
    case 'status':
    case 'walletIban':
    case 'walletReference':
    case 'organisationReference':
    case 'userReference':
    case 'organisation':
    case 'walletTotalBalance':
    case 'legalNumberOfEmployeeRange':
    case 'legalNetIncomeRange':
    case 'legalAnnualTurnOver':
    case 'onTreezor':
    case 'walletAuthorizedBalance':
    case 'wallet':
      return null
    case 'identityDocument':
    case 'bceDocument':
      if (data[key] && data[key]['documentTypeReference'] && data[key]['fileContentBase64']) {
        return [
          key,
          {
            documentTypeReference: data[key]['documentTypeReference'],
            fileContentBase64: data[key]['fileContentBase64'],
          },
        ]
      }
      break
    default:
      return [key, data[key]]
  }
}

const getParentResource = role => ({
  name: 'organisations',
  idKey: 'organisationReference',
})

const getName = () => 'paymentOrganisations'

const getLabel = () => 'Payment Organisation'

const getRecordById = (state, id) => get(state, `admin.resources.paymentOrganisations.data.${id}`, null)

const getShowPathForId = id => '/' + getName() + '/' + id + '/show'

const getLabelForRecord = record => {
  return 'KYC-Organisation'
}

export default {
  fieldsPartial,
  buildQuery,
  getParentResource,
  getName,
  getLabel,
  getRecordById,
  getShowPathForId,
  getLabelForRecord,
  filterTypes,
  getMutationInputPair,
}
