import get from 'lodash/get'
import gql from 'graphql-tag'

const fieldsPartial = `
  id
  firstName
  lastName
  isSuperuser
  memberships {
    id
    role
    organisation {
      id
      name
    }
    currentBudgets{
      id
      total
      remaining
      used
      professionalUsed
      startOn
      endOn
      type{
        name
        code
        profile
      }
    }
    budgetsSummary{
      proTotal
      proUsed
      proRemaining
      privateTotal
      privateUsed
      privateRemaining
    }
  }
  createdOn
  updatedOn
`

const filterTypes = {}

const buildQuery = (fetchType, params) => {
  return {
    query: gql`query me {
      me {
        ${fieldsPartial}
      }
    }`,
    parseResponse: response => {
      const data = response.data.me
      return { data }
    },
  }
}

const getParentResource = () => null

const getName = () => 'me'

const getLabel = () => 'Home'

const getRecordById = (state, id) => get(state, `admin.resources.me.data.${id}`, null)

const getMainRecord = state => {
  const id = get(state, `admin.resources.me.list.ids[0]`, null)
  return getRecordById(state, id)
}

const getShowPathForId = id => '/' + getName() + '/' + id + '/show'

const getShowPathForRecord = record => (record ? getShowPathForId(record.id) : null)

const getLabelForRecord = record => {
  if (!record) {
    return ''
  }
  return `${record.firstName} ${record.lastName}`
}

export default {
  fieldsPartial,
  buildQuery,
  getParentResource,
  getName,
  getLabel,
  getRecordById,
  getMainRecord,
  getShowPathForId,
  getShowPathForRecord,
  getLabelForRecord,
  filterTypes,
}
