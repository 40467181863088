import React from 'react'
import { List, TextField } from 'react-admin'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { parse } from 'query-string'
import get from 'lodash/get'
import commonStyles from '../common/styles'
import { UMPagination, UMWrappedDatagrid } from '../../components/UMDatagrid'
import UMDateField from '../../components/UMDateField'
import UMPriceField from '../../components/UMPriceField'
import UMBreadcrumbs from '../../components/UMBreadcrumbs'
import UMActions from '../../components/UMActions'
import UMChartField from '../../components/UMChartField'
import memberships from '../../api/resources/memberships'
import organisations from '../../api/resources/organisations'
import OrganisationShow from '../organisations/show'

const CreateRelatedMembership = ({ record }) => {
  return (
    <Button
      component={Link}
      to={{
        pathname: '/employees/create',
        // state: { record: { organisationId: record.id } },
        search: '?organisationId=' + record,
      }}
    >
      New Employee
    </Button>
  )
}

const Actions = ({ record, classes, permissions }) => {
  if (permissions === 'FLEET_MGR' || permissions === 'EMPLOYEE')
    return <UMActions classes={classes} title="Employees"></UMActions>
  else
    return (
      <UMActions classes={classes} title="Employees">
        <CreateRelatedMembership record={record} />
      </UMActions>
    )
}

const MembershipList = withStyles(commonStyles.list)(
  ({ classes, parentRecord, record, organisationId, dispatch, permissions, ...props }) => {
    return (
      <List
        className={classes.root}
        bulkActions={false}
        actions={<Actions classes={classes} record={organisationId} permissions={permissions} />}
        sort={{ field: 'user.lastName', order: 'ASC' }}
        pagination={<UMPagination />}
        perPage={50}
        title={<UMBreadcrumbs resourceName={organisations.getName()} forceRecord={parentRecord} />}
        aside={<OrganisationShow permissions={props.permissions} classes={classes} forceRecord={parentRecord} />}
        {...props}
      >
        <UMWrappedDatagrid
          rowClick={(id, basePath, record) => memberships.getShowPathForId(id)}
          emptyText="No employees"
        >
          <TextField source="user.lastName" label="Last name" />
          <TextField source="user.firstName" label="First name" />
          <UMDateField source="createdOn" label="Creation date" />
          <UMPriceField
            source="budgetsSummary.privateTotal"
            label="Budget total"
            format={v => (v ? v / 100 : 0)}
            sortable={false}
          />

          <UMPriceField
            source="budgetsSummary.privateUsed"
            label="Budget used"
            format={v => (v ? v / 100 : 0)}
            sortable={false}
          />

          <UMPriceField
            source="budgetsSummary.privateRemaining"
            label="Budget remaining"
            format={v => (v ? v / 100 : 0)}
            sortable={false}
          />
          <UMChartField
            usedKey="budgetsSummary.privateUsed"
            totalKey="budgetsSummary.privateTotal"
            label="Budget status"
          />
          <BudgetsButton />
        </UMWrappedDatagrid>
      </List>
    )
  },
)

const BudgetsButton = ({ record }) => {
  console.log(record)
  return (
    <Button
      onClick={e => {
        console.log(record)
        e.stopPropagation()
        alert('clicked')
      }}
      component={Link}
      to={{
        pathname: '/budgets',
        search: '?organisationId=' + record.id,
      }}
    >
      Budgets
    </Button>
  )
}
export default connect((state, ownProps) => {
  const query = get(ownProps, 'location.search')
  const params = parse(query)
  const filterStr = params.filter || '{}'
  const filter = JSON.parse(filterStr)
  const { organisationId } = filter
  const parentRecord = organisations.getRecordById(state, organisationId) || { id: organisationId }
  const record = { organisation: parentRecord }

  return {
    record,
    parentRecord,
    organisationId,
  }
})(MembershipList)
