import React, { Children, cloneElement } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { AppBar, Toolbar, Typography, withStyles, createStyles, withWidth } from '@material-ui/core'
import compose from 'recompose/compose'
import { toggleSidebar as toggleSidebarAction, Headroom, UserMenu, LoadingIndicator } from 'react-admin'
import me from '../api/resources/me'

const styles = theme =>
  createStyles({
    toolbar: {
      paddingLeft: 24,
      paddingRight: 24,
    },
    menuButton: {
      marginLeft: '0.5em',
      marginRight: '0.5em',
    },
    menuButtonIconClosed: {
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: 'rotate(0deg)',
    },
    menuButtonIconOpen: {
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: 'rotate(180deg)',
    },
    title: {
      flex: 1,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  })

const UMUserMenu = connect(state => ({
  currentUser: me.getMainRecord(state),
}))(({ currentUser, ...props }) => (
  <UserMenu {...props}>
    {/* <MenuItemLink
      to={memberships.getShowPathForId(get(currentUser, 'memberships[0].id'))}
      primaryText={me.getLabelForRecord(currentUser)}
      leftIcon={<AccountCircleIcon />}
      divider
    /> */}
  </UserMenu>
))

const UMAppBar = ({
  children,
  classes,
  className,
  logo,
  logout,
  open,
  title,
  toggleSidebar,
  userMenu,
  width,
  ...rest
}) => (
  <Headroom>
    <AppBar className={className} color="secondary" position="static" {...rest}>
      <Toolbar disableGutters variant={width === 'xs' ? 'regular' : 'dense'} className={classes.toolbar}>
        {Children.count(children) === 0 ? (
          <Typography variant="title" color="inherit" className={classes.title} id="react-admin-title" />
        ) : (
          children
        )}
        <LoadingIndicator />
        {cloneElement(userMenu, { logout })}
      </Toolbar>
    </AppBar>
  </Headroom>
)

UMAppBar.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  logout: PropTypes.element,
  open: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  userMenu: PropTypes.node,
  width: PropTypes.string,
}

UMAppBar.defaultProps = {
  userMenu: <UMUserMenu />,
}

const enhance = compose(
  connect(
    state => ({
      locale: state.i18n.locale, // force redraw on locale change
    }),
    {
      toggleSidebar: toggleSidebarAction,
    },
  ),
  withStyles(styles),
  withWidth(),
)

export default enhance(UMAppBar)
