import React from 'react'
import { Create } from 'react-admin'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { parse } from 'query-string'
import get from 'lodash.get'
import UMBreadcrumbs from '../../components/UMBreadcrumbs'
import memberships from '../../api/resources/memberships'
import BudgetForm from './form'
import commonStyles from '../common/styles'
import MembershipShow from '../memberships/show'
import { withStyles } from '@material-ui/core'
import UMActions from '../../components/UMActions'

const BudgetCreate = withStyles(commonStyles.create)(
  ({ parentRecord, membershipId, dispatch, permissions, classes, ...props }) => {
    if (!parentRecord) {
      if (!membershipId) {
        dispatch(push('/'))
        return null
      }
      parentRecord = { id: membershipId }
    }

    const formProps = {
      mode: 'create',
      permissions,
      defaultValue: {
        membership: parentRecord,
      },
    }

    return (
      <Create
        className={classes.root}
        actions={<UMActions classes={classes} title="New budget" />}
        title={
          <UMBreadcrumbs resourceName={memberships.getName()} forceRecord={parentRecord} currentLabel="New budget" />
        }
        aside={<MembershipShow permissions={permissions} classes={classes} forceRecord={parentRecord} />}
        {...props}
      >
        <BudgetForm {...formProps} />
      </Create>
    )
  },
)

export default connect((state, ownProps) => {
  const query = get(ownProps, 'location.search')
  const params = parse(query)
  const { membershipId } = params

  const parentRecord = membershipId ? memberships.getRecordById(state, membershipId) : null
  return {
    parentRecord,
    membershipId,
  }
})(BudgetCreate)
