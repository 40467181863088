import React from 'react'
import { Show, SimpleShowLayout, TextField, DateField, EditButton, BooleanField } from 'react-admin'
import { withStyles, CardActions } from '@material-ui/core'
import commonStyles from '../common/styles'
import UMBreadcrumbs from '../../components/UMBreadcrumbs'
import users from '../../api/resources/users'

const ShowActions = ({ basePath, data, resource }) => (
  <CardActions>
    <EditButton basePath={basePath} record={data} />
  </CardActions>
)

export default withStyles(commonStyles.show)(({ classes, permissions, ...props }) => (
  <Show
    {...props}
    className={classes.root}
    title={<UMBreadcrumbs resourceName={users.getName()} />}
    actions={<ShowActions classes={classes} />}
  >
    <SimpleShowLayout>
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="email" />
      <TextField source="status" />
      <TextField source="phoneNumber" />
      <BooleanField source="isSuperuser" />
      <DateField label="Created on" source="createdOn" />
    </SimpleShowLayout>
  </Show>
))
