import React from 'react'
import { Create } from 'react-admin'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { parse } from 'query-string'
import get from 'lodash.get'
import UMBreadcrumbs from '../../components/UMBreadcrumbs'
import budgets from '../../api/resources/budgets'
import { EXPENSE_TYPE_PRIVATE } from '../../api/resources/expenses'
import partners from '../../api/resources/partners'
import ExpenseForm from './form'
import commonStyles from '../common/styles'
import BudgetShow from '../budgets/show'
import { withStyles } from '@material-ui/core'
import UMActions from '../../components/UMActions'
import { getTodayDate } from '../../utils/dates'

const ExpenseCreate = withStyles(commonStyles.create)(
  ({ parentRecord, budgetId, defaultPartner, title, newExpense = false, dispatch, permissions, classes, ...props }) => {
    if (!parentRecord) {
      if (!budgetId) {
        dispatch(push('/'))
        return null
      }
      parentRecord = { id: budgetId }
    }
    const formProps = {
      mode: 'create',
      defaultPartner,
      permissions,
      newExpense,
      defaultValue: {
        type: EXPENSE_TYPE_PRIVATE,
        budget: parentRecord,
        internal: true,
        expenseOn: getTodayDate(),
      },
    }

    return (
      <Create
        className={classes.root}
        actions={<UMActions classes={classes} title={'New expense'} />}
        title={<UMBreadcrumbs resourceName={budgets.getName()} forceRecord={parentRecord} currentLabel={title} />}
        aside={<BudgetShow permissions={permissions} classes={classes} forceRecord={parentRecord} />}
        {...props}
      >
        <ExpenseForm {...formProps} />
      </Create>
    )
  },
)

export default connect((state, ownProps) => {
  const query = get(ownProps, 'location.search')
  const params = parse(query)
  const filterStr = params.filter || '{}'
  const filter = JSON.parse(filterStr)
  const { budgetId, title, newExpense } = filter
  const parentRecord = budgetId ? budgets.getRecordById(state, budgetId) : null
  const defaultPartner = partners.getMainRecord(state)
  return {
    parentRecord,
    budgetId,
    defaultPartner,
    title,
    newExpense,
  }
})(ExpenseCreate)
