import React from 'react'
import { Edit } from 'react-admin'
import UMBreadcrumbs from '../../components/UMBreadcrumbs'
import VirtualCardForm from './form'
import virtualCards from '../../api/resources/virtualCards'

const VirtualCardEdit = ({ permissions, dispatch, ...props }) => {
  const formProps = { permissions }
  return (
    <Edit
      title={<UMBreadcrumbs resourceName={virtualCards.getName()} currentLabel="Edit virtual card" />}
      undoable={false}
      {...props}
    >
      <VirtualCardForm {...formProps} />
    </Edit>
  )
}

export default VirtualCardEdit
