import React from 'react'
import PropTypes from 'prop-types'

const ProToBusinessField = ({ source, record = {} }) => {
  switch (record[source]) {
    case 'PRO':
      return <span>{'BUSINESS'}</span>
    default:
      return <span>{record[source]}</span>
  }
}

ProToBusinessField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
}

export default ProToBusinessField
