import get from 'lodash/get'
import { stringify } from 'query-string'
import { formatDate } from '../../utils/dates'
import attachments from './attachments'

export const EXPENSE_TYPE_PRO = 'PRO'
export const EXPENSE_TYPE_PRIVATE = 'PRIVATE'
export const EXPENSE_TYPE_MIXED = 'MIXED'
export const EXPENSE_TYPES = {
  [EXPENSE_TYPE_PRIVATE]: 'Private',
  [EXPENSE_TYPE_PRO]: 'Business',
  [EXPENSE_TYPE_MIXED]: 'Mixed',
}

const fieldsPartial = `
  id
  budget {
    id
    membership {
      id
      user {
        id
        firstName
        lastName
      }
      organisation {
        id
        name
      }
    }
  }
  title
  type
  category {
    id
    name
  }
  partner {
    id
    name
  }
  partnerReference
  amount
  employerAmount
  startOn
  endOn
  expenseOn
  justification
  providerName
  paymentSource
  validationStatus
  validationComment
  attachments {
    ${attachments.fieldsPartial}
  }
  internal
  closed
  tag {
    id
    name
  }
`

const filterTypes = {
  ids: '[ID]',
  budgetId: 'ID',
  organisationId: 'ID',
  isSignificant: 'Boolean',
}

const getMutationInputPair = (key, data) => {
  switch (key) {
    case 'id':
    case 'type':
    case 'internal':
    case 'closed':
    case 'validationStatus':
    case 'validationComment':
    case 'attachments':
    case 'paymentSource':
    case 'providerName':
    case 'isRefund':
      return null
    case 'category':
    case 'partner':
    case 'budget':
      return [key + 'Id', data[key]['id']]
    case 'tag':
      return [key + 'Id', !data[key] || data[key]['id'] === '' ? null : data[key]['id']]
    case 'employerAmount': {
      if (data.type === EXPENSE_TYPE_PRIVATE) {
        return [key, 0]
      } else if (data.type === EXPENSE_TYPE_PRO) {
        return [key, data.amount]
      }
      return [key, data[key] || 0]
    }
    default:
      return [key, data[key]]
  }
}

const getParentResource = () => ({
  name: 'budgets',
  idKey: 'budget.id',
})

const getName = () => 'expenses'

const getLabel = () => 'Expenses'

const getRecordById = (state, id) => get(state, `admin.resources.expenses.data.${id}`, null)

const getShowPathForId = id => '/' + getName() + '/' + id + '/show'

const getListPathForParentId = budgetId => '/' + getName() + '?' + stringify({ filter: JSON.stringify({ budgetId }) })

const getListPathForOrganisationId = (budgetId, organisationId) =>
  '/' +
  getName() +
  '?' +
  stringify({ filter: JSON.stringify({ budgetId }) }) +
  '&' +
  stringify({ filter: JSON.stringify({ organisationId }) })

const getLabelForRecord = record => {
  if (!record) {
    return ''
  }
  return `${record.title} (${formatDate(record.expenseOn)})`
}
export default {
  fieldsPartial,
  getMutationInputPair,
  getParentResource,
  getName,
  getLabel,
  getRecordById,
  getShowPathForId,
  getListPathForParentId,
  getLabelForRecord,
  filterTypes,
  getListPathForOrganisationId,
}
