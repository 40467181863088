import React from 'react'
import { SimpleShowLayout, TextField, NumberField } from 'react-admin'
import { Card } from '@material-ui/core'
import UMResourceLoader from '../../components/UMResourceLoader'
import UMChartField from '../../components/UMChartField'
import UMPriceField from '../../components/UMPriceField'
import organisations from '../../api/resources/organisations'
import UMDateField from '../../components/UMDateField'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'

const ViewAllCards = ({ record }) => {
  return (
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: '/virtualCards',
        // state: { record: { organisationId: record.id } },
        search: '?organisationId=' + record,
      }}
    >
      View all cards
    </Button>
  )
}

const OrganisationInfoShowLayout = props => (
  <SimpleShowLayout {...props}>
    <TextField source="name" variant="title" addLabel={false} gutterBottom />
    <NumberField source="summary.employeeCount" label="Employees" />
    <UMChartField label="Status" usedKey="summary.usedBudget" totalKey="summary.totalBudget" />
    <UMPriceField source="summary.totalBudget" label="Total budget" format={v => (v ? v / 100 : 0)} />
    <UMPriceField source="summary.usedBudget" label="Used budget" format={v => (v ? v / 100 : 0)} />
    <UMPriceField source="summary.remainingBudget" label="Remaining budget" format={v => (v ? v / 100 : 0)} />
    <UMPriceField source="summary.professionalUsedBudget" label="Business expenses" format={v => (v ? v / 100 : 0)} />
    <TextField label="Payment cards" />
    <div>
      {props.cardsCount}
      <ViewAllCards record={props.organisationId} />
    </div>
    <UMDateField source="updatedOn" label="Last update" />
  </SimpleShowLayout>
)

export default ({ permissions, basePath, forceRecord, organisationRecord, cardsCount, resource, version, classes }) => {
  const showProps = {
    basePath,
    record: forceRecord,
    organisationId: organisationRecord.id,
    cardsCount: cardsCount,
    resource,
    version,
    permissions,
    addLabel: true,
  }
  const organisationId = showProps.organisationId
  return (
    <Card className={classes.aside}>
      <UMResourceLoader resourceName={organisations.getName()} recordId={organisationId} cardsCount={cardsCount}>
        {data => {
          return <OrganisationInfoShowLayout {...showProps} record={data} />
        }}
      </UMResourceLoader>
    </Card>
  )
}
