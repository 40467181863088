import React from 'react'
import { Show, SimpleShowLayout, TextField, DateField, EditButton, RichTextField } from 'react-admin'
import { withStyles, CardActions } from '@material-ui/core'
import commonStyles from '../common/styles'
import UMBreadcrumbs from '../../components/UMBreadcrumbs'
import mccRestrictionGroups from '../../api/resources/mccRestrictionGroups'

const ShowActions = ({ basePath, data, resource }) => (
  <CardActions>
    <EditButton basePath={basePath} record={data} />
  </CardActions>
)

export default withStyles(commonStyles.show)(({ classes, permissions, ...props }) => (
  <Show
    {...props}
    className={classes.root}
    title={<UMBreadcrumbs resourceName={mccRestrictionGroups.getName()} />}
    actions={<ShowActions classes={classes} />}
  >
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="startDate" />
      <TextField source="status" />
      <DateField label="Created on" source="createdOn" />
      <DateField label="Updated on" source="updatedOn" />
      <RichTextField label="mcc white listed" source="mccList" />
    </SimpleShowLayout>
  </Show>
))
