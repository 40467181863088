import React from 'react'
import { List, TextField, ChipField } from 'react-admin'
import { UMPagination, UMWrappedDatagrid } from '../../components/UMDatagrid'
import UMDateField from '../../components/UMDateField'
import UMPriceField from '../../components/UMPriceField'
import ProToBusinessField from './ProToBusinessField'

const Header = () => {
  return (
    <div style={{ width: '100%' }}>
      <h2>Your expenses</h2>
    </div>
  )
}

const ExpenseList = ({ classes, parentRecord, record, dispatch, permissions, ...props }) => {
  return (
    <List
      bulkActions={false}
      sort={{ field: 'expenseOn', order: 'DESC' }}
      filter={{ budgetId: record.id }}
      pagination={<UMPagination />}
      perPage={50}
      actions={<Header />}
      {...props}
    >
      <UMWrappedDatagrid rowClick="edit" emptyText="No expenses">
        <TextField source="category.name" label="Category" />
        <TextField source="providerName" label="Provider" />
        <ProToBusinessField source="type" sortable={false} />
        <UMPriceField source="amount" label="Total amount" format={v => (v ? v / 100 : 0)} />
        <UMPriceField source="employerAmount" label="Employer amount" format={v => (v ? v / 100 : 0)} />
        <UMDateField source="expenseOn" label="Date" />
        <ChipField source="tag.name" label="Tag" />
        <TextField source="paymentSource" label="Payment source" />
      </UMWrappedDatagrid>
    </List>
  )
}

export default ExpenseList
