import get from 'lodash/get'

const fieldsPartial = `
  id
  firstName
  lastName
  email
  status
  phoneNumber
  isSuperuser
  memberships {
    id
    role
    organisation {
      id
      name
    }
    currentBudgets {
      id
    }
  }
  createdOn
  updatedOn
`

const filterTypes = {
  // last_name: 'String'
  ids: '[ID]',
  q: 'String',
}

const buildQuery = (fetchType, resourceName, params) => {
  return null
}

const getMutationInputPair = (key, data) => {
  switch (key) {
    case 'id':
    case 'memberships':
    case 'updatedOn':
    case 'createdOn':
      return null
    default:
      return [key, data[key]]
  }
}

const getParentResource = () => null

const getName = () => 'users'

const getLabel = () => 'Users'

const getRecordById = (state, id) => get(state, `admin.resources.users.data.${id}`, null)

const getShowPathForId = id => '/' + getName() + '/' + id + '/show'

const getShowPathForRecord = record => (record ? getShowPathForId(record.id) : null)

const getLabelForRecord = record => {
  if (!record) {
    return ''
  }
  return `${record.firstName} ${record.lastName}`
}
const fieldsPartialForList = `
  id
  firstName
  lastName
  email
  status
  phoneNumber
  isSuperuser
  createdOn
  updatedOn
`

export default {
  fieldsPartial,
  buildQuery,
  getParentResource,
  getName,
  getLabel,
  getRecordById,
  getShowPathForId,
  getShowPathForRecord,
  getLabelForRecord,
  filterTypes,
  getMutationInputPair,
  fieldsPartialForList,
}
