import get from 'lodash/get'

const fieldsPartial = `
    id
    restrictionGroupReference
    name
    isWhitelist
    mccList
    status
    startDate
    createdOn
    updatedOn
`

const filterTypes = {
  ids: '[ID]',
}

const buildQuery = (fetchType, resourceName, params) => {
  return null
}

const getMutationInputPair = (key, data) => {
  switch (key) {
    case 'id':
    case 'createdOn':
    case 'updatedOn':
    case 'restrictionGroupReference':
    case 'isWhitelist':
      return null
    default:
      return [key, data[key]]
  }
}

const getParentResource = () => null

const getName = () => 'MccRestrictionGroups'

const getLabel = () => 'Card - MCC'

const getRecordById = (state, id) => get(state, `admin.resources.mccRestrictionGroups.data.${id}`, null)

const getShowPathForId = id => '/' + getName() + '/' + id + '/show'

const getLabelForRecord = record => {
  if (!record) {
    return ''
  }
  return record.name
}

export default {
  fieldsPartial,
  buildQuery,
  getParentResource,
  getName,
  getLabel,
  getRecordById,
  getShowPathForId,
  getLabelForRecord,
  filterTypes,
  getMutationInputPair,
}
