import CoreDataProvider from './CoreDataProvider'
import WalletDataProvider from './WalletDataProvider'
import gql from 'graphql-tag'

let coreDataProvider
let walletDataProvider

const superDataProvider = async (type, resource, params) => {
  switch (resource) {
    case 'paymentOrganisations':
    case 'cardTransactions':
    case 'documents':
    case 'virtualCards':
    case 'shareholders':
    case 'employees':
    case 'mccRestrictionGroups':
    case 'merchantIdRestrictionGroups':
    case 'merchants':
      return walletDataProvider(type, resource, params)
    default:
      break
  }
  if (type === 'GET_ONE' && resource === 'organisations') {
    let { data, total } = await walletDataProvider('GET_LIST', 'paymentOrganisations', {
      filter: { organisationReference: params.id },
    })
    if (total === 1) {
      //const organisationId = params.id
      //let {data: paymentOrganisation} = await walletDataProvider(type, 'paymentOrganisations', {id: data[0].id})
      let paymentOrganisationId = data[0].id

      //data[0].id = organisationId
      let { data: organisation } = await coreDataProvider(type, resource, params)
      //organisation = {...data[0]['isTreezor'], ...organisation}
      var wallet = data[0]['wallet']
      if (wallet) {
        organisation['automaticRefill'] = wallet['automaticRefill']
        organisation['minimumAmount'] = wallet['minimumAmount']
      }
      organisation['legalNumberOfEmployeeRange'] = data[0]['legalNumberOfEmployeeRange']
      organisation['legalNetIncomeRange'] = data[0]['legalNetIncomeRange']
      organisation['legalAnnualTurnOver'] = data[0]['legalAnnualTurnOver']
      organisation['involvedSanctions'] = data[0]['involvedSanctions']
      organisation['activityOutsideEu'] = data[0]['activityOutsideEu']
      organisation['economicSanctions'] = data[0]['economicSanctions']
      organisation['residentCountriesSanctions'] = data[0]['residentCountriesSanctions']
      organisation['isTreezor'] = data[0]['onTreezor']
      organisation['userReference'] = data[0]['userReference']
      organisation['walletAuthorizedBalance'] = data[0]['walletAuthorizedBalance']
      organisation['paymentOrganisationId'] = paymentOrganisationId
      return new Promise((resolve, reject) => resolve({ data: organisation }))
    }
  } else if (type === 'CREATE' && resource === 'expenses') {
    const mutationPayload = {
      query: gql`
        mutation transferAmountToWallet($input: TransferAmountToWalletInput!) {
          transferAmountToWallet(input: $input) {
            success
            transfer
            validationErrors {
              key
              code
              message
            }
          }
        }
      `,
      data: {
        input: {
          organisationReference: params.data.budget.membership.organisation.id,
          amount: params.data.amount,
        },
      },
    }
    // take validation error into account
    let { data: transfer } = await walletDataProvider('CUSTOM', 'paymentOrganisations', mutationPayload)
    console.log(transfer)
    let { data: expense } = await coreDataProvider(type, resource, params)
    return new Promise((resolve, reject) => resolve({ data: expense }))
  }
  return coreDataProvider(type, resource, params)
}

export default {
  init: async authProvider => {
    CoreDataProvider.init(authProvider).then(dataProvider => (coreDataProvider = dataProvider))
    WalletDataProvider.init(authProvider).then(dataProvider => (walletDataProvider = dataProvider))
    return superDataProvider
  },
}
