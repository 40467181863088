import get from 'lodash/get'
import gql from 'graphql-tag'
import { CREATE, UPDATE, UPDATE_MANY } from 'react-admin'
import { capitalizeString } from '../utils/strings'

export const baseMutationPayloadPartial = `
  success
  validationErrors {
    key
    code
    message
  }
`

export const getItemQuery = (itemKey, fieldsPartial) => {
  return gql`query ${itemKey}($id: ID!) {
    ${itemKey}(id: $id) {
      ${fieldsPartial}
    }
  }`
}

export const getCollectionQuery = (collectionKey, fieldsPartial, variablePart, argumentPart) => {
  return gql`query ${collectionKey}${variablePart} {
    ${collectionKey}${argumentPart} {
      items {
        ${fieldsPartial}
      }
      count
    }
  }`
}

export const getCollectionQueryParts = (params, filterTypes) => {
  // Filters
  const combinedArguments = { ...params.filter }
  const combinedArgumentTypes = { page: 'Int', perPage: 'Int', sort: 'String' }
  Object.keys(combinedArguments).forEach(key => {
    combinedArgumentTypes[key] = filterTypes[key]
  })

  // Target - used for reference
  if (params.target !== undefined) {
    combinedArguments[params.target] = params.id
    combinedArgumentTypes[params.target] = 'ID'
  }

  // Ids - used for GET_MANY
  if (params.ids !== undefined) {
    combinedArguments['ids'] = params.ids
    combinedArguments['perPage'] = params.ids.length
    combinedArgumentTypes['ids'] = '[ID]!'
    combinedArgumentTypes['perPage'] = 'Int'
  }

  // Pagination
  if ('pagination' in params) {
    combinedArguments['page'] = params.pagination.page
    combinedArguments['perPage'] = params.pagination.perPage
  }

  // Sort
  if ('sort' in params) {
    const directionPrefix = params.sort.order === 'DESC' ? '-' : ''
    combinedArguments['sort'] = directionPrefix + params.sort.field
  }

  let variablePart = '',
    argumentPart = ''

  if (Object.keys(combinedArguments).length > 0) {
    variablePart = `(${Object.keys(combinedArguments)
      .map(key => `$${key}: ${combinedArgumentTypes[key]}`)
      .join(', ')})`
    argumentPart = `(${Object.keys(combinedArguments)
      .map(key => `${key}: $${key}`)
      .join(', ')})`
  }

  return { variablePart, argumentPart, variables: combinedArguments }
}

const getMutationKey = (type, itemKey) => {
  console.debug('getMutationKey', type, itemKey)
  const mutationType = [CREATE, UPDATE].includes(type) ? type : CREATE
  if (type === UPDATE_MANY) return 'updateEmployees'
  return `${mutationType.toLowerCase()}${capitalizeString(itemKey)}`
}

export const getMutationQuery = (mutationType, itemKey, fieldsPartial) => {
  const mutationKey = getMutationKey(mutationType, itemKey)
  const idDef = mutationType === UPDATE ? '$id: ID!, ' : ''
  const idParam = mutationType === UPDATE ? `${itemKey}Id: $id, ` : ''
  return gql`mutation ${mutationKey}(${idDef}$input: ${capitalizeString(mutationKey)}Input!) {
    ${mutationKey}(${idParam}input: $input) {
      ${baseMutationPayloadPartial}
      ${itemKey} {
        ${fieldsPartial}
      }
    }
  }`
}

export const getMutationInput = (resource, data) => {
  const blacklist = ['__typename']
  return Object.keys(data).reduce((accumulator, currentKey) => {
    if (blacklist.includes(currentKey)) {
      return accumulator
    }
    const pair = resource.getMutationInputPair(currentKey, data)
    if (!pair) {
      return accumulator
    }
    const [k, v] = pair
    return { ...accumulator, [k]: v }
  }, {})
}

export const buildParseItemQueryResponse = itemKey => response => {
  const data = response.data[itemKey]
  return { data }
}

export const buildParseCollectionQueryResponse = collectionKey => response => {
  const data = response.data[collectionKey]
  return { data: get(data, 'items', []), total: get(data, 'count', 0) }
}

export const buildParseMutationResponse = (mutationType, itemKey) => response => {
  const mutationKey = getMutationKey(mutationType, itemKey)
  const { success, validationErrors, [itemKey]: data } = response.data[mutationKey]
  if (success) {
    return { data }
  } else {
    throw new Error(get(validationErrors, '[0].key') + ': ' + get(validationErrors, '[0].message'))
  }
}

export const getMutationManyQuery = (mutationType, itemKey, fieldsPartial) => {
  return gql`mutation updateEmployees($ids: [ID]!, $input: UpdateEmployeesInput!) {
    updateEmployees(ids: $ids, input: $input) {
      ${baseMutationPayloadPartial}
        count
        items{
          ${fieldsPartial}
      }
    }
  }`
}

export const buildParseMutationManyResponse = (mutationType, itemKey) => response => {
  const mutationKey = getMutationKey(mutationType, itemKey)
  const { success, validationErrors, items, count } = response.data[mutationKey]
  if (success) {
    return { data: items, total: count }
  } else {
    throw new Error(get(validationErrors, '[0].key') + ': ' + get(validationErrors, '[0].message'))
  }
}
