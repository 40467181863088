import React, { Component, createElement } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { withRouter } from 'react-router'
import { MuiThemeProvider, createMuiTheme, withStyles, createStyles, Typography } from '@material-ui/core'
import compose from 'recompose/compose'
import { Menu, Notification, Error, defaultTheme, Sidebar } from 'react-admin'
import UMAppBar from './UMAppBar'
import { TERMS_AND_CONDITIONS_URL, PRIVACY_POLICY_URL } from '../config/env/base'
import me from '../api/resources/me'
import UMSideMenu from './UMSideMenu'

const styles = theme =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      zIndex: 1,
      minHeight: '100vh',
      backgroundColor: theme.palette.background.default,
      position: 'relative',
      minWidth: 'fit-content',
      width: '100%',
    },
    appFrame: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    },
    main: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
    },
    content: {
      flexDirection: 'column',
      flexGrow: 1,
      flexBasis: 0,
      padding: theme.spacing.unit * 3,
      paddingBottom: 0,
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
    contentWithSidebar: {
      display: 'flex',
      flexGrow: 1,
    },
    footer: {
      padding: theme.spacing.unit * 3,
    },
    link: {
      color: 'inherit',
      textDecoration: 'underline',
    },
  })

const sanitizeRestProps = ({ staticContext, history, location, match, ...props }) => props

class Layout extends Component {
  state = { hasError: false, errorMessage: null, errorInfo: null }

  constructor(props) {
    super(props)
    /**
     * Reset the error state upon navigation
     *
     * @see https://stackoverflow.com/questions/48121750/browser-navigation-broken-by-use-of-react-error-boundaries
     */
    props.history.listen(() => {
      if (this.state.hasError) {
        this.setState({ hasError: false })
      }
    })
  }

  componentDidCatch(errorMessage, errorInfo) {
    this.setState({ hasError: true, errorMessage, errorInfo })
  }

  SidebarManager = connect(state => ({
    currentUser: me.getMainRecord(state),
  }))(({ currentUser, ...props }) => {
    if (currentUser && currentUser.isSuperuser) {
      return (
        <Sidebar>
          <Menu logout={props.logout} />
        </Sidebar>
      )
    } else {
      return null
    }
  })

  render() {
    const {
      appBar,
      children,
      classes,
      className,
      customRoutes,
      error,
      dashboard,
      logout,
      menu,
      notification,
      sidebar,
      open,
      title,
      ...props
    } = this.props
    const { hasError, errorMessage, errorInfo } = this.state
    return (
      <div className={classnames('layout', classes.root, className)} {...sanitizeRestProps(props)}>
        <div className={classes.appFrame}>
          {createElement(appBar, { title, open, logout })}
          <main className={classes.contentWithSidebar}>
            {createElement(sidebar, {
              children: createElement(menu, {
                logout,
                hasDashboard: !!dashboard,
              }),
              size: 180,
            })}
            <div className={classes.content}>
              {hasError
                ? createElement(error, {
                    error: errorMessage,
                    errorInfo,
                    title,
                  })
                : children}
            </div>
          </main>
          <footer className={classes.footer}>
            <Typography variant="body1" align="center" color="textSecondary">
              {'© skiprBusiness - '}
              <a className={classes.link} href={TERMS_AND_CONDITIONS_URL} target="_blank" rel="noopener noreferrer">
                Terms and Conditions
              </a>
              {' - '}
              <a className={classes.link} href={PRIVACY_POLICY_URL} target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </a>
              {' - '}
              {process.env.REACT_APP_VERSION}
            </Typography>
          </footer>
          {createElement(notification)}
        </div>
      </div>
    )
  }
}

const componentPropType = PropTypes.oneOfType([PropTypes.func, PropTypes.string])

Layout.propTypes = {
  appBar: componentPropType,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  classes: PropTypes.object,
  className: PropTypes.string,
  customRoutes: PropTypes.array,
  dashboard: componentPropType,
  error: componentPropType,
  history: PropTypes.object.isRequired,
  logout: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.string]),
  menu: componentPropType,
  notification: componentPropType,
  open: PropTypes.bool,
  title: PropTypes.node.isRequired,
}

Layout.defaultProps = {
  appBar: UMAppBar,
  error: Error,
  menu: UMSideMenu,
  notification: Notification,
  sidebar: Sidebar,
}

const EnhancedLayout = compose(
  connect(
    null,
    {}, // Avoid connect passing dispatch in props
  ),
  withRouter,
  withStyles(styles),
)(Layout)

class LayoutWithTheme extends Component {
  constructor(props) {
    super(props)
    this.theme = createMuiTheme(props.theme)
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.theme !== this.props.theme) {
      this.theme = createMuiTheme(nextProps.theme)
    }
  }
  render() {
    const { theme, ...rest } = this.props
    return (
      <MuiThemeProvider theme={this.theme}>
        <EnhancedLayout {...rest} />
      </MuiThemeProvider>
    )
  }
}

LayoutWithTheme.propTypes = {
  theme: PropTypes.object,
}

LayoutWithTheme.defaultProps = {
  theme: defaultTheme,
}

const UMLayout = LayoutWithTheme
export default UMLayout
