import get from 'lodash/get'

const fieldsPartial = `
  userReference
  paymentDocumentReference
  paymentDocumentName
  paymentDocumentTypeReference
  status
  updatedOn
  createdOn
  id
`

const filterTypes = {
  ids: '[ID]',
}

const buildQuery = (fetchType, resourceName, params) => {
  return null
}

const buildMutationInput = params => {
  console.log(params)
  return Object.keys(params).reduce((accumulator, currentKey) => {
    console.log(currentKey)
    console.log(accumulator)
    switch (currentKey) {
      case 'paymentOrganisation':
        accumulator = { ...accumulator, [currentKey + 'Id']: params[currentKey]['id'] }
        break
      case 'id':
      case '__typename':
        break
      default:
        accumulator = { ...accumulator, [currentKey]: params[currentKey] }
    }
    return accumulator
  }, {})
}

const getParentResource = () => null

const getName = () => 'documents'

const getLabel = () => 'Documents'

const getRecordById = (state, id) => get(state, `admin.resources.documents.data.${id}`, null)

const getShowPathForId = id => '/' + getName() + '/' + id + '/show'

const getLabelForRecord = record => {
  if (!record) {
    return ''
  }
  return record.name
}

export default {
  fieldsPartial,
  buildQuery,
  getParentResource,
  getName,
  getLabel,
  getRecordById,
  getShowPathForId,
  getLabelForRecord,
  filterTypes,
  buildMutationInput,
}
