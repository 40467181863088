import React from 'react'
import get from 'lodash/get'
import { DateField, TextField } from 'react-admin'
import { LOCALE_DEFAULT } from '../config/locale'

const UMDateField = ({ fallbackText = 'N/A', ...props }) => {
  if (props.record && !get(props.record, props.source)) {
    return <TextField {...props} record={{ ...props.record, [props.source]: fallbackText }} />
  }
  return <DateField locales={LOCALE_DEFAULT} {...props} />
}

UMDateField.defaultProps = {
  addLabel: true,
}

export default UMDateField
