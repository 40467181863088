import React from 'react'
import { Datagrid, Pagination } from 'react-admin'
import { CardContent, Typography } from '@material-ui/core'

export const UMPagination = props =>
  props.total > 0 ? <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} /> : null

const UMDatagrid = ({ emptyText = 'No results', isFullList = false, ...props }) => {
  if (!props.loadedOnce) {
    return (
      <Typography variant="body1" style={isFullList ? { marginTop: 16, marginLeft: 12 } : null}>
        Loading…
      </Typography>
    )
  }
  if (props.total === 0) {
    return (
      <Typography variant="body1" style={isFullList ? { marginTop: 16, marginLeft: 12 } : null}>
        {emptyText}
      </Typography>
    )
  }
  return <Datagrid {...props} />
}

export const UMWrappedDatagrid = props => (
  <CardContent>
    <UMDatagrid {...props} />
  </CardContent>
)

export default UMDatagrid
