import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import compose from 'recompose/compose'
import { withTranslate, showNotification } from 'react-admin'
import { Button, CardActions, CircularProgress, TextField, withStyles, createStyles } from '@material-ui/core'

const styles = ({ spacing }) =>
  createStyles({
    form: {
      padding: '0 1em 1em 1em',
    },
    input: {
      marginTop: '1em',
    },
    button: {
      width: '100%',
      marginBottom: spacing.unit,
    },
    icon: {
      marginRight: spacing.unit,
    },
    link: {
      color: 'inherit',
      textDecoration: 'underline',
    },
  })

// see http://redux-form.com/6.4.3/examples/material-ui/
const renderInput = ({
  meta: { touched, autofilled, error } = { touched: false, autofilled: false, error: '' }, // eslint-disable-line react/prop-types
  input: { ...inputProps }, // eslint-disable-line react/prop-types
  ...props
}) => (
  <TextField
    error={!!(touched && error) && !autofilled}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
)

const query = async (path, params) => {
  const request = new Request(process.env.REACT_APP_API_URL_AUTH + path, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: new Headers({ 'Content-Type': 'application/json; charset=utf-8' }),
  })
  const response = await fetch(request)
  if (response.status < 200 || response.status >= 300) {
    return { error: true, detail: response.json() }
  }
  return { error: false, detail: response.json() }
}

const reset = async (data, dispatch, { redirectTo }) => {
  console.log('forgot', data)
  const { error, detail } = await query('/auth/reset-password', { email: data['email'] })
  if (!error) {
    dispatch(showNotification('Email sent with link to reset'))
    return true //TODO: display success notification using dispatch?
  }
  dispatch(showNotification('Error with your email', 'warning'))
  return detail //TODO: display error message to user
}

const ForgotForm = ({ classes, isLoading, handleSubmit, translate }) => (
  <form onSubmit={handleSubmit(reset)}>
    <div className={classes.form}>
      <p>
        Please enter your email address <br /> to receive a link to reset your password.
      </p>
      <div className={classes.input}>
        <Field
          autoFocus
          id="email"
          name="email"
          component={renderInput}
          label="Email"
          type="email"
          disabled={isLoading}
          required
        />
      </div>
    </div>
    <CardActions>
      <Button variant="raised" type="submit" color="primary" disabled={isLoading} className={classes.button}>
        {isLoading && <CircularProgress className={classes.icon} size={18} thickness={2} />}
        Validate
      </Button>
    </CardActions>
    <p>
      <center>
        <Link to={'./'}>Back to Login</Link>
      </center>
    </p>
  </form>
)

const mapStateToProps = state => ({
  isLoading: state.admin.loading > 0,
})

const enhance = compose(
  withStyles(styles),
  withTranslate,
  connect(mapStateToProps),
  reduxForm({
    form: 'forgot',
    validate: (values, props) => {
      const errors = { email: '', password: '' }
      const { translate } = props
      if (!values.email) {
        errors.email = translate('ra.validation.required')
      }
      return errors
    },
  }),
)

const UMForgotForm = enhance(ForgotForm)

UMForgotForm.propTypes = {
  redirectTo: PropTypes.string,
  showNotification: PropTypes.func,
  push: PropTypes.func,
}

export default connect(
  null,
  {
    showNotification,
    push,
  },
)(UMForgotForm)

//export default UMForgotForm
