import React from 'react'
import { List, TextField, CardActions, CreateButton, RefreshButton, BooleanField, FunctionField } from 'react-admin'
import { connect } from 'react-redux'
import { parse } from 'query-string'
import get from 'lodash/get'
import UMDateField from '../../components/UMDateField'
import organisations from '../../api/resources/organisations'
import { withStyles } from '@material-ui/core'
import commonStyles from '../common/styles'
import UMBreadcrumbs from '../../components/UMBreadcrumbs'
import { UMPagination, UMWrappedDatagrid } from '../../components/UMDatagrid'

const CardsActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  permissions,
  total,
}) => {
  if (permissions === 'FLEET_MGR' || permissions === 'EMPLOYEE')
    return (
      <CardActions>
        {bulkActions &&
          React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
          })}
        {filters &&
          React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
          })}
        <RefreshButton />
        {/* Add your custom actions */}
      </CardActions>
    )
  else
    return (
      <CardActions>
        {bulkActions &&
          React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
          })}
        {filters &&
          React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
          })}

        <CreateButton basePath={basePath} />
        <RefreshButton />
        {/* Add your custom actions */}
      </CardActions>
    )
}

const VirtualCardsList = withStyles(commonStyles.list)(
  ({ classes, parentRecord, record, dispatch, permissions, ...props }) => {
    return (
      <List
        className={classes.root}
        bulkActions={false}
        actions={<CardsActions permissions={permissions} />}
        //sort={{ field: 'user.lastName', order: 'ASC' }}
        pagination={<UMPagination />}
        perPage={500}
        title={
          parentRecord ? (
            <UMBreadcrumbs resourceName={organisations.getName()} forceRecord={parentRecord} />
          ) : (
            'Virtual cards'
          )
        }
        {...props}
      >
        <UMWrappedDatagrid isFullList rowClick="show">
          <TextField source="cardReference" label="Treezor Id" />
          <TextField source="status" label="Card Status" />
          <BooleanField source="isLive" label="is Active" />
          <FunctionField
            source="pinTryExceeds"
            label="PIN status"
            render={(record, source) => (
              <BooleanField record={{ ...record, pinTryExceeds: !record.pinTryExceeds }} source={source} />
            )}
          />
          {/* <ReferenceField label="Membership" source="membershipReference" reference="memberships">
            <FunctionField render={record => `${record.user.lastName} ${record.user.firstName}`} />
          </ReferenceField> */}
          <TextField style={{ color: '#ff5240' }} source="employee.firstName" label="firstname" />
          <TextField style={{ color: '#ff5240' }} source="employee.lastName" label="lastname" />
          <TextField source="employee.paymentOrganisation.name" label="Organisation" />
          <TextField source="mccRestrictionGroup.name" label="mcc restriction" />
          <TextField source="merchantIdRestrictionGroup.name" label="mid restriction" />
          <UMDateField source="createdOn" />
          <UMDateField source="updatedOn" />
        </UMWrappedDatagrid>
      </List>
    )
  },
)

export default connect((state, ownProps) => {
  const query = get(ownProps, 'location.search')
  const params = parse(query)
  // const filterStr = params.filter || '{}'
  // const filter = JSON.parse(filterStr)
  const { organisationId } = params
  const parentRecord = organisations.getRecordById(state, organisationId)
  const record = { organisation: parentRecord }
  return {
    record,
    parentRecord,
  }
})(VirtualCardsList)
