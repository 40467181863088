import * as React from 'react'
import gql from 'graphql-tag'
import { Fragment, useState } from 'react'
import { Confirm, Query, Mutation, GET_ONE } from 'react-admin'
import { Button, CircularProgress } from '@material-ui/core'

const TransferBudgetButton = ({ record, status }) => {
  // Setup constants
  const [open, setOpen] = useState(false)

  // Define validation
  const checkTransferAllowed = (org, paymentOrg) => {
    if (!paymentOrg.onTreezor) {
      console.log('Transfer budget only allowed for organisations on Treezor')
      return false
    }

    if (!org.isFreelancer) {
      console.log('Transfer budget only allowed for freelancers')
      return false
    }

    if (paymentOrg.walletTotalBalance !== 0) {
      console.log('Transfer budget only allowed when wallet is empty')
      return false
    }

    // Transfer allowed
    return true
  }

  // Return component
  return (
    <Query type={GET_ONE} resource="organisations" payload={{ id: record.organisationReference }}>
      {({ data: orgData, loading: orgLoading, error: orgError }) => {
        // Data is loading
        if (orgLoading) {
          return (
            <p>
              <CircularProgress />
              Check if budget transfer is allowed
            </p>
          )
        }

        // Fetching data failed
        if (orgError) {
          console.error('Failed to fetch organisation', record, orgError)
          return (
            <p>
              Failed to fetch organisation {record.organisationReference}: {String(orgError)}
            </p>
          )
        }

        // Process data
        const isTransferAllowed = checkTransferAllowed(orgData, record)
        const confirmAmount = (orgData.summary.remainingBudget / 100.0).toFixed(2)
        const confirmMessage = 'Are you sure you want to transfer €' + confirmAmount + ' to the wallet?'
        const mutationPayload = {
          query: gql`
            mutation transferBudgetToWallet($id: ID!, $input: TransferBudgetToWalletInput!) {
              transferBudgetToWallet(paymentOrganisationId: $id, input: $input)
            }
          `,
          data: {
            id: record.id,
            input: {
              expectedAmountInCents: orgData.summary.remainingBudget,
            },
          },
        }

        // Set mutation options
        const options = {
          undoable: false,
          onSuccess: {
            // If call returned a 200, it was always successful
            notification: { body: `Successfully transferred €${confirmAmount} to the wallet`, level: 'info' },
          },
        }

        // Return component
        return (
          <Mutation type="CUSTOM" resource="paymentOrganisations" payload={mutationPayload} options={options}>
            {(transferBudget, { loading: mutLoading, error: mutError }) => {
              // Define callbacks
              const handleClick = () => setOpen(true)
              const handleConfirm = () => {
                setOpen(false)
                transferBudget()
              }
              const handleConfirmClose = () => setOpen(false)

              // Show notification on error
              if (mutLoading === false && mutError !== null) {
                console.error('transferBudgetToWallet', mutError.message)
                alert(mutError.message)
              }

              // Return component
              return (
                <Fragment>
                  {isTransferAllowed && (
                    <Button color="primary" onClick={handleClick}>
                      Transfer budget
                    </Button>
                  )}
                  <Confirm
                    isOpen={open}
                    title="Transfer budget to wallet"
                    content={confirmMessage}
                    onConfirm={handleConfirm}
                    onClose={handleConfirmClose}
                  />
                </Fragment>
              )
            }}
          </Mutation>
        )
      }}
    </Query>
  )
}

export default TransferBudgetButton
