import React, { Component } from 'react'
import { Loading, GET_ONE } from 'react-admin'
import { createStyles, withStyles, Card, CardContent } from '@material-ui/core'
import authProvider, { AUTH_GET_CURRENT_USER_ID } from '../../api/AuthProvider'
import ExpenseList from '../../layouts/expenses/listexp'
import CardPanel from './CardPanel'
import SidePanel from './SidePanel'

const styles = theme =>
  createStyles({
    mainContainer: {
      display: 'flex',
    },
    columnContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      maxWidth: '80%',
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
    },
    expenses: {
      marginTop: '1em',
    },
    aside: {
      width: 240,
      //flexShrink: 0,
      //flex: '1 0 100%',
      marginRight: '1em',
      paddingTop: 5,
    },
  })

class EmployeeDashboard extends Component {
  state = {
    card: null,
    employee: null,
    me: null,
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData() {
    this.fetchCard()
    this.fetchBudgetAndEmployee()
  }

  async fetchBudgetAndEmployee() {
    const { dataProvider } = this.props
    let { data: me } = await dataProvider(GET_ONE, 'me', {})
    this.setState({
      me: me,
    })
  }

  async fetchCard() {
    const { dataProvider } = this.props
    let userId = await authProvider(AUTH_GET_CURRENT_USER_ID)
    const { data: card } = await dataProvider(GET_ONE, 'virtualCards', { id: userId })
    this.setState({
      card: card,
    })
  }
  render() {
    if (this.state.card) {
      return (
        <main className={this.props.classes.mainContainer}>
          <SidePanel
            basePath="/"
            {...this.props}
            forceRecord={this.state.me.memberships[0].currentBudget}
            employeeRecord={this.state.card.employee}
            classes={this.props.classes}
          />
          <div className={this.props.classes.columnContainer}>
            <CardPanel dataProvider={this.props.dataProvider} card={this.state.card} />
            <ExpenseList
              className={this.props.classes.expenses}
              location={this.props.location}
              match={this.props.match}
              basePath="/expenses"
              resource="expenses"
              record={this.state.me.memberships[0].currentBudget}
              hasCreate={false}
              hasEdit={true}
              hasList={true}
              hasShow={false}
              title={`${this.state.me.memberships[0].organisation.name}`}
              classes={this.props.classes}
            />
          </div>
        </main>
      )
    }
    return (
      <Card>
        <CardContent>
          <Loading loadingPrimary="" loadingSecondary="ra.page.loading" />
        </CardContent>
      </Card>
    )
  }
}

export default withStyles(styles)(EmployeeDashboard)
