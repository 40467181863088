import React from 'react'
import {
  Edit,
  TabbedForm,
  FormTab,
  FileField,
  FileInput,
  SelectInput,
  TextInput,
  DateInput,
  ReferenceField,
} from 'react-admin'
import UMBreadcrumbs from '../../components/UMBreadcrumbs'
import employees from '../../api/resources/employees'
import CountrySelectorInput from '../../components/CountrySelectorInput'

const choicesUserTitle = [{ _key: 'M', _label: 'M' }, { _key: 'MME', _label: 'MME' }, { _key: 'MLLE', _label: 'MLLE' }]

const choicesIdentityDocumentTypes = [
  { key: '9', label: 'Identity Card' },
  { key: '17', label: 'Passport' },
  { key: '15', label: 'Residence Permit' },
]

const choicesUserStatus = [{ key: 'INTEGRATED', label: 'Integrated' }, { key: 'ARCHIVED', label: 'Archived' }]

const EmployeeEdit = ({ permissions, dispatch, ...props }) => {
  return (
    <Edit
      title={<UMBreadcrumbs resourceName={employees.getName()} currentLabel="Edit employee" />}
      undoable={false}
      {...props}
    >
      <TabbedForm {...props} redirect="show">
        <FormTab label="Personal">
          <SelectInput
            source="title"
            choices={choicesUserTitle}
            optionText="_label"
            optionValue="_key"
            allowEmpty={false}
          />
          <TextInput source="firstName" />
          <TextInput source="lastName" />
          <TextInput source="email" />
          <TextInput source="phoneNumber" />
          <DateInput source="birthday" />
          <TextInput source="address.street" />
          <TextInput source="address.city" />
          <TextInput source="address.postalCode" />
          <CountrySelectorInput source="address.countryCode" defaultValue="BE" />
          <CountrySelectorInput source="nationality" defaultValue="BE" />
          <TextInput source="placeOfBirth" />
          <CountrySelectorInput source="birthCountry" defaultValue="BE" />
          <ReferenceField
            label="Internal reference / Social Secretary"
            source="membershipReference"
            reference="memberships"
          >
            <TextInput
              source="internalPayrollId"
              label="click to modify Internal reference or Social Secretary"
              style={{ width: 400 }}
            />
          </ReferenceField>
        </FormTab>
        <FormTab label="Documents">
          <SelectInput
            source="identityDocument.documentTypeReference"
            label="Document Type"
            choices={choicesIdentityDocumentTypes}
            optionText="label"
            optionValue="key"
          />
          <FileInput source="identityDocument.fileContentBase64" label="File" multiple="true">
            <FileField source="src" title="title" />
          </FileInput>
        </FormTab>
        <FormTab label="User deactivation">
          <h1>
            Be careful ! Updating the user as archived to deactivate it cannnot be reversed at the moment, budgets will
            be blocked, card will be blocked, link with the maas will be closed.
          </h1>
          <SelectInput
            source="integrationStatus"
            label="user status"
            choices={choicesUserStatus}
            optionText="label"
            optionValue="key"
          />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}

export default EmployeeEdit
