import React from 'react'
import { Mutation } from 'react-admin'
import { Button } from '@material-ui/core'

const options = {
  undoable: false,
  onSuccess: {
    notification: { body: 'KYC review started', level: 'info' },
    redirectTo: '/paymentOrganisations',
  },
  onFailure: {
    notification: { body: 'Error: starting kyc review', level: 'warning' },
  },
}

const StartKYCButton = ({ record, label, status }) => {
  const payload = record ? { id: record.id, data: { id: record.id, organisationAction: status } } : {}
  return (
    <Mutation type="UPDATE" resource="paymentOrganisations" payload={payload} options={options}>
      {activate => (
        <Button label="Activate" onClick={activate}>
          {label}
        </Button>
      )}
    </Mutation>
  )
}

export default StartKYCButton
