import React from 'react'
import { Show, TabbedShowLayout, Tab, TextField, DateField, EditButton } from 'react-admin'
import { withStyles, CardActions } from '@material-ui/core'
import commonStyles from '../common/styles'
import UMBreadcrumbs from '../../components/UMBreadcrumbs'
import shareholders from '../../api/resources/shareholders'

const ShowActions = ({ basePath, data, resource }) => (
  <CardActions>
    <EditButton basePath={basePath} record={data} />
  </CardActions>
)

export default withStyles(commonStyles.show)(({ classes, permissions, ...props }) => (
  <Show
    {...props}
    className={classes.root}
    title={<UMBreadcrumbs resourceName={shareholders.getName()} />}
    actions={<ShowActions classes={classes} />}
  >
    <TabbedShowLayout>
      <Tab label="References">
        <TextField source="userReference" label="Treezor Id" />
        <TextField source="paymentOrganisationId" label="Organisation Treezor Id" />
        <TextField source="id" label="Skipr Id" />
        <TextField source="taxResidence.taxPayerId" label="National Registry Number" />
      </Tab>
      <Tab label="Personal">
        <TextField source="title" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="email" />
        <DateField source="birthday" />
        <TextField source="placeOfBirth" />
        <TextField source="nationality" />
        <TextField source="phoneNumber" />
      </Tab>
      <Tab label="Address">
        <TextField source="address.street" label="street" />
        <TextField source="address.city" label="city" />
        <TextField source="address.postalCode" label="postal code" />
        <TextField source="address.countryCode" label="country" />
      </Tab>
      <Tab label="Documents">
        <TextField source="identityDocument.status" label="Identity Check" />
        <TextField source="residenceDocument.status" label="Residence Check" />
        <TextField source="additionalDocument.status" label="Additional Check" />
        <TextField source="kycReviewComment" label="Review Comment" />
      </Tab>
      <Tab label="Dates">
        <DateField label="Created on" source="createdOn" />
      </Tab>
    </TabbedShowLayout>
  </Show>
))
