import React, { Component } from 'react'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Notification, defaultTheme } from 'react-admin'
import { Card, Avatar, MuiThemeProvider, createMuiTheme, withStyles, createStyles } from '@material-ui/core'
import LockIcon from '@material-ui/icons/Lock'
import UMLoginForm from './UMLoginForm'
import { randomInt } from '../utils/numbers'
import UMForgotForm from './UMForgotForm'
import UMResetForm from './UMResetForm'
import { parse } from 'query-string'

const styles = theme =>
  createStyles({
    main: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      height: '1px',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    content: {
      marginTop: '6em',
    },
    logo: {
      display: 'block',
      position: 'relative',
      marginLeft: -28,
    },
    card: {
      marginTop: '2em',
      minWidth: 300,
    },
    avatar: {
      margin: '1em',
      display: 'flex',
      justifyContent: 'center',
    },
    icon: {
      backgroundColor: theme.palette.secondary[500],
    },
  })

class Login extends Component {
  theme = createMuiTheme(this.props.theme)
  containerRef = React.createRef()
  backgroundImageLoaded = false

  updateBackgroundImage = () => {
    if (!this.backgroundImageLoaded && this.containerRef.current) {
      const { backgroundImage } = this.props
      this.containerRef.current.style.backgroundImage = `url(${backgroundImage})`
      this.backgroundImageLoaded = true
    }
  }

  // Load background image asynchronously to speed up time to interactive
  lazyLoadBackgroundImage() {
    const { backgroundImage } = this.props

    if (backgroundImage) {
      const img = new Image()
      img.onload = this.updateBackgroundImage
      img.src = backgroundImage
    }
  }

  componentDidMount() {
    this.lazyLoadBackgroundImage()
  }

  componentDidUpdate() {
    if (!this.backgroundImageLoaded) {
      this.lazyLoadBackgroundImage()
    }
  }

  render() {
    const {
      backgroundImage,
      classes,
      className,
      loginForm,
      forgotForm,
      resetForm,
      location,
      staticContext,
      ...rest
    } = this.props
    console.log('UMLogin', location)
    const { reset } = parse(location.search)
    return (
      <MuiThemeProvider theme={this.theme}>
        <div className={classnames(classes.main, className)} {...rest} ref={this.containerRef}>
          <div className={classes.content}>
            <img
              className={classes.logo}
              src="/images/logo-skipr.png"
              width="240"
              alt="skiprBusiness"
              style={{ margin: 'auto' }}
            />
            <Card className={classes.card}>
              <div className={classes.avatar}>
                <Avatar className={classes.icon}>
                  <LockIcon />
                </Avatar>
              </div>
              {!location.search && loginForm}
              {location.search.includes('forgot') && forgotForm}
              {location.search.includes('reset') && (
                <UMResetForm location={location} initialValues={{ resetToken: reset }} />
              )}
            </Card>
          </div>
          <Notification />
        </div>
      </MuiThemeProvider>
    )
  }
}

const UMLogin = withRouter(withStyles(styles)(Login))

UMLogin.propTypes = {
  backgroundImage: PropTypes.string,
  loginForm: PropTypes.element,
  forgotForm: PropTypes.element,
  resetForm: PropTypes.element,
  theme: PropTypes.object,
  staticContext: PropTypes.object,
}

UMLogin.defaultProps = {
  backgroundImage: '/images/bg-' + randomInt(1, 3) + '.jpg',
  theme: defaultTheme,
  loginForm: <UMLoginForm />,
  forgotForm: <UMForgotForm />,
  resetForm: <UMResetForm />,
}
export default UMLogin
