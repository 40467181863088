import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { change, submit, isSubmitting } from 'redux-form'
import {
  fetchEnd,
  fetchStart,
  required,
  showNotification,
  Button,
  SaveButton,
  SimpleForm,
  NumberInput,
  UPDATE,
  withDataProvider,
} from 'react-admin'
import IconCancel from '@material-ui/icons/Cancel'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

class SetPinButton extends Component {
  state = {
    error: false,
    showDialog: false,
  }

  handleClick = () => {
    this.setState({ showDialog: true })
  }

  handleCloseClick = () => {
    this.setState({ showDialog: false })
  }

  handleSaveClick = () => {
    const { submit } = this.props
    submit('set-pin-form')
  }

  handleSubmit = values => {
    const { fetchStart, fetchEnd, showNotification } = this.props

    fetchStart()

    values = { ...values, status: 'SET_PIN' }
    this.props
      .dataProvider(UPDATE, 'virtualCards', { id: this.props.record.id, data: values })
      .then(({ data }) => {
        this.setState({ showDialog: false })
        showNotification('pin set with success !')
      })
      .catch(error => {
        showNotification(error.message, 'warning')
      })
      .finally(() => {
        fetchEnd()
      })
  }

  render() {
    const { showDialog } = this.state
    const { isSubmitting } = this.props

    return (
      <Fragment>
        <Button onClick={this.handleClick} label="SET PIN"></Button>
        <Dialog fullWidth open={showDialog} onClose={this.handleCloseClick} aria-label="Set pin">
          <DialogTitle>Set pin</DialogTitle>
          <DialogContent>
            <SimpleForm form="set-pin-form" resource="virtualCards" onSubmit={this.handleSubmit} toolbar={null}>
              <NumberInput source="newPin" validate={required()} />
              <NumberInput source="confirmPin" validate={required()} />
            </SimpleForm>
          </DialogContent>
          <DialogActions>
            <SaveButton saving={isSubmitting} onClick={this.handleSaveClick} />
            <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
              <IconCancel />
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  isSubmitting: isSubmitting('set-pin-form')(state),
})

const mapDispatchToProps = {
  change,
  fetchEnd,
  fetchStart,
  showNotification,
  submit,
}

export default withDataProvider(connect(mapStateToProps, mapDispatchToProps)(SetPinButton))
