import React from 'react'
import { List, TextField, ReferenceField } from 'react-admin'
import { Link } from 'react-router-dom'
import { withStyles, Card } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import commonStyles from '../../layouts/common/styles'
import { UMWrappedDatagrid } from '../../components/UMDatagrid'
import UMDateField from '../../components/UMDateField'
import UMPriceField from '../../components/UMPriceField'
import UMActions from '../../components/UMActions'
import UMFullNameField from '../UMFullNameField'
import { stringify } from 'query-string'
import organisations from '../../api/resources/organisations'
import get from 'lodash/get'
import { connect } from 'react-redux'
import { parse } from 'query-string'
import ProToBusinessField from '../../layouts/expenses/ProToBusinessField'

const ViewAllExpenses = ({ record }) => {
  return (
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: '/expenses',
        // state: { record: { organisationId: record.id } },
        search: stringify({
          filter: JSON.stringify({ organisationId: record }),
        }),
      }}
    >
      VIEW ALL EXPENSES
    </Button>
  )
}

const Actions = ({ record, classes }) => {
  return (
    <UMActions classes={classes} title="5 recent expenses">
      <ViewAllExpenses record={record} />
    </UMActions>
  )
}

const NoPagination = ({ record, classes }) => {
  return null
}

const FiveRecentExpenses = withStyles(commonStyles.list)(
  ({ classes, parentRecord, record, organisationRecord, dispatch, permissions, ...props }) => {
    return (
      <Card>
        <List
          bulkActions={false}
          sort={{ field: 'expenseOn', order: 'DESC' }}
          filterDefaultValues={{ organisationId: organisationRecord.id }}
          pagination={<NoPagination />}
          perPage={5}
          actions={<Actions classes={classes} record={organisationRecord.id} />}
          {...props}
        >
          <UMWrappedDatagrid emptyText="No expenses">
            <UMPriceField sortable={false} source="amount" label="Total Amount" format={v => (v ? v / 100 : 0)} />
            <ReferenceField
              source="budget.membership.id"
              reference="memberships"
              label="Employee"
              linkType={false}
              sortable={false}
            >
              <UMFullNameField firstNameKey="user.firstName" lastNameKey="user.lastName" />
            </ReferenceField>
            <TextField sortable={false} source="partner.name" label="Provider" />
            <TextField sortable={false} source="category.name" label="Category" />
            <ProToBusinessField sortable={false} source="type" label="Type of expense" />
            <UMDateField sortable={false} source="expenseOn" label="Date" />
            {/* <TextField sortable={false} label="Payment method" /> */}
          </UMWrappedDatagrid>
        </List>
      </Card>
    )
  },
)

export default connect((state, ownProps) => {
  const query = get(ownProps, 'location.search')
  const params = parse(query)
  const filterStr = params.filter || '{}'
  const filter = JSON.parse(filterStr)
  const { organisationId } = filter
  const parentRecord = organisations.getRecordById(state, organisationId) || { id: organisationId }
  const record = { organisation: parentRecord }
  return {
    record,
    parentRecord,
  }
})(FiveRecentExpenses)
