import React from 'react'
import { Edit } from 'react-admin'
import { connect } from 'react-redux'
import UMBreadcrumbs from '../../components/UMBreadcrumbs'
import memberships from '../../api/resources/memberships'
import MembershipForm from './form'

const MembershipEdit = ({ permissions, dispatch, ...props }) => {
  const formProps = { permissions }
  return (
    <Edit title={<UMBreadcrumbs resourceName={memberships.getName()} currentLabel="Edit membership" />} {...props}>
      <MembershipForm {...formProps} />
    </Edit>
  )
}

export default connect(state => {})(MembershipEdit)
