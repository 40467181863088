import React from 'react'
import { Create } from 'react-admin'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import get from 'lodash.get'
import { parse } from 'query-string'
import partners from '../../api/resources/partners'
import UMBreadcrumbs from '../../components/UMBreadcrumbs'
import memberships from '../../api/resources/memberships'
import MembershipForm from './form'
import { withStyles } from '@material-ui/core'
import commonStyles from '../common/styles'

const MembershipCreate = withStyles(commonStyles.create)(
  ({ parentRecord, organisationId, defaultPartner, dispatch, permissions, classes, ...props }) => {
    if (!parentRecord) {
      if (!organisationId) {
        dispatch(push('/'))
        return null
      }
      parentRecord = { id: organisationId }
    }

    const formProps = {
      mode: 'create',
      defaultPartner,
      permissions,
      organisationId,
    }

    return (
      <Create
        title={<UMBreadcrumbs resourceName={memberships.getName()} currentLabel="Create membership" />}
        {...props}
      >
        <MembershipForm {...formProps} />
      </Create>
    )
  },
)

export default connect((state, ownProps) => {
  const query = get(ownProps, 'location.search')
  const params = parse(query)
  const { organisationId } = params

  const defaultPartner = partners.getMainRecord(state)
  return {
    organisationId,
    defaultPartner,
  }
})(MembershipCreate)
